export const LIGHT_COLORS = {
  background: 'white',
  accent: 'gray',
  disabled: '#DDE4EE',
  border: '#C5D0DF',
  accentLight: ' ',
  placeholder: '#475569',
  textColor: 'black',
  subText: '#4B5563',
  transparent: '#ffffff00',
  borderHover: '#64748B',
  highlightColor: 'white',
  neutral: '#DDE4EE',
  grey: '#73738C',
  lightWhite: '#ffffff30',
  black: '#000000',
  white: '#FFFFFF',
};

export const LIGHT_BLUE = {
  'blue-900': '#194185',
  'blue-700': '#175CD3',
  'blue-600': '#1570EF',
  'blue-400': '#53B1FD',
  'blue-300': '#84B4FF',
  'blue-200': '#84CAFF',
  'blue-50': '#EFF8FF',
};

export const LIGHT_AMBER = {
  'amber-900': '#93370D',
  'amber-700': '#B54708',
  'amber-600': '#DC6803',
  'amber-400': '#FF9500',
  'amber-300': '#FEC84B',
  'amber-200': '#FEDF89',
  'amber-100': '#FEF0C7',
  'amber-50': '#FFFAEB',
};

export const LIGHT_GREEN = {
  'green-900': '#05603A',
  'green-700': '#027948',
  'green-600': '#039855',
  'green-400': '#20BF74',
  'green-300': '#6CE9A6',
  'green-200': '#A6F4C5',
  'green-100': '#C7FED6',
  'green-50': '#ECFDF3',
};

export const LIGHT_NEUTRAL = {
  'neutral-1150': '#ffffff',
  'neutral-1100': '#ffffff',
  'neutral-1050': '#ffffff',
  'neutral-1000': '#ffffff',
  'neutral-900': '#111827',
  'neutral-800': '#1F2937',
  'neutral-700': '#374151',
  'neutral-600': '#4B5563',
  'neutral-500': '#6B7280',
  'neutral-400': '#94A3B8',
  'neutral-300': '#CBD5E1',
  'neutral-200': '#DDE4EE',
  'neutral-100': '#F1F5F9',
  'neutral-50': '#F8FAFC',
};

export const LIGHT_GREY = {
  'grey-50': '#F7F7F8',
  'grey-100': '#EBEBEF',
  'grey-200': '#D8D8DF',
  'grey-300': '#B9B9C6',
  'grey-400': '#8F8FA3',
  grey: '#73738C',
  'grey-600': '#5A5A72',
  'grey-700': '#434356',
  'grey-800': '#25252D',
  'grey-900': '#131318',
};

export const LIGHT_RED = {
  'red-900': '#87112B',
  'red-700': '#B4173A',
  'red-600': '#E11D48',
  'red-400': '#F5655A',
  'red-300': '#ED7791',
  'red-200': '#F3A5B6',
  'red-100': '#FFDFDD',
  'red-50': '#FEF2F1',
};
