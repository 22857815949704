import {useMemo} from 'react';

import {useAppletsList} from '../../context';
import {AppletService} from '../../types';

export const useWidgetByIdMap = () => {
  const applets = useAppletsList();

  return useMemo(() => {
    const widgetByIdMap = new Map<string, AppletService>();
    applets.forEach(({widgets}) => {
      widgets.forEach((widget) => widgetByIdMap.set(widget.id, widget));
    });

    return widgetByIdMap;
  }, [applets]);
};
