import React, {forwardRef, Ref} from 'react';

import {ImageBackground as BaseImgBackground} from 'react-native';

import {View} from '../View';

import {ImageBackgroundProps} from './types';

const BaseImageBackground = ({children, styles, source, resizeMode, ...props}: ImageBackgroundProps, ref: Ref<any>) => {
  return (
    <View {...props} position="relative">
      <BaseImgBackground
        ref={ref}
        source={source}
        resizeMode={resizeMode}
        style={[{width: '100%', height: '100%'}, styles]}>
        {children}
      </BaseImgBackground>
    </View>
  );
};

export const ImageBackground = forwardRef(BaseImageBackground);
