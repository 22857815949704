import React from 'react';

import {Spinner} from '../../components/Spinner';

interface WidgetComponents {
  Component: (props: any) => JSX.Element;
  Icon: (props: any) => JSX.Element;
  Settings: (props: any) => JSX.Element;
}

const WrapComponent = (Component: any) => (props: any) => {
  return (
    <React.Suspense fallback={<Spinner />}>
      <Component {...props} />
    </React.Suspense>
  );
};

export const LazyWidget = (load: () => Promise<any>): WidgetComponents => {
  const component = React.lazy(async () => await load());
  const icon = React.lazy(async () => ({default: (await load()).Icon}));
  const settings = React.lazy(async () => ({default: (await load()).Settings}));

  return {
    Icon: WrapComponent(icon),
    Settings: WrapComponent(settings),
    Component: WrapComponent(component),
  };
};
