import ky from 'ky';

export function createMockApi(mockedResponse?: any, mockedStatus = 200, waitTime = 500) {
  return ky.create({
    hooks: {
      beforeRequest: [
        (request) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const response = typeof mockedResponse !== 'function' ? mockedResponse : mockedResponse(request);
              resolve(new Response(JSON.stringify(response), {status: mockedStatus}));
            }, waitTime);
          }),
      ],
    },
  });
}
