import createLucideIcon from '../createLucideIcon';

const BookText = createLucideIcon('BookText', [
  [
    'path',
    {
      d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20',
      key: 't4utmx',
    },
  ],
  ['path', { d: 'M8 7h6', key: '1f0q6e' }],
  ['path', { d: 'M8 11h8', key: 'vwpz6n' }],
]);

export default BookText;
