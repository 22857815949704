import React from 'react';

import {AnimatePresence, Tabs as BaseTabs} from 'tamagui';

import {IconView} from '../../IconView';
import {Typography} from '../../Typography';
import {View} from '../../View';
import {TabsRovingIndicator} from '../components/TabsRovingIndicator';
import {TabsProps, TabsState, TabsTabProps} from '../types';

type Props = {
  tabs: TabsProps['tabs'];
  appDirection: 'ltr' | 'rtl';
  currentTab: number;
  handleOnInteraction: TabsTabProps['handleOnInteraction'];
  activeAt: TabsState['activeAt'];
};

export const BaseTabsList = ({tabs, appDirection, currentTab, handleOnInteraction, ...props}: Props) => {
  return (
    <BaseTabs.List width="100%" disablePassBorderRadius loop={false} direction={appDirection} gap="$4" {...props}>
      {tabs?.map((tab, index) => {
        const isActiveTab = currentTab === index;
        const color = isActiveTab ? 'white' : '$accent';

        return (
          <BaseTabs.Tab
            key={index}
            direction={appDirection}
            unstyled
            value={'' + index}
            onInteraction={handleOnInteraction}
            paddingVertical="$10"
            paddingHorizontal="$14"
            borderRadius={15}
            borderWidth={isActiveTab ? 0 : '$1'}
            borderColor="$accent"
            overflow="hidden">
            {tab.icon && (
              <View marginHorizontal="$2">
                <IconView size={19} icon={tab.icon} color={color} />
              </View>
            )}

            <Typography color={color}>{tab.label}</Typography>

            <AnimatePresence>{isActiveTab && <TabsRovingIndicator key={index} />}</AnimatePresence>
          </BaseTabs.Tab>
        );
      })}
    </BaseTabs.List>
  );
};
