import React from 'react';

import {Button, Space} from 'antd';
import {Trans} from 'react-i18next';

import {useAppImage} from '../../data';
import {EnvironmentProfile} from '../../types';
import {fileIsImage} from '../../utils';
import {IconView} from '../IconView';
import {UploadButton} from '../UploadButton';

interface Props {
  value?: File | string;
  onChange?: (file: File | undefined) => void;
  disabled?: boolean;
  loading?: boolean;
  showPreview?: boolean;
  showRemoveButton?: boolean;
  showRemoveLabel?: boolean;
  serviceId?: string;
  useServiceImage?: (serviceId: string) => void;
}

export function UploadImage({
  value,
  onChange,
  disabled,
  loading,
  showPreview = true,
  showRemoveButton = true,
  showRemoveLabel = true,
  serviceId,
  useServiceImage,
}: Props) {
  const {url: fileUrl} = useAppImage(value, EnvironmentProfile.DRAFT, serviceId, useServiceImage);

  return (
    <Space direction="vertical">
      <Space>
        <UploadButton
          value={value}
          onChange={(file) => {
            if (file && !fileIsImage(file)) {
              onChange?.(undefined);
            } else {
              onChange?.(file);
            }
          }}
          loading={loading}
          disabled={disabled}
          accept="image/png, image/jpeg">
          {showRemoveLabel && <Trans i18nKey="upload" />}
        </UploadButton>

        {showRemoveButton && value && (
          <Button shape="circle" danger onClick={() => onChange?.(undefined)} icon={<IconView icon="X" fill />} />
        )}
      </Space>

      {showPreview && value && <img src={fileUrl} style={{width: '150px', maxHeight: '250px', borderRadius: '10px'}} />}
    </Space>
  );
}
