import React, {ComponentProps, forwardRef} from 'react';

import {Stack} from 'tamagui';

import {useDirectionAwareProps} from '../../utils/useDirectionAwareProps';

export type ViewProps = ComponentProps<typeof Stack>;

function _View(props: ViewProps, ref) {
  const _props = useDirectionAwareProps(props);

  return <Stack {..._props} ref={ref} />;
}

export const View = forwardRef(_View);
