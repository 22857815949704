import AsyncStorage from '@react-native-async-storage/async-storage';

export class CacheService {
  scopeId?: string;

  constructor(scopeId?: string) {
    this.scopeId = scopeId;
  }

  scopedKey(key) {
    return this.scopeId ? `@${this.scopeId}_${key}` : key;
  }

  async get<T>(key: string): Promise<T | undefined> {
    try {
      const value = await AsyncStorage.getItem(this.scopedKey(key));
      return value != undefined ? JSON.parse(value) : undefined;
    } catch (e) {
      return undefined;
    }
  }

  set(key: string, value: any) {
    return AsyncStorage.setItem(this.scopedKey(key), JSON.stringify(value));
  }

  remove(key: string) {
    return AsyncStorage.removeItem(this.scopedKey(key));
  }

  clear() {
    return AsyncStorage.clear();
  }
}

export const cacheService = new CacheService();
