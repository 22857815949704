import {useEffect, useState} from 'react';

import {cacheService} from './service';

export function useCachedValue<T = string>(key: string) {
  const [value, setValue] = useState<T | null>();

  useEffect(() => {
    cacheService.get<T>(key).then((res) => setValue(res));
  }, [key]);

  return value;
}
