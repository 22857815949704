import {useMemo} from 'react';

import {useAppletsList} from '../../context/AppContext';
import {LocalizedTextType} from '../../types';

export const useServiceNamesMap = (): Map<string, LocalizedTextType> => {
  const applets = useAppletsList();

  return useMemo(() => {
    const serviceNamesMap = new Map<string, LocalizedTextType>();
    applets.forEach(({services}) => {
      services.forEach((service) => serviceNamesMap.set(service.id, service.name));
    });

    return serviceNamesMap;
  }, [applets]);
};
