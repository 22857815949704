import {useQuery} from 'react-query';

import {useAuth, useIsAuthenticated} from '../../context/AuthContext';
import {useAPI} from '../../services/api';
import {LoggedUserInfo} from '../../types/LoggedUserInfo';

export function useCurrentUser() {
  const API = useAPI({name: 'Diku'} as LoggedUserInfo);
  const isAuthenticated = useIsAuthenticated();
  const {discovery} = useAuth();

  const res = useQuery(
    ['userinfo'],
    () => API.get('', {prefixUrl: discovery?.userInfoEndpoint}).json<LoggedUserInfo>(),
    {
      enabled: isAuthenticated,
    },
  );

  return res.data;
}
