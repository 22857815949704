import React from 'react';

import {View} from '../../../../../View';

export const Container = ({children}) => {
  return (
    <View margin="$2" gap="$12">
      {children}
    </View>
  );
};
