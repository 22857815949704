export function getFileExtensionFromBuffer(buffer: ArrayBuffer) {
  const uintArray = new Uint8Array(buffer);

  const header = uintArray.slice(0, 4);
  const headerString = header.reduce((acc, byte) => acc + byte.toString(16).padStart(2, '0'), '');

  const signatureMappings = {
    '89504e47': 'png',
    '47494638': 'gif',
    ffd8ffe0: 'jpg',
    ffd8ffe1: 'jpg',
    ffd8ffe2: 'jpg',
    '25504446': 'pdf',
    '504b0304': 'zip',
    '504b0506': 'zip',
    '504b0708': 'zip',
    '4d546864': 'mid',
    '1f8b08': 'gz',
    '52617221': 'rar',
    '377abcaf271c': '7z',
    '4d5a9000': 'exe',
    '4d5a5000': 'exe',
    '7f454c46': 'elf',
    '7573746172': 'tar',
    '22666972': 'csv',
  };

  const fileExtension = signatureMappings[headerString];

  return fileExtension;
}
