import * as BackgroundFetch from 'expo-background-fetch';
import * as TaskManager from 'expo-task-manager';
import {Platform} from 'react-native';

export function defineBackgroundTask(backgroundTask: string, cb: TaskManager.TaskManagerTaskExecutor<unknown>) {
  if (Platform.OS !== 'web' && backgroundTask && cb) {
    TaskManager.defineTask(backgroundTask, cb);
  }
}

export async function registerBackgroundTaskAsync(backgroundTask: string) {
  if (Platform.OS !== 'web' && backgroundTask) {
    return BackgroundFetch.registerTaskAsync(backgroundTask, {
      minimumInterval: 2 * 60, // TODO:  after testing this feature we will increase the Interval time to 15 min (15 * 60)
      stopOnTerminate: false,
      startOnBoot: true,
    });
  }
}

export async function unregisterBackgroundTaskAsync(backgroundTask: string) {
  if (Platform.OS !== 'web' && backgroundTask) {
    return BackgroundFetch.unregisterTaskAsync(backgroundTask);
  }
}
