import React, {useEffect, useState} from 'react';

import {Stack, XStack} from 'tamagui';

import {Text} from '../Text';

export interface StepperProps {
  currentStep: number;
  stepsCount: number;
}

export const Stepper = ({currentStep = 0, stepsCount = 0}: StepperProps) => {
  const [stepsArr, setStepsArr] = useState<number[]>([]);

  useEffect(() => {
    const steps: number[] = [];
    for (let i = 0; i < stepsCount; i++) {
      steps.push(i + 1);
    }
    setStepsArr(steps);
  }, [stepsCount]);

  return (
    <XStack justifyContent="center" alignItems="center">
      {stepsArr.map((step) => {
        return (
          <XStack key={step} justifyContent="center" alignItems="center">
            <Stack
              opacity={step - 1 == currentStep ? 1 : 0.5}
              justifyContent="center"
              alignItems="center"
              width={30}
              height={30}
              backgroundColor={currentStep > step - 1 ? '#196e37' : '#484849'}
              borderRadius={50}>
              <Text position="absolute" color="white">
                {step}
              </Text>
            </Stack>
            {step != stepsCount && <Stack height={2} backgroundColor="lightgray" width={50} />}
          </XStack>
        );
      })}
    </XStack>
  );
};
