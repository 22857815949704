export const getPercentageNumericValue = (prop: number | string) => (prop ? `${prop}`.replace('%', '') : undefined);

export const getPercentageValueParser =
  (allowAbsoluteWithPercentage = true) =>
  (value: string) => {
    if (!value) return undefined;

    const hasPercentage = value.trim().endsWith('%');
    let numericValue = Number.parseFloat(value);

    if (!hasPercentage && allowAbsoluteWithPercentage) return numericValue;

    if (numericValue < 0) {
      numericValue = 0;
    } else if (numericValue > 100) {
      numericValue = 100;
    }
    return `${numericValue}%`;
  };
