import {useMemo} from 'react';

import {useAppletsList} from '../../context';
import {Applet} from '../../types';

export const useAppletByComponentIdMap = () => {
  const applets = useAppletsList();

  return useMemo(() => {
    const componentToAppletMap = new Map<string, Applet>();
    applets.forEach((applet) => {
      applet.services.forEach(({id}) => componentToAppletMap.set(id, applet));
      applet.widgets.forEach(({id}) => componentToAppletMap.set(id, applet));
      applet.configurations.forEach(({id}) => componentToAppletMap.set(id, applet));
      applet.userConfigurations.forEach(({id}) => componentToAppletMap.set(id, applet));
    });

    return componentToAppletMap;
  }, [applets]);
};
