import {BuilderPropsGroup} from '../../../../types';

import {Measures, Spacing, Borders, Alignment, Style} from './sections';

export const LayoutPropsBuilder: BuilderPropsGroup<any> = {
  title: 'design',
  sections: [
    {
      title: 'settings.spacing',
      Content: Spacing,
    },
    {
      title: 'settings.measures',
      Content: Measures,
    },
    {
      title: 'settings.borders.label',
      Content: Borders,
    },
    {
      title: 'settings.alignment',
      Content: Alignment,
    },
    {
      title: 'settings.style.label',
      Content: Style,
    },
  ],
};
