import React, {ReactNode} from 'react';

import {Tooltip as BaseTooltip, TooltipProps as BaseTooltipProps} from 'tamagui';

export type TooltipProps = {
  children: Exclude<ReactNode, string | number | boolean>;
  content: ReactNode;
  hide?: boolean;
} & BaseTooltipProps;

export function Tooltip({content, children, hide, ...props}: TooltipProps) {
  return (
    <BaseTooltip {...props}>
      <BaseTooltip.Trigger>{children}</BaseTooltip.Trigger>

      <BaseTooltip.Content
        display={hide ? 'none' : 'flex'}
        enterStyle={{x: 0, y: -5, opacity: 0, scale: 0.9}}
        exitStyle={{x: 0, y: -5, opacity: 0, scale: 0.9}}
        scale={1}
        x={0}
        y={0}
        opacity={1}
        backgroundColor="black"
        padding="$8"
        animation={['quick', {opacity: {overshootClamping: true}}]}>
        <BaseTooltip.Arrow />
        {content}
      </BaseTooltip.Content>
    </BaseTooltip>
  );
}
