import {useCallback} from 'react';

import {useNavigation} from '@react-navigation/native';

import {useAppletContext} from '../../context';
import {appletServicesNamespace} from '../buildAppletFromTemplate';
import {appletScopedKey} from '../scopedKeys';

export function useNavigateToService() {
  const appletId = useAppletContext();
  const navigation = useNavigation<any>();

  // todo: make params typed
  return useCallback(
    (serviceLocalId: string, params?: any) => {
      const roueId = appletScopedKey(appletId, serviceLocalId, appletServicesNamespace);
      navigation.navigate(roueId, params);
    },
    [appletId, navigation],
  );
}
