import React from 'react';

import {Block, isBuilderWidgetElement, isLeafElement} from '../../../../types';
import {usePropsByViewPortSize} from '../../../../utils';
import {View} from '../../../View';

type PageElementProps = {
  child: Block;
  renderPageContent: (children: Block[]) => JSX.Element[];
  builderContentElements: Record<string, any>;
};

export const PageElement = ({child, renderPageContent, builderContentElements}: PageElementProps) => {
  const element = builderContentElements[child.definitionId];
  const nodeProps = usePropsByViewPortSize({...element?.defaultProps, ...child.props});

  if (isLeafElement(child)) {
    return isBuilderWidgetElement(element) ? (
      <View maxWidth="100%" maxHeight={400} key={child.id} {...nodeProps}>
        <element.Component />
      </View>
    ) : (
      <element.Component key={child.id} {...nodeProps} />
    );
  }

  return (
    <element.Component key={child.id} {...nodeProps}>
      {renderPageContent(child.children || [])}
    </element.Component>
  );
};
