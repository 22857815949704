import { useIsomorphicLayoutEffect } from "@tamagui/constants";
import { Stack, Text, styled, useEvent } from "@tamagui/core";
import { Portal } from "@tamagui/portal";
import { VisuallyHidden } from "@tamagui/visually-hidden";
import * as React from "react";
import { useToastProviderContext } from "./ToastProvider.mjs";
import { jsx, jsxs } from "react/jsx-runtime";
const ToastAnnounceExcludeFrame = styled(Stack, {
    name: "ToastAnnounceExclude"
  }),
  ToastAnnounceExclude = React.forwardRef((props, forwardedRef) => {
    const {
      altText,
      ...announceExcludeProps
    } = props;
    return /* @__PURE__ */jsx(ToastAnnounceExcludeFrame, {
      "data-toast-announce-exclude": "",
      "data-toast-announce-alt": altText || void 0,
      ...announceExcludeProps,
      ref: forwardedRef
    });
  }),
  ToastAnnounce = props => {
    const {
        __scopeToast,
        children,
        ...announceProps
      } = props,
      context = useToastProviderContext(__scopeToast),
      [renderAnnounceText, setRenderAnnounceText] = React.useState(!1),
      [isAnnounced, setIsAnnounced] = React.useState(!1);
    return useNextFrame(() => setRenderAnnounceText(!0)), React.useEffect(() => {
      const timer = setTimeout(() => setIsAnnounced(!0), 1e3);
      return () => clearTimeout(timer);
    }, []), isAnnounced ? null : /* @__PURE__ */jsx(Portal, {
      asChild: !0,
      children: /* @__PURE__ */jsx(VisuallyHidden, {
        ...announceProps,
        children: renderAnnounceText && /* @__PURE__ */jsxs(Text, {
          children: [context.label, " ", children]
        })
      })
    });
  };
function useNextFrame(callback = () => {}) {
  const fn = useEvent(callback);
  useIsomorphicLayoutEffect(() => {
    let raf1 = 0,
      raf2 = 0;
    return raf1 = requestAnimationFrame(() => {
      raf2 = requestAnimationFrame(fn);
    }), () => {
      cancelAnimationFrame(raf1), cancelAnimationFrame(raf2);
    };
  }, [fn]);
}
export { ToastAnnounce, ToastAnnounceExclude };