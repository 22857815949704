import {randomUUID} from 'expo-crypto';

import {AppPageType, AppServicePage} from '../../types';

export function createAppServicePage(appletId, serviceId): AppServicePage {
  return {
    id: randomUUID(),
    type: AppPageType.SERVICE,
    appletId,
    serviceId,
  };
}
