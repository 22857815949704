import React, {ReactNode} from 'react';

import {Typography} from '../Typography';
import {ViewProps} from '../View';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

export type ListItemProps = ViewProps & {
  prefix?: ReactNode;
  suffix?: ReactNode;
  title?: ReactNode;
  subTitle?: ReactNode;
};

export const ListItem = ({prefix, suffix, title, subTitle, disabled, ...props}: ListItemProps) => {
  const hoverStyle: ViewProps = disabled ? {} : {borderRadius: 30, backgroundColor: '$accentLight'};

  return (
    <XStack
      padding="$4"
      paddingHorizontal="$12"
      gap="$3"
      height="$5"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      backgroundColor={disabled ? '$neutral-200' : '$background'}
      hoverStyle={hoverStyle}
      {...props}>
      {prefix}
      <YStack flex={1} marginHorizontal="$4">
        <Typography>{title}</Typography>
        {subTitle && <Typography variant="caption">{subTitle}</Typography>}
      </YStack>
      {suffix}
    </XStack>
  );
};
