import {useMemo} from 'react';

import {useAppletsList} from '../../context';
import {AppletService} from '../../types';

export const useServiceByIdMap = () => {
  const applets = useAppletsList();

  return useMemo(() => {
    const serviceByIdMap = new Map<string, AppletService>();
    applets.forEach(({services}) => {
      services.forEach((service) => serviceByIdMap.set(service.id, service));
    });

    return serviceByIdMap;
  }, [applets]);
};
