import React from 'react';

import {
  Progress as BaseProgress,
  ProgressProps as BaseProgressProps,
  ColorTokens,
  ProgressIndicatorProps,
} from 'tamagui';

export type ProgressBarProps = BaseProgressProps & {
  color?: ColorTokens;
  animation?: ProgressIndicatorProps['animation'];
};

export const ProgressBar = ({color = '$green-600', animation = 'bouncy', ...props}: ProgressBarProps) => {
  return (
    <BaseProgress backgroundColor="$neutral-200" {...props}>
      <BaseProgress.Indicator animation={animation} backgroundColor={color || '$accent'} />
    </BaseProgress>
  );
};
