import createLucideIcon from '../createLucideIcon';

const BookHeadphones = createLucideIcon('BookHeadphones', [
  [
    'path',
    {
      d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20',
      key: 't4utmx',
    },
  ],
  ['circle', { cx: '9', cy: '12', r: '1', key: '1vctgf' }],
  ['path', { d: 'M8 12v-2a4 4 0 0 1 8 0v2', key: '1vsqkj' }],
  ['circle', { cx: '15', cy: '12', r: '1', key: '1tmaij' }],
]);

export default BookHeadphones;
