import React, {ReactNode, useMemo, ComponentProps} from 'react';

import {YStack, XStack} from 'tamagui';

import {isDefined} from '../../utils/isDefined';
import {TextProps, Text} from '../Text';
import {View} from '../View';

type Props = ComponentProps<typeof YStack> & {
  keyName?: ReactNode;
  value?: ReactNode;
  renderValue?: () => ReactNode;
  labelProps?: TextProps;
  valueProps?: TextProps;
  horizontal?: boolean;
};

export const KeyValue: React.FC<Props> = ({
  keyName,
  value,
  renderValue,
  labelProps = {},
  valueProps = {},
  horizontal = false,
  ...props
}) => {
  const Wrapper = useMemo(() => (horizontal ? XStack : YStack), [horizontal]);
  return (
    <Wrapper space={1} maxWidth="100%" alignItems="flex-start" padding={2} position="relative" zIndex={1} {...props}>
      <Text fontSize="$16" color="$textColor" fontWeight="bold" {...labelProps}>
        {keyName}
      </Text>
      <View>{renderValue ? renderValue() : <Text {...valueProps}>{isDefined(value) ? value : '-'}</Text>}</View>
    </Wrapper>
  );
};
