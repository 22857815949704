import React, {useCallback} from 'react';

import {useToastController} from '@tamagui/toast';
import {useTranslation} from 'react-i18next';

import {ShowToastType} from './models';

export const showToastRef = React.createRef<ShowToastType>();

export const showToast: ShowToastType = (...args) => {
  showToastRef.current?.(...args);
  return true;
};

export const useShowToast = () => {
  const toast = useToastController();
  const {t} = useTranslation();

  return useCallback<ShowToastType>(
    ({title, message, preset}) => {
      return toast.show(t(title), {
        message: message && t(message),
        burntOptions: {preset} as any,
      });
    },
    [toast],
  );
};
