import React, {forwardRef} from 'react';

import {LocalizedTextType, BlockDefinition, BlockCategory} from '../../../../types';
import {coreScopedKey} from '../../../../utils';
import {Link, LinkProps} from '../../../Link';
import {LocalizedText} from '../../../LocalizedText';
import {Typography, TypographyProps} from '../../../Typography';
import {View, ViewProps} from '../../../View';
import {LayoutPropsBuilder} from '../../components';

import {LinkPropsBuilder} from './PropsBuilder';

export type LinkElementProps = ViewProps &
  LinkProps & {
    value?: LocalizedTextType;
    fontSize?: TypographyProps['fontSize'];
    color?: TypographyProps['color'];
    lineHeight?: TypographyProps['lineHeight'];
    fontWeight?: TypographyProps['fontWeight'];
  };

const defaultProps: LinkElementProps = {
  href: 'https://naseej.com/media-center/news/',
  value: 'Visit Naseej.com',
  fontSize: 20,
  color: '$accent',
  gap: 10,
};

export const Component = ({value, color, gap, fontSize, lineHeight, fontWeight, ...props}: LinkElementProps, ref) => {
  return (
    <View alignItems="center" justifyContent="flex-start" {...props} ref={ref}>
      <Link href={props.href} textDecorationLine="none" flexDirection="row" flex={0} gap={gap}>
        <Typography color={color} fontSize={fontSize} lineHeight={lineHeight} fontWeight={fontWeight}>
          <LocalizedText value={value} />
        </Typography>
      </Link>
    </View>
  );
};

export const LinkComponent = forwardRef(Component);

export const LinkElement: BlockDefinition<LinkElementProps> = {
  name: coreScopedKey('blocks.LinkElement'),
  version: 1,
  id: 'LinkElement',
  props: defaultProps,
  category: BlockCategory.NODE,
  Component: LinkComponent,
  Icon: 'ExternalLink',
  propsBuilder: [LinkPropsBuilder, LayoutPropsBuilder],
};
