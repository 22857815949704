import {appletName, configurationTransKey, widgetNameKey} from '../../components/ScopedTrans';
import {withAppletContext} from '../../context/AppletContext';
import {Applet, AppletTemplate, BaseWidget, WidgetOmitGenerated} from '../../types';
import {isDefined} from '../isDefined';
import {appletScopedKey} from '../scopedKeys';

export const appletServicesNamespace = 'services';
export const appletWidgetsNamespace = 'widgets';

function processAppletWidget(
  applet: Applet,
  appletId,
  widgets: WidgetOmitGenerated<BaseWidget>[],
  nameKey,
  namespace,
): BaseWidget[] {
  return widgets.filter(isDefined).map((it) => {
    // extract localizations and merge it with applet
    if (it.localizations) {
      Object.entries(it.localizations)?.forEach(([lang, localizations]) => {
        applet.localizations[lang] = {...applet.localizations[lang], [it.id]: localizations};
      });
    }

    return {
      ...it,
      appletId: appletId,
      category: it.category ? appletScopedKey(appletId, it.category) : null,
      localId: it.id,
      id: appletScopedKey(appletId, it.id, namespace),
      name: nameKey(appletId, it.id),
      Component: withAppletContext(it.Component, appletId),
    };
  });
}

export function buildAppletFromTemplate(appletTemplate: AppletTemplate) {
  const applet = {...appletTemplate} as Applet;

  applet.name = appletName(appletTemplate.id);

  applet.widgets = processAppletWidget(
    applet,
    appletTemplate.id,
    appletTemplate.widgets,
    widgetNameKey,
    appletWidgetsNamespace,
  );
  applet.services = processAppletWidget(
    applet,
    appletTemplate.id,
    appletTemplate.services,
    widgetNameKey,
    appletServicesNamespace,
  );
  applet.configurations = processAppletWidget(
    applet,
    appletTemplate.id,
    appletTemplate.configurations,
    configurationTransKey,
    'configurations',
  );
  applet.userConfigurations = processAppletWidget(
    applet,
    appletTemplate.id,
    appletTemplate.userConfigurations,
    configurationTransKey,
    'configurations',
  );

  return applet;
}
