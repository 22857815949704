import React, {ReactNode} from 'react';

import {Typography} from '../../../Typography';
type EditSectionTitleProps = {
  title: ReactNode;
};

export const EditSectionTitle = ({title}: EditSectionTitleProps) => {
  return (
    <Typography fontWeight="600" color="$accent" variant="h6" lineHeight="$8">
      {title}
    </Typography>
  );
};
