import React from 'react';

/* eslint-disable import/order */
import {EventContentArg} from '@fullcalendar/core';
/* eslint-enable import/order */

import {EventImpl} from '@fullcalendar/core/internal';

import {CalendarEvent, CalendarViewsType} from '../../../../types';
import {EventItem} from '../../../EventItem';

export const EventHolder = ({eventContent}: {eventContent: EventContentArg}) => {
  const {title, start, end, id} = eventContent.event as EventImpl;
  const extendedProps = eventContent.event.extendedProps as Omit<CalendarEvent, 'title' | 'id'>;

  const eventItem = {
    title,
    id,
    start: start || undefined,
    end: end || undefined,
    ...extendedProps,
  } as CalendarEvent;

  const currentView = eventContent.view.type as CalendarViewsType;

  return <EventItem currentView={currentView} eventItem={eventItem} />;
};
