import i18n from 'i18next';

import {Applet} from '../../types';
import {appletScopedKey} from '../scopedKeys';

export function loadAppletLocalizations(applet: Applet) {
  const localizations = applet.localizations;
  Object.keys(localizations).forEach((langKey) => {
    const lang = localizations[langKey];
    i18n.addResourceBundle(langKey, 'translation', {[appletScopedKey(applet.id)]: lang}, true, true);
  });
}
