import React, {useState} from 'react';

import {Select} from 'antd';
const {Option} = Select;

import {IconView} from '../IconView';

import styles from './styles.module.css';

enum BreakPoints {
  BASE = 'base',
  SM = 'sm',
  MD = 'md',
}

const breakPointsList = [BreakPoints.BASE, BreakPoints.SM, BreakPoints.MD];
const breakPointsListIcon = {
  [BreakPoints.BASE]: <IconView icon="Smartphone" fill />,
  [BreakPoints.SM]: <IconView icon="Tablet" fill />,
  [BreakPoints.MD]: <IconView icon="Laptop2" fill />,
};

type Value = Partial<{[breakpoint in BreakPoints]: any}> | any;

interface Props {
  value: Value;
  onChange?: (value: Value) => void;
  input: ({mediaValue, onMediaValueChange}: any) => any;
  parseValue?: (value: any) => any;
  transformValue?: (value: any) => any;
}

const noop = (value: any) => value;

const getMediaValue = (value: any, breakpoint: any): any => {
  if (!breakpoint) {
    return undefined;
  }

  if (value[breakpoint]) {
    return value[breakpoint];
  }

  const prevBreakpointIndex = breakPointsList.findIndex((bp) => bp === breakpoint) - 1;
  return getMediaValue(value, breakPointsList[prevBreakpointIndex]);
};

export const MediaQueryInput = ({input, value, onChange, parseValue = noop, transformValue = noop}: Props) => {
  const [selectedBreakpoint, setSelectedBreakpoint] = useState(BreakPoints.BASE);

  if (value === null || value === undefined) {
    value = {[BreakPoints.BASE]: undefined};
  }

  if (typeof value === 'string' || typeof value === 'number') {
    value = {[BreakPoints.BASE]: value};
  }

  const mediaValue = getMediaValue(value, selectedBreakpoint);

  return (
    <div className={styles.inputContainer}>
      <div className={styles.input}>
        {input({
          mediaValue: parseValue(mediaValue),
          onMediaValueChange: (newValue: any) => {
            onChange && onChange({...(value as any), [selectedBreakpoint]: transformValue(newValue)});
          },
        })}
      </div>
      <div className={styles.select}>
        <Select
          className="compact-options"
          popupClassName="compact-options"
          size="middle"
          value={selectedBreakpoint}
          onSelect={setSelectedBreakpoint}
          suffixIcon={null}>
          {breakPointsList.map((breakpoint) => (
            <Option key={breakpoint} value={breakpoint}>
              {breakPointsListIcon[breakpoint]}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};
