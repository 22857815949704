import {ComponentProps, ReactNode} from 'react';

import {Button as BaseButton, ButtonProps as BaseButtonProps, ThemeParsed} from 'tamagui';

import {TypographyFontWeight} from '../Typography';

import {ButtonTypeColors} from './config';

export enum ButtonVariants {
  filled = 'filled',
  outlined = 'outlined',
  empty = 'empty',
}
export type ButtonColorTypes = keyof typeof ButtonTypeColors;

export type UseThemeResult = {
  [key in keyof ThemeParsed]: ThemeParsed[key] & {
    get: () => string | ThemeParsed[key]['val'];
  };
};

export type Colors = ButtonProps['color'];

// todo: handle tamagui adding variant prop
export type ButtonProps = Omit<ComponentProps<typeof BaseButton>, 'variant'> & {
  loading?: boolean;
  hoverStyle?: BaseButtonProps['hoverStyle'];
  variant?: keyof typeof ButtonVariants;
  type?: ButtonColorTypes;
  suffix?: ReactNode;
  prefix?: ReactNode;
  fontWeight?: TypographyFontWeight;
};
