// todo: add docs
import React, {useContext} from 'react';

import {DiscoveryDocument} from 'expo-auth-session';

import {SSOConfig} from '../components/AuthProvider/models';

export interface AuthResponse {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  idToken: string;
  issuedAt: number;
  scope: string;
  tokenType: string;
}

export interface AuthContextValue {
  authResponse?: AuthResponse;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  refreshToken: () => Promise<AuthResponse | undefined>;
  config?: SSOConfig;
  discovery: DiscoveryDocument | null;
}

const AuthContext = React.createContext<AuthContextValue>({
  login: async () => {},
  logout: async () => {},
  refreshToken: async () => undefined,
  discovery: {},
});

export const AuthContextProvider = AuthContext.Provider;

export const useAuthResponse = () => {
  return useContext(AuthContext).authResponse;
};

export const useAccessToken = () => {
  return useAuthResponse()?.accessToken;
};

export const useIsAuthenticated = () => {
  return !!useAccessToken();
};

export const useAuth = () => {
  return useContext(AuthContext);
};
