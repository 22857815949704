import React from 'react';

import HTML, {RenderHTMLProps} from 'react-native-render-html';

import {View, ViewProps} from '../View';

type HtmlViewProps = ViewProps & {
  content: string;
  htmlProps?: Omit<RenderHTMLProps, 'source'>;
};

export const HtmlView: React.FC<HtmlViewProps> = ({content, htmlProps, ...props}) => {
  return (
    <View {...props}>
      <HTML source={{html: content}} {...htmlProps} />
    </View>
  );
};
