import React from 'react';

import {WebView as RNWebView, WebViewProps as RNWebViewProps} from 'react-native-webview';

import {Spinner} from '../Spinner';

export type WebViewProps = RNWebViewProps & {disabled?: boolean};

export const WebView = (props: WebViewProps) => {
  if (!props.source) return null;

  return (
    <RNWebView
      {...props}
      style={{width: '100%', height: '100%', pointerEvents: props.disabled ? 'none' : undefined}}
      renderLoading={() => <Spinner />}
      scalesPageToFit={false}
    />
  );
};
