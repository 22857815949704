import React, {useContext} from 'react';

import {CalendarProps} from '../types';

export const CalendarContext = React.createContext<CalendarProps>({
  events: [],
  weekStartDay: 0,
});

export const useCalendar = () => {
  return useContext(CalendarContext);
};

export const CalendarProvider = CalendarContext.Provider;
