import {SortedBy} from '../model';

export const sortByMultipleKeys = <T>(data: T[], sortBy: SortedBy<T>): [T[], boolean] => {
  const sortByKeys = Array.from(sortBy.keys());

  if (!sortByKeys.length) {
    return [data, false];
  }

  const compareByKey = (a: T, b: T, key: keyof T) => {
    const sortDirection = sortBy.get(key);
    if (!sortDirection) return 0;

    if (a[key] > b[key]) {
      return sortDirection * -1;
    } else if (a[key] < b[key]) {
      return sortDirection * 1;
    }

    return 0;
  };

  data = data.sort((a, b) => {
    for (const key of sortByKeys) {
      const compareResult = compareByKey(a, b, key);
      if (compareResult !== 0) {
        return compareResult;
      }
    }

    return 0;
  });

  return [data, true];
};
