export const sleep = (duration: number) => {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), duration));
};

export const timeout = (duration: number, ...operations: Promise<any>[]) => {
  return Promise.race([
    Promise.all(operations),
    sleep(duration).then(() => {
      throw 'operation timeout';
    }),
  ]);
};
