export const hijriMonths = [
  'Muharram',
  'Safar',
  'RabiAlAwwal',
  'RabiAlThani',
  'JumadaAlUla',
  'JumadaAlThaniyah',
  'Rajab',
  'Shaaban',
  'Ramadan',
  'Shawwal',
  'DhuAlQidah',
  'DhuAlHijjah',
];
