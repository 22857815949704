import React from 'react';

import {nanoid} from 'nanoid';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {Trans, useTranslation} from 'react-i18next';

import {BuilderPropsGroup, PropsBuilder} from '../../../../types';
import {coreScopedKey, usePropsByViewPortSize} from '../../../../utils';
import {Button} from '../../../Button';
import {IconView} from '../../../IconView';
import {PropsBuilderContainer} from '../../components/PropsBuilderContainer';
import {AccordionElementProps} from '../Accordion';

import {AccordionSectionContent} from './components/AccordionSectionContent';

export const _AccordionPropsBuilder: PropsBuilder<AccordionElementProps> = ({value: propsValue, onChange}) => {
  const {t} = useTranslation();

  const {sections} = usePropsByViewPortSize(propsValue);

  const onUpdateProps = (key: string, value: any) => {
    onChange(key, value);
  };

  const handleTitleChange = (newValue: string, id?: string) => {
    const newSections = sections.map((section) => (section.id === id ? {...section, title: newValue} : section));
    onUpdateProps('sections', newSections);
  };

  const handleContentChange = (newValue: string, id?: string) => {
    const newSections = sections.map((section) => (section.id === id ? {...section, content: newValue} : section));
    onUpdateProps('sections', newSections);
  };

  const handleAddNewSection = () => {
    const newSections = [
      ...sections,
      {
        id: nanoid(),
        title: t('settings.accordion.newSection'),
        content: t('settings.typography.initialText'),
      },
    ];
    onUpdateProps('sections', newSections);
  };

  const handleDeleteSection = (id?: string) => {
    const newSections = sections.filter((s) => s.id !== id);
    onUpdateProps('sections', newSections);
  };

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const dragItem = sections[dragIndex];
    const newSections = [...sections];
    newSections.splice(dragIndex, 1);
    newSections.splice(hoverIndex, 0, dragItem);
    onUpdateProps('sections', newSections);
  };

  return (
    <PropsBuilderContainer>
      <DndProvider backend={HTML5Backend}>
        {sections.map((section, index) => (
          <AccordionSectionContent
            key={index}
            index={index}
            section={section}
            handleTitleChange={handleTitleChange}
            handleDeleteSection={handleDeleteSection}
            handleContentChange={handleContentChange}
            moveItem={moveItem}
          />
        ))}
      </DndProvider>

      <Button prefix={<IconView icon="PlusCircle" />} variant="empty" onPress={handleAddNewSection}>
        <Trans i18nKey="action.add" />
      </Button>
    </PropsBuilderContainer>
  );
};

export const AccordionPropsBuilder: BuilderPropsGroup<AccordionElementProps> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.AccordionElement'),
      Content: _AccordionPropsBuilder,
    },
  ],
};
