import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../types';
import {coreScopedKey} from '../../../../utils';
import {View, ViewProps} from '../../../View';
import {LayoutPropsBuilder} from '../../components';

export type RowElementProps = ViewProps;

const defaultProps: RowElementProps = {
  position: 'relative',
  borderRadius: 4,
  paddingHorizontal: 3,
  paddingBottom: 3,
  padding: 20,
  maxWidth: '100%',
  gap: 6,
  flexDirection: 'row',
  alignItems: 'center',
  $md: {
    flexWrap: 'wrap',
  },
  $sm: {
    flexWrap: 'wrap',
  },
};

export const Component = ({children, ...props}: RowElementProps, ref) => {
  return (
    <View flexDirection="row" {...props} ref={ref}>
      {children}
    </View>
  );
};

export const RowComponent = forwardRef(Component);

export const RowElement: BlockDefinition<RowElementProps> = {
  name: coreScopedKey('blocks.RowElement'),
  version: 1,
  id: 'RowElement',
  props: defaultProps,
  children: [],
  Component: RowComponent,
  category: BlockCategory.LAYOUT,
  Icon: 'Columns',
  propsBuilder: [LayoutPropsBuilder],
};
