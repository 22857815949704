import React from 'react';

import {Input} from 'antd';

type Props = {
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  type?: string;
  style?: React.CSSProperties;
};

export const SideBarInput = ({value, type = 'number', style, onChange}: Props) => {
  return (
    <Input
      value={value}
      type={type}
      onChange={onChange}
      style={{backgroundColor: 'ButtonHighlight', borderColor: 'transparent', ...style}}
    />
  );
};
