import React, {ReactNode, useCallback} from 'react';

import {useNavigation} from '@react-navigation/native';

import {LocalizedTextType} from '../../types';
import {navigateBack} from '../../utils/navigateToRoot';
import {LocalizedText} from '../LocalizedText';
import {NavigateBackButton} from '../NavigateBackButton';
import {Typography} from '../Typography';
import {ViewProps} from '../View';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

export type NavigationHeaderProps = Omit<ViewProps, 'content'> & {
  title?: LocalizedTextType;
  subTitle?: ReactNode;
  actions?: ReactNode;
  content?: ReactNode;
  backNavigationDisabled?: boolean;
  onBack?: () => void;
};

export const NavigationHeader = ({
  backNavigationDisabled,
  content,
  actions,
  title,
  onBack,
  ...props
}: NavigationHeaderProps): JSX.Element => {
  const navigation = useNavigation<any>();

  const goBack = useCallback(() => navigateBack(navigation), []);

  return (
    <YStack alignItems="center" justifyContent="center" height="$6" backgroundColor="$background" {...props}>
      <XStack
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        paddingHorizontal="$32"
        $sm={{paddingHorizontal: '$16'}}>
        <XStack justifyContent="center" alignItems="center" space="$3" gap="$6">
          {!backNavigationDisabled && <NavigateBackButton onBack={onBack || goBack} />}

          <YStack
            alignItems="center"
            justifyContent="center"
            marginHorizontal={backNavigationDisabled ? '$2' : undefined}>
            <Typography fontWeight="700" variant="h5">
              <LocalizedText value={title} />
            </Typography>
          </YStack>
        </XStack>

        {actions}
      </XStack>

      {content}
    </YStack>
  );
};
