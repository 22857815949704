import {useMemo} from 'react';

import {useAppletsList} from '../../context/AppContext';
import {useAppletContext} from '../../context/AppletContext';

export function useApplet(appletId?: string) {
  const appletsList = useAppletsList();
  const scopedAppletId = useAppletContext();

  return useMemo(
    () => appletsList.find((applet) => applet.id === (appletId || scopedAppletId)),
    [appletId, scopedAppletId, appletsList],
  );
}
