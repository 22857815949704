import React, {useState} from 'react';

import {useMedia} from 'tamagui';

import {dayjs} from '../../utils/extendedDayjs';
import {YStack} from '../YStack';

import {DatepickerContent} from './components/DatepickerContent';
import {DatepickerProvider} from './context/DatepickerProvider';
import {FocusedInput, START_DATE, useDatepicker, OnDatesChangeProps} from './hooks';
import {DatepickerProps} from './models';

export const Datepicker = ({
  value,
  mode = 'single',
  type = 'gregory',
  onChange,
  format = 'YYYY-MM-DD',
  minimumDate,
  maximumDate,
  excludeDates,
  allowedDates,
  isDisabled,
  containerProps,
  filterDate,
}: DatepickerProps) => {
  const media = useMedia();

  const [state, setState] = useState<OnDatesChangeProps>({
    startDate: Array.isArray(value) && value.length > 1 ? new Date(value[0]) : new Date(),
    endDate: Array.isArray(value) && value.length > 1 ? new Date(value[1]) : new Date(),
    focusedInput: 'startDate',
  });

  const monthsCount: number = mode === 'range' ? (media.xs ? 1 : 2) : 1;

  const filterBlockedDates = (date: Date) => {
    const normalizedDate = date.setHours(0, 0, 0, 0);
    let isDateBlocked = false;

    if (allowedDates) {
      const normalizedAllowedDates = allowedDates.map((date) => date.setHours(0, 0, 0, 0));
      isDateBlocked = !normalizedAllowedDates?.includes(normalizedDate);
    }

    if (isDisabled) {
      return isDateBlocked;
    }

    if (excludeDates) {
      const normalizedExcludeDates = excludeDates.map((date) => date.setHours(0, 0, 0, 0));
      isDateBlocked = normalizedExcludeDates?.includes(normalizedDate);
    }
    return isDateBlocked;
  };

  const {activeMonths, firstDayOfWeek, ...context} = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    maxBookingDate: maximumDate,
    minBookingDate: minimumDate,
    isDateBlocked: typeof filterDate === 'function' ? filterDate : filterBlockedDates,
    focusedInput: state.focusedInput as FocusedInput,
    onDatesChange: (data) => {
      if (!data.focusedInput) {
        setState({...data, focusedInput: START_DATE});
      } else {
        setState({...data});
      }
      if (typeof onChange === 'function') {
        const range: string[] = [];
        let date;
        if (mode === 'range') {
          range[0] = dayjs(data.startDate).calendar(type).format(format);
          range[1] = dayjs(data.endDate).calendar(type).format(format);
          date = range;
        } else {
          date = dayjs(data.startDate).calendar(type).format(format);
        }
        onChange(date);
      }
    },
    numberOfMonths: monthsCount,
    // todo : change this implementation
    ...(mode !== 'range' && {
      minSelectedDays: 1,
      exactMinSelectedDays: true,
    }),
  });

  return (
    <DatepickerProvider {...context}>
      <YStack
        backgroundColor="$background"
        borderRadius={30}
        padding="$8"
        $xs={{borderWidth: '$0', borderRadius: '$0', borderTopWidth: '$2'}}
        {...containerProps}>
        {activeMonths.map((month, index) => (
          <DatepickerContent
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            monthsCount={monthsCount}
            firstDayOfWeek={firstDayOfWeek}
            date={month.date}
            type={type}
            isFirstMonth={monthsCount === 0 || index === 0}
            isLastMonth={monthsCount === 0 || index === monthsCount - 1}
          />
        ))}
      </YStack>
    </DatepickerProvider>
  );
};
