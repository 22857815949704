import React from 'react';

import {Option} from '../../../types';
import {Checkbox} from '../../Checkbox';
import {ListItem} from '../../ListItem';
import {LocalizedText} from '../../LocalizedText';

export type OptionListProps = {
  options?: Option[];
  onChangeSelection: (value: Option) => void;
  isSelected: (item: Option) => boolean;
};

export const OptionList = ({options, onChangeSelection, isSelected}: OptionListProps) => {
  return (
    <>
      {options?.map((option) => (
        <ListItem
          onPress={() => onChangeSelection(option)}
          key={option.value}
          cursor="pointer"
          title={<LocalizedText value={option.label} />}
          prefix={<Checkbox checked={isSelected(option)} />}
        />
      ))}
    </>
  );
};
