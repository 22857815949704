import {NavigationProp} from '@react-navigation/native';
import {randomUUID} from 'expo-crypto';

export function navigateBack(navigation: NavigationProp<any>) {
  navigation.canGoBack() ? navigation.goBack() : navigateToRoot(navigation);
}

export function navigateToRoot(navigation: NavigationProp<any>) {
  let mainNavigation = navigation;
  while (mainNavigation.getParent()) {
    mainNavigation = mainNavigation.getParent();
  }

  const navigationState = mainNavigation.getState();
  const root = navigationState.routeNames[0];

  mainNavigation.reset({...navigationState, routes: [{name: root, key: randomUUID()}], index: 0});
}
