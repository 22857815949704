import React, {useState, useEffect} from 'react';

import {AppState} from 'react-native';
import {Platform} from 'react-native';
import YoutubePlayerModule from 'react-native-youtube-iframe';

export type YoutubePlayerProps = {url?: string; disabled?: boolean};

export const YoutubePlayer = ({url, disabled}: YoutubePlayerProps) => {
  const [playing, setPlaying] = useState(true);

  const extractedID = () => {
    let video_id = url?.split('v=')[1];
    const ampersandPosition = video_id?.indexOf('&');
    if (ampersandPosition != -1) {
      video_id = video_id?.substring(0, ampersandPosition);
    }
    return video_id;
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      const subscription = AppState.addEventListener('change', (nextAppState) => {
        if (nextAppState !== 'active') {
          setPlaying(false);
        }
      });
      return () => {
        subscription.remove();
      };
    }
  }, []);

  return (
    <YoutubePlayerModule
      height={'100%' as any}
      play={playing}
      videoId={extractedID()}
      webViewStyle={disabled ? ({pointerEvents: 'none'} as any) : undefined}
    />
  );
};
