import createLucideIcon from '../createLucideIcon';

const Globe = createLucideIcon('Globe', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  [
    'path',
    { d: 'M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20', key: '13o1zl' },
  ],
  ['path', { d: 'M2 12h20', key: '9i4pu4' }],
]);

export default Globe;
