export function fileHasExtension(file: File, extensions: string[] = []) {
  const extension = file.name?.split('.').pop();

  if (!extension) {
    return false;
  }

  return extensions.includes(extension);
}

export function fileIsImage(file: File) {
  return fileHasExtension(file, ['png', 'jpg', 'jpeg']);
}
