import {BadgeStatusConfig} from '../types';

export const BadgeTypeConfig: BadgeStatusConfig = {
  info: {
    color: '$blue-400',
  },
  success: {
    color: '$green-400',
  },
  danger: {
    color: '$red-400',
  },
  warning: {
    color: '$amber-400',
  },
};
