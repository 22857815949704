import React from 'react';

import {XStack as BaseXStack, XStackProps} from 'tamagui';

import {useDirectionAwareProps} from '../../utils/useDirectionAwareProps';

export const XStack = (props: XStackProps) => {
  const _props = useDirectionAwareProps(props);

  return <BaseXStack {..._props} />;
};
