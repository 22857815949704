import React from 'react';

import {Trans, useTranslation} from 'react-i18next';

import {BuilderPropsGroup, LocalizedTextType, PropsBuilder} from '../../../../types';
import {coreScopedKey, usePropsByViewPortSize} from '../../../../utils';
import {ColorInput} from '../../../ColorInput';
import {Input} from '../../../Input';
import {LocalizedInput} from '../../../LocalizedInput';
import {Select} from '../../../Select';
import {XStack} from '../../../XStack';
import {EditSectionElement, SliderInputValuePicker, SelectButtons} from '../../components';
import {PropsBuilderContainer} from '../../components/PropsBuilderContainer';

import {ButtonElementProps} from './index';

export const _ButtonPropsBuilder: PropsBuilder<ButtonElementProps> = ({value: propsValue, onChange, onReset}) => {
  const {t} = useTranslation();

  const {href, fontSize, alignSelf, value, variant, type, backgroundColor, justifyContent} =
    usePropsByViewPortSize<ButtonElementProps>(propsValue);

  const onUpdateProps = (key: string, value: number | string | LocalizedTextType) => {
    onChange(key, value);
  };

  const onResetProps = (keys: string[]) => {
    onReset?.(keys);
  };

  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<Trans i18nKey="content" />} onReset={() => onResetProps(['value'])}>
        <LocalizedInput
          value={value}
          onChange={(value) => {
            onUpdateProps('value', value);
          }}
          input={({localizedValue, onLocalizedValueChange}) => (
            <Input value={localizedValue} onChangeText={(value) => onLocalizedValueChange(value)} />
          )}
        />
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="settings.basic.url" />}>
        <XStack alignItems="center" justifyContent="space-between">
          <Input value={href} onChangeText={(value) => onUpdateProps('href', value)} />
        </XStack>
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="variant" />} onReset={() => onResetProps(['variant'])}>
        <Select
          value={variant}
          options={[
            {label: t('settings.button.empty'), value: 'empty'},
            {label: t('settings.button.filled'), value: 'filled'},
            {label: t('settings.button.outlined'), value: 'outlined'},
          ]}
          onChange={(value) => onUpdateProps('variant', value)}
          placeholder={t('settings.button.filled')}
        />
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="type" />} onReset={() => onResetProps(['type'])}>
        <Select
          value={type}
          options={[
            {label: t('settings.button.danger'), value: 'danger'},
            {label: t('settings.button.info'), value: 'info'},
            {label: t('settings.button.neutral'), value: 'neutral'},
            {label: t('settings.button.primary'), value: 'primary'},
            {label: t('settings.button.success'), value: 'success'},
            {label: t('settings.button.warning'), value: 'warning'},
          ]}
          onChange={(value) => onUpdateProps('type', value)}
          placeholder={t('settings.button.primary')}
        />
      </EditSectionElement>

      <EditSectionElement
        title={<Trans i18nKey="settings.flex.alignSelf" />}
        onReset={() => onResetProps(['alignSelf'])}>
        <SelectButtons
          value={alignSelf}
          options={[
            {
              action: () => onUpdateProps('alignSelf', 'flex-start'),
              icon: 'AlignHorizontalJustifyStart',
              lable: t('settings.flex.flexStart'),
              optionValue: 'flex-start',
            },
            {
              action: () => onUpdateProps('alignSelf', 'center'),
              icon: 'AlignHorizontalJustifyCenter',
              lable: t('settings.flex.center'),
              optionValue: 'center',
            },
            {
              action: () => onUpdateProps('alignSelf', 'flex-end'),
              icon: 'AlignHorizontalJustifyEnd',
              lable: t('settings.flex.flexEnd'),
              optionValue: 'flex-end',
            },
            {
              action: () => onUpdateProps('alignSelf', 'stretch'),
              icon: 'StretchHorizontal',
              lable: t('settings.flex.stretch'),
              optionValue: 'stretch',
            },
          ]}
        />
      </EditSectionElement>

      <EditSectionElement
        title={<Trans i18nKey="settings.typography.textAlign" />}
        onReset={() => onResetProps(['justifyContent'])}>
        <SelectButtons
          value={justifyContent}
          options={[
            {
              action: () => onUpdateProps('justifyContent', 'flex-start'),
              icon: 'AlignLeft',
              lable: t('settings.typography.left'),
              optionValue: 'flex-start',
            },
            {
              action: () => onUpdateProps('justifyContent', 'center'),
              icon: 'AlignCenter',
              lable: t('settings.typography.center'),
              optionValue: 'center',
            },
            {
              action: () => onUpdateProps('justifyContent', 'flex-end'),
              icon: 'AlignRight',
              lable: t('settings.typography.right'),
              optionValue: 'flex-end',
            },
          ]}
        />
      </EditSectionElement>

      <EditSectionElement
        title={<Trans i18nKey="settings.typography.fontSize" />}
        onReset={() => onResetProps(['fontSize'])}>
        <SliderInputValuePicker value={fontSize} propName="fontSize" onUpdate={onUpdateProps} />
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.typography.fontWeight" />}
        onReset={() => onResetProps(['fontWeight'])}>
        <SelectButtons
          value={alignSelf}
          options={[
            {
              action: () => onUpdateProps('fontWeight', '400'),
              icon: 'CaseSensitive',
              lable: t('settings.typography.thin'),
              optionValue: '400',
            },
            {
              action: () => onUpdateProps('fontWeight', '500'),
              icon: 'Type',
              lable: t('settings.typography.normal'),
              optionValue: '500',
            },
            {
              action: () => onUpdateProps('fontWeight', '700'),
              icon: 'Bold',
              lable: t('settings.typography.bold'),
              optionValue: '700',
            },
          ]}
        />
      </EditSectionElement>

      <EditSectionElement
        title={<Trans i18nKey="settings.style.backgroundColor" />}
        onReset={() => onResetProps(['backgroundColor'])}>
        <ColorInput
          inputProps={{width: '80%'}}
          value={backgroundColor || 'transparent'}
          onChange={(value) => onUpdateProps('backgroundColor', value)}
        />
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};

export const ButtonPropsBuilder: BuilderPropsGroup<ButtonElementProps> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.ButtonElement'),
      Content: _ButtonPropsBuilder,
    },
  ],
};
