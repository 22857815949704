import {UserProfileInfoRegistry, ProfileInfo} from '../types';

class UserInfoRegistry {
  private userInfo: UserProfileInfoRegistry = {};

  registerUserInfo(userData: UserProfileInfoRegistry) {
    const appletIds = Object.keys(userData);

    if (appletIds.length) {
      appletIds.map((id) => {
        this.userInfo[id] = userData[id];
      });
    }
  }

  getUserInfo(): UserProfileInfoRegistry {
    return this.userInfo;
  }
}

export class ScopedProfileRegistry {
  scopedId: string;

  constructor(scopedId: string) {
    this.scopedId = scopedId;
  }
  registerUserInfo(userData: ProfileInfo) {
    userInfoRegistry.registerUserInfo({[this.scopedId]: userData});
  }
}

// todo: dont expose outside of core
export const userInfoRegistry = new UserInfoRegistry();
