import React, {useEffect} from 'react';

import {ToastProvider as BaseToastProvider, ToastViewport, Toast, useToastState} from '@tamagui/toast';
import {useTranslation} from 'react-i18next';
import {Platform} from 'react-native';
import {ColorTokens, XStack, YStack} from 'tamagui';

import {IconListType} from '../../lib/lucide';
import {IconView} from '../IconView';
import {safeAreaStyles} from '../SafeAreaView';

import {ToastPreset} from './models';
import {showToastRef, useShowToast} from './utils';

export const ToastProvider = ({children}) => {
  return (
    <BaseToastProvider>
      <InitializeToastRef />
      {children}
      <ToastViewport portalToRoot width="100%" alignSelf="center" multipleToasts style={safeAreaStyles.shared} />
      <CurrentToast />
    </BaseToastProvider>
  );
};

const InitializeToastRef = () => {
  const showToast = useShowToast();
  const {t} = useTranslation();

  useEffect(() => {
    (showToastRef as any).current = showToast;
  }, [t, showToast]);

  return null;
};

const CurrentToast = () => {
  const currentToast = useToastState();

  if (!currentToast || currentToast.isHandledNatively) return null;

  const presetsOptions: {[key in ToastPreset]: {icon?: IconListType; theme?: ColorTokens}} = {
    done: {icon: 'BadgeCheck', theme: '$green-600'},
    error: {icon: 'AlertOctagon', theme: '$red-600'},
    none: {icon: 'AlertCircle', theme: '$accent'},
    warning: {icon: 'AlertTriangle', theme: '$amber-600'},
  };

  const presetOption = presetsOptions[currentToast.burntOptions?.preset || 'none'];

  return (
    <Toast
      key={currentToast.id}
      duration={currentToast.duration}
      enterStyle={{opacity: 0, scale: 0.5, y: -25}}
      exitStyle={{position: 'absolute', top: 0, opacity: 0, scale: 1, y: -20}}
      y={0}
      opacity={1}
      scale={1}
      animation="100ms"
      minWidth={Platform.OS === 'web' ? 360 : '80%'}
      borderRadius="$rounded-md"
      padding="$20"
      backgroundColor="$background"
      borderColor="$border"
      borderWidth="$1"
      borderLeftWidth="$2"
      borderLeftColor={presetOption.theme}
      viewportName={currentToast.viewportName}>
      <XStack alignItems="center" space="$2" justifyContent="space-between">
        {presetOption?.icon && (
          <YStack
            alignSelf="flex-start"
            animation="quick"
            enterStyle={{scale: 0, rotate: '-100deg', x: 10}}
            x={0}
            scale={1}
            rotate="0deg">
            <IconView color={presetOption.theme} icon={presetOption.icon} size={24} />
          </YStack>
        )}

        <YStack alignSelf="flex-start" flex={1}>
          <Toast.Title animation="quick" fontWeight="600" fontSize="$16">
            {currentToast.title}
          </Toast.Title>
          {currentToast.message && (
            <Toast.Description enterStyle={{x: 20}} x={0} animation="quick" fontSize="$14">
              {currentToast.message}
            </Toast.Description>
          )}
        </YStack>

        <Toast.Close alignSelf="flex-start" backgroundColor="$transparent" borderWidth={0}>
          <IconView icon="X" size={16} />
        </Toast.Close>
      </XStack>
    </Toast>
  );
};
