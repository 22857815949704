import React, {ReactNode} from 'react';

import {Platform} from 'react-native';
import {YStack} from 'tamagui';

import {ViewProps} from '../../View';
import {XStack} from '../../XStack';

type Props<T> = ViewProps & {
  subRow?: ReactNode;
  onLayout?: (e: any, idx?: number) => void;
  extendedView?: (item: T) => React.ReactNode;
  rowExtended?: any;
  item?: T;
  id?: string;
};

export function Row<T>({children, subRow, onLayout, extendedView, rowExtended, item, id, ...props}: Props<T>) {
  const borderProps = {};

  Object.keys(props).forEach((prop) => {
    if (prop.startsWith('border')) {
      borderProps[prop] = props[prop];
      delete props[prop];
    }
  });

  return (
    <YStack
      borderColor="$border"
      borderWidth={0.5}
      {...borderProps}
      onLayout={Platform.OS !== 'web' && id ? (e) => onLayout?.(e, +id) : onLayout}
      id={id}>
      <XStack minHeight={48} alignItems="center" justifyContent="center" {...props}>
        {children}
      </XStack>

      {subRow}

      {rowExtended && item && extendedView?.(item)}
    </YStack>
  );
}
