import {appletScopedKey, coreScopedKey} from '../../utils/scopedKeys';

export const appletName = (id: string) => appletScopedKey(id, '_name');

export const widgetNameKey = (appletId, id) => appletScopedKey(appletId, `${id}._name`);

export const configurationTransKey = (appletId, id) => {
  const name = `settings.${id}._name`;
  return [appletScopedKey(appletId, name), coreScopedKey(name)];
};
