import React from 'react';

import {Anchor, SizableTextProps, Stack, StackProps, AnchorProps} from 'tamagui';

export type LinkProps = StackProps & {
  href?: string;
  lineHeight?: SizableTextProps['lineHeight'];
  textDecorationLine?: AnchorProps['textDecorationLine'];
};

export const Link = ({href, children, textDecorationLine, lineHeight = '$4', ...props}: LinkProps) => {
  return (
    <Anchor target="_blank" href={href} lineHeight={lineHeight} textDecorationLine={textDecorationLine}>
      <Stack {...props}>{children}</Stack>
    </Anchor>
  );
};
