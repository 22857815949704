import {dayjs} from '../../../../utils/extendedDayjs';

type Interval = {
  start: Date | number;
  end: Date | number;
};

export const addDays = (date: Date | number, amount: number): Date => {
  return dayjs(date).add(amount, 'day').toDate();
};

export const isAfter = (date: Date | number, dateToCompare: Date | number): boolean => {
  const parsedDate = dayjs(date);
  const parsedDateToCompare = dayjs(dateToCompare);

  return parsedDate.isAfter(parsedDateToCompare);
};

export const isBefore = (date: Date | number, dateToCompare: Date | number): boolean => {
  const parsedDate = dayjs(date);
  const parsedDateToCompare = dayjs(dateToCompare);

  return parsedDate.isBefore(parsedDateToCompare);
};

export const isSameDay = (date: Date | number, dateToCompare: Date | number): boolean => {
  const parsedDate = dayjs(date);
  const parsedDateToCompare = dayjs(dateToCompare);

  return parsedDate.isSame(parsedDateToCompare, 'day');
};

export const isSameMonth = (date: Date | number, dateToCompare: Date | number): boolean => {
  const parsedDate = dayjs(date);
  const parsedDateToCompare = dayjs(dateToCompare);

  return parsedDate.isSame(parsedDateToCompare, 'month');
};

export const isWithinRange = (date: Date | number, interval: Interval): boolean => {
  const day = dayjs(date);
  const start = dayjs(interval.start);
  const end = dayjs(interval.end);
  return day.isAfter(start) && day.isBefore(end);
};

export const addMonths = (date: Date | number, amount: number): Date => {
  const day = dayjs(date);
  return day.add(amount, 'month').toDate();
};

export const eachDay = (interval: Interval, options: {step?: number} = {}): Date[] => {
  const {start, end} = interval;
  const {step = 1} = options;

  if (dayjs(end).isBefore(start)) {
    throw new RangeError('The start of an interval cannot be after its end');
  }

  const result: Date[] = [];
  let currentDate = dayjs(start);

  while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
    if (!currentDate.isValid()) {
      throw new RangeError('Date in interval cannot be Invalid Date');
    }
    result.push(currentDate.toDate());
    currentDate = currentDate.add(step, 'day');
  }

  return result;
};

export const format = (
  date: Date | number,
  formatString: string,
  options: {
    locale?: any;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  } = {},
): string => {
  let day = dayjs(date);

  if (options.locale) {
    day = day.locale(options.locale);
  }

  if (options.weekStartsOn !== undefined) {
    day = day.startOf('week').set('day', options.weekStartsOn);
  }

  if (options.firstWeekContainsDate !== undefined) {
    day = day.startOf('year').add(options.firstWeekContainsDate - 1, 'day');
  }

  if (options.useAdditionalWeekYearTokens) {
    formatString = formatString.replace('YYYY', day.format('YYYY')).replace('WW', day.format('WW'));
  }

  if (options.useAdditionalDayOfYearTokens) {
    formatString = formatString.replace('DDD', day.format('DDD'));
  }

  return day.format(formatString);
};

export const getMonth = (date: Date | number): number => {
  const day = dayjs(date);
  return day.month();
};

export const getYear = (date: Date | number): number => {
  const day = dayjs(date);
  return day.year();
};

export const startOfMonth = (date: Date | number): Date => {
  const day = dayjs(date);
  return day.startOf('month').toDate();
};

export const startOfToday = (): Date => {
  const today = dayjs().startOf('day');
  return today.toDate();
};

export const endOfMonth = (date: Date | number): Date => {
  const day = dayjs(date);
  return day.endOf('month').toDate();
};

export const endOfWeek = (
  date: Date | number,
  options: {
    locale?: any;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  } = {},
): Date => {
  let day = dayjs(date);

  if (options.locale) {
    day = day.locale(options.locale);
  }

  if (options.weekStartsOn !== undefined) {
    day = day.startOf('week').set('day', (options.weekStartsOn + 6) % 7);
  } else {
    day = day.endOf('week');
  }

  return day.toDate();
};

export const getDay = (date: Date | number): number => {
  const day = dayjs(date);
  return day.day();
};

export const startOfWeek = (
  date: Date | number,
  options: {
    locale?: any;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  } = {},
): Date => {
  let day = dayjs(date);

  if (options.locale) {
    day = day.locale(options.locale);
  }

  if (options.weekStartsOn !== undefined) {
    day = day.startOf('week').set('day', options.weekStartsOn);
  } else {
    day = day.startOf('week');
  }

  return day.toDate();
};
