import React, {forwardRef} from 'react';

import {nanoid} from 'nanoid';

import {LocalizedTextType, BlockDefinition, BlockCategory} from '../../../../types';
import {coreScopedKey} from '../../../../utils';
import {Accordion, AccordionProps} from '../../../Accordion';
import {View, ViewProps} from '../../../View';
import {LayoutPropsBuilder} from '../../components';

import {AccordionPropsBuilder} from './PropsBuilder';

export type AccordionElementProps = ViewProps &
  AccordionProps & {
    value?: LocalizedTextType;
    fontSize?: number;
    onHoverIn?: () => void;
  };

const defaultProps: AccordionElementProps = {
  sections: [
    {
      id: nanoid(),
      title: 'Section 1',
      content:
        'Your content goes here. Edit styles and content or remove this text From the side bar  / Your content goes here. Edit styles and content or remove this text From the side bar',
    },
    {
      id: nanoid(),
      title: 'Section 2',
      content:
        'Your content goes here. Edit styles and content or remove this text From the side bar  / Your content goes here. Edit styles and content or remove this text From the side bar',
    },
  ],
  maxWidth: '100%',
};

export const Component = ({sections, ...props}: AccordionElementProps, ref) => {
  return (
    <View {...props} ref={ref}>
      <Accordion defaultActiveSections={[0, 1]} variant="outlined" sections={sections} />
    </View>
  );
};

export const AccordionComponent = forwardRef(Component);

export const AccordionElement: BlockDefinition<AccordionElementProps> = {
  name: coreScopedKey('blocks.AccordionElement'),
  version: 1,
  id: 'AccordionElement',
  props: defaultProps,
  category: BlockCategory.NODE,
  Component: AccordionComponent,
  Icon: 'SquareGantt',
  propsBuilder: [AccordionPropsBuilder, LayoutPropsBuilder],
};
