export const config = {
  daySlotDuration: '00:15:00',
  daySlotLabelInterval: '01:00:00',
  monthSlotDuration: '00:15:00',
  monthSlotLabelInterval: '01:00:00',
  slotLabelFormat: {
    hour: 'numeric',
    minute: '2-digit',
    hour12: false,
  },
  eventBackgroundColor: 'transparent',
  eventBorderColor: 'transparent',
};
