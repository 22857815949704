import i18n from 'i18next';

import coreLocalization from '../../localization';
import {coreScopedKey} from '../scopedKeys';

export function loadCoreLocalizations() {
  Object.keys(coreLocalization).forEach((langKey) => {
    const lang = (coreLocalization as any)[langKey];
    i18n.addResourceBundle(langKey, 'translation', {[coreScopedKey()]: lang}, true, true);
  });
}
