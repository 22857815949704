import {coreScopedKey} from '../scopedKeys';

export const getRequestErrorMessage = async (e: any): Promise<string> => {
  try {
    if (e?.response?.json) {
      const data = await e.response.json();

      if (data.message) {
        return data.message;
      }

      if (data.error?.message) {
        return data.error.message;
      }

      if (data.errors?.[0]?.message) {
        return data.errors[0].message;
      }
    }
  } catch (e) {}

  return coreScopedKey('generalErrorMsg');
};
