import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../types';
import {coreScopedKey} from '../../../../utils';
import {View, ViewProps} from '../../../View';
import {WebView, WebViewProps} from '../../../WebView';
import {LayoutPropsBuilder} from '../../components';

import {WebViewPropsBuilder} from './PropsBuilder';

export type WebViewElementType = ViewProps &
  WebViewProps & {
    uri: string;
  };

const defaultProps: WebViewElementType = {
  style: {
    maxWidth: '100%',
    width: '100%',
    height: '100%',
    overflow: 'scroll',
  },
  uri: 'https://openai.com/index/chatgpt/',
  overflow: 'scroll',
  height: 500,
};

export const Component = ({uri, style, ...props}: WebViewElementType, ref) => {
  return (
    <View {...props} ref={ref}>
      <WebView source={{uri}} style={[style]} />
    </View>
  );
};

export const WebViewComponent = forwardRef(Component);

export const WebViewElement: BlockDefinition<WebViewElementType> = {
  name: coreScopedKey('blocks.WebViewElement'),
  version: 1,
  id: 'WebViewElement',
  props: defaultProps,
  category: BlockCategory.NODE,
  Component: WebViewComponent,
  Icon: 'Globe',
  propsBuilder: [WebViewPropsBuilder, LayoutPropsBuilder],
};
