import createLucideIcon from '../createLucideIcon';

const BookDashed = createLucideIcon('BookDashed', [
  ['path', { d: 'M20 22h-2', key: '1rpnb6' }],
  ['path', { d: 'M20 15v2h-2', key: 'fph276' }],
  ['path', { d: 'M4 19.5V15', key: '6gr39e' }],
  ['path', { d: 'M20 8v3', key: 'deu0bs' }],
  ['path', { d: 'M18 2h2v2', key: '180o53' }],
  ['path', { d: 'M4 11V9', key: 'v3xsx8' }],
  ['path', { d: 'M12 2h2', key: 'cvn524' }],
  ['path', { d: 'M12 22h2', key: 'kn7ki6' }],
  ['path', { d: 'M12 17h2', key: '13u4lk' }],
  ['path', { d: 'M8 22H6.5a2.5 2.5 0 0 1 0-5H8', key: 'fiseg2' }],
  ['path', { d: 'M4 5v-.5A2.5 2.5 0 0 1 6.5 2H8', key: 'wywhs9' }],
]);

export default BookDashed;
