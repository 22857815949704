import createLucideIcon from '../createLucideIcon';

const BookUser = createLucideIcon('BookUser', [
  [
    'path',
    {
      d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20',
      key: 't4utmx',
    },
  ],
  ['circle', { cx: '12', cy: '8', r: '2', key: '1822b1' }],
  ['path', { d: 'M15 13a3 3 0 1 0-6 0', key: '10j68g' }],
]);

export default BookUser;
