import React from 'react';

import {BidirectionalIcon} from '../../BidirectionalIcon';
import {Pressable} from '../../Pressable';

interface RowSelectorProps {
  onToggle?: () => void;
  extended?: boolean;
}
export const RowExtend = ({onToggle, extended}: RowSelectorProps) => {
  return (
    <Pressable
      display="flex"
      flex={0}
      onPress={onToggle}
      rotate={extended ? '90deg' : '0deg'}
      width="$4"
      alignItems="center"
      justifyContent="center">
      <BidirectionalIcon size={17} icon="ChevronRight" accent={extended} />
    </Pressable>
  );
};
