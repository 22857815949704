import React, {forwardRef} from 'react';

import {TextArea as BaseTextArea, TextAreaProps as BaseTextAreaProps} from 'tamagui';

import {useAppDirection} from '../../utils/useAppDirection';
import {InputProps} from '../Input';
import {Typography} from '../Typography';
import {View} from '../View';

export type TextAreaProps = InputProps & {
  textAreaProps?: BaseTextAreaProps;
};

export const _TextArea = (
  {
    value,
    inline,
    error,
    disabled,
    subtext,
    editable,
    placeholder,
    onChange,
    onChangeText,
    textAreaProps,
    ...props
  }: TextAreaProps,
  ref,
) => {
  const {isRTL} = useAppDirection();

  const borderColor = inline ? '$transparent' : error ? '$red-600' : '$border';
  const focusBorderColor = inline ? '$transparent' : disabled ? undefined : '$accent';
  const hoverBorderColor = inline ? '$transparent' : disabled ? undefined : '$borderHover';
  const disabledBackgroundColor = disabled ? '$neutral-200' : '$transparent';
  const inputTextAlign = (isRTL && 'right') || undefined;

  return (
    <View
      theme="translucent"
      flexDirection="column"
      width="100%"
      {...props}
      backgroundColor="$background"
      borderRadius="$true">
      <BaseTextArea
        placeholder={placeholder}
        value={value as string}
        backgroundColor={disabledBackgroundColor}
        textAlign={inputTextAlign}
        onChangeText={onChangeText}
        onChange={onChange}
        borderColor={borderColor}
        editable={editable && !disabled}
        placeholderTextColor={disabled ? 'neutral-600' : '$placeholder'}
        focusStyle={{
          borderWidth: 2,
          borderColor: focusBorderColor,
        }}
        lineHeight="$true"
        hoverStyle={{borderColor: hoverBorderColor}}
        focusVisibleStyle={{outlineWidth: 0}}
        ref={ref}
        flex={1}
        {...textAreaProps}
      />
      {subtext && <Typography color={error ? '$red-600' : ''}>{subtext}</Typography>}
    </View>
  );
};

export const TextArea = forwardRef(_TextArea);
