import {useState} from 'react';

import {OnSelect, OnSelectHandler} from '../../model';

export function useRowSelector<T>(onSelect?: OnSelect<T>) {
  const [selectAll, setSelectAll] = useState(false);
  const [selectToggledItems, setSelectToggledItems] = useState(new Set<T | any>());

  const onChange: OnSelectHandler<T> = (selectToggledItems, selectAll) => {
    if (!onSelect) return;

    if (typeof onSelect === 'function') {
      onSelect(selectToggledItems, selectAll);
    } else {
      onSelect.handler(selectToggledItems, selectAll);
    }
  };

  const getItemValue = (item: T) => {
    if (!onSelect) return item;

    if (typeof onSelect === 'function') {
      return item;
    } else {
      return item[onSelect.key];
    }
  };

  const onToggleSelectAll = () => {
    const _selectAll = !selectAll;
    const _selectToggledItems = new Set<T>();
    setSelectAll(_selectAll);
    setSelectToggledItems(_selectToggledItems);
    onChange(_selectToggledItems, _selectAll);
  };

  const onSelectItem = (item: T) => {
    if (selectToggledItems.has(getItemValue(item))) {
      selectToggledItems.delete(getItemValue(item));
    } else {
      selectToggledItems.add(getItemValue(item));
    }

    setSelectToggledItems(new Set(selectToggledItems));

    onChange(selectToggledItems, selectAll);
  };

  const isItemSelected = (item: T) => (selectToggledItems.has(getItemValue(item)) ? !selectAll : selectAll);

  return {selectAll, onToggleSelectAll, onSelectItem, isItemSelected};
}
