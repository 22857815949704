import {config} from '@tamagui/config';
import {createFont} from 'tamagui';

import {FONT_TOKENS} from './constant';

const face = {
  normal: {normal: 'NewSansArabic-Regular'},
  bold: {normal: 'NewSansArabic-Bold'},
  300: {normal: 'NewSansArabic-Regular'},
  400: {normal: 'NewSansArabic-Regular'},
  500: {normal: 'NewSansArabic-Medium'},
  600: {normal: 'NewSansArabic-Bold'}, // TODO: change to NewSansArabic-SemiBold
  700: {normal: 'NewSansArabic-Bold'},
  800: {normal: 'NewSansArabic-Bold'},
};

export const fonts = {
  ...config.fonts,
  body: createFont({
    ...config.fonts.body,
    size: FONT_TOKENS,
    family: 'NewSansArabic',
    face,
  }),
  heading: createFont({
    ...config.fonts.heading,
    family: 'NewSansArabic',
    face,
  }),
};
