import React from 'react';

import {i18n} from 'i18next';
import {useTranslation} from 'react-i18next';

import {LocalizedTextType} from '../../types';

interface Props {
  value?: LocalizedTextType;
  i18n?: i18n;
  t?: (key: string | string[]) => string;
}

export const getLocalizedText = ({value, t, i18n}: Props): string => {
  let localizedValue;

  if (value === undefined || value === null) {
    return '';
  }

  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    localizedValue = t && t(value);
  } else {
    const language = i18n?.language || 'en';
    const fallbackLng = (i18n?.options.fallbackLng as string) || 'en';

    localizedValue = value[language] || value[language?.split('-')[0]] || value[fallbackLng];
  }

  return localizedValue || '';
};

export const LocalizedText = ({value}: Props) => {
  const {t, i18n} = useTranslation();
  return <>{getLocalizedText({value, t, i18n})}</>;
};
