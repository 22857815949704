import React, {useRef} from 'react';

/* eslint-disable import/order */
import FullCalendar from '@fullcalendar/react';
import daygridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import arLocale from '@fullcalendar/core/locales/ar';
import enLocale from '@fullcalendar/core/locales/en-au';

/* eslint-enable import/order */

import './fullcalender-override.css';

import {useTranslation} from 'react-i18next';
import {YStack} from 'tamagui';

import {useCalendar} from '../../context/CalendarContext';
import {CalendarViews} from '../../types';
import {useCalendarController} from '../../utils/useCalendarController';
import {CalendarHeader} from '../CalendarHeader';

import {EventHolder} from './components/EventHolder';
import {config} from './config';

export const DesktopCalendar = ({eventContent, calendarHeader, calendarRef, noTitle, withPrint}) => {
  const fullCalendarRef = useRef<FullCalendar | null>(null);

  const {i18n} = useTranslation();

  const {view} = useCalendarController();
  const {events, weekStartDay} = useCalendar();

  return (
    <YStack backgroundColor="$background" height="100%">
      {calendarHeader !== undefined ? (
        calendarHeader
      ) : (
        <CalendarHeader calendarRef={fullCalendarRef} noTitle={noTitle} withPrint={withPrint} />
      )}
      <FullCalendar
        locales={[enLocale, arLocale]}
        locale={i18n.language}
        ref={(element) => {
          fullCalendarRef.current = element;
          if (calendarRef) calendarRef.current = element;
        }}
        headerToolbar={false}
        plugins={[daygridPlugin, timeGridPlugin]}
        initialView={CalendarViews.month}
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          hour12: false,
        }}
        expandRows
        eventInteractive={false}
        views={{
          timeGridDay: {
            slotDuration: config.daySlotDuration,
            slotLabelInterval: config.daySlotLabelInterval,
          },
          timeGridWeek: {
            slotDuration: config.monthSlotDuration,
            slotLabelInterval: config.monthSlotLabelInterval,
          },
        }}
        events={events}
        nowIndicator
        eventBackgroundColor={config.eventBackgroundColor}
        eventOverlap={false}
        slotEventOverlap={false}
        firstDay={weekStartDay}
        eventContent={(e) => eventContent?.(e, view) || <EventHolder eventContent={e} />}
        height={1200}
      />
    </YStack>
  );
};
