import {ViewProps} from '../components';

import {IconType} from './IconType';
import {LocalizedTextType} from './LocalizedTextType';
import {Block, BlockDefinition} from './PageDesign';

export enum AppPageType {
  SERVICE,
  CONTENT,
}

export type ContentElementsResolver = Record<string, BlockDefinition>;

export interface AppPageCommon {
  id: string;
  type?: AppPageType;
}

export type AppServicePage = AppPageCommon & {
  appletId: string;
  serviceId: string;
};
export type AppContentPage = AppPageCommon & {
  version: number;
  specVersion: number;
  title: LocalizedTextType;
  children: Block[];
  props: ViewProps;
  definitionId: string;
  definitionVersion: number;
  icon?: IconType;
  public?: boolean;
};

export type AppPagesGroup<T = any> = {
  id: string;
  title?: LocalizedTextType;
  pages: T[];
};

export type AppPage = AppServicePage | AppContentPage;

export class LauncherOptions {
  pageTop?: AppContentPage;
  pageBottom?: AppContentPage;
}

export class AppStructure {
  launcher = new LauncherOptions();
  pages: AppPage[] = [];
  auxiliaryPages: AppPagesGroup[] = [];
}

// Type Guards
export function isAppServicePage(page: AppPageCommon): page is AppServicePage {
  return page.type === AppPageType.SERVICE;
}

export function isAppContentPage(page: AppPageCommon): page is AppContentPage {
  return page.type === AppPageType.CONTENT;
}
