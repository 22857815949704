import React, {forwardRef} from 'react';

import {ResizeMode} from 'expo-av';

import {BlockDefinition, BlockCategory} from '../../../../types';
import {coreScopedKey} from '../../../../utils';
import {Video, VideoProps} from '../../../Video';
import {View, ViewProps} from '../../../View';
import {LayoutPropsBuilder} from '../../components';

import {VideoPropsBuilder} from './PropsBuilder';

export type VideoElementProps = Omit<ViewProps, 'style'> & {
  url: string;
  resizeMode?: ResizeMode;
  style: VideoProps['style'];
  borderColor?: string;
  borderWidth?: number;
};

const defaultProps: VideoElementProps = {
  url: 'http://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4',
  maxWidth: '100%',
  height: 250,
  width: 600,
  style: {width: '100%', height: '100%'},
  resizeMode: ResizeMode.STRETCH,
};

export const Component = ({url, borderColor, borderWidth, resizeMode, style, ...props}: VideoElementProps, ref) => {
  return (
    <View {...props} borderColor={borderColor} borderWidth={borderWidth} ref={ref}>
      <Video url={url} style={[style, {borderColor, borderWidth}]} resizeMode={resizeMode} />
    </View>
  );
};

export const VideoComponent = forwardRef(Component);

export const VideoElement: BlockDefinition<VideoElementProps> = {
  name: coreScopedKey('blocks.VideoElement'),
  version: 1,
  id: 'VideoElement',
  props: defaultProps,
  category: BlockCategory.NODE,
  Component: VideoComponent,
  Icon: 'Clapperboard',
  propsBuilder: [VideoPropsBuilder, LayoutPropsBuilder],
};
