export const en = {
  login: 'Login',
  action: {
    save: 'Save',
    reset: 'Reset',
    close: 'Close',
    cancel: 'Cancel',
    next: 'Next',
    previous: 'Previous',
    seeMore: 'See More',
    accept: 'Accept',
    ok: 'Ok',
  },
  actions: 'Actions',
  settings: {
    title: 'Settings',
    general: {
      _name: 'General',
      connectors: 'Connectors',
      connector: {
        enable: 'Enable',
        url: 'URL',
        username: 'Username',
        password: 'Password',
        tenant: 'Tenant',
        token: 'Token',
      },
    },
  },
  calendar: {
    title: 'Calendar',
    titleKey: 'title',
    time: 'Time',
    noEvents: 'No events',
    from: 'From',
    to: 'to',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    filters: 'Filters',
    today: 'Today',
    m: 'M',
    h: 'H',
    months: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
    },
    days: {
      Sunday: 'Sunday',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
    },
  },
  validation: {
    required: 'Required',
  },
  success: 'Success',
  error: 'Error',
  generalErrorMsg: 'Sorry, something went wrong.',
  successMsg: 'Done, Successfully',
  connectorMustBeDefined: 'Please configure the connector first',
  connectorIsDisabled: 'Please note that this connector is disabled',
  sortBy: 'Sort By',
  ago: '{{value}} ago',
  pagination: {
    rowsPerPage: 'Rows per page:',
    page: 'Page',
    of: 'Of',
    perPage: 'Per Page',
  },
  hidjriMonths: {
    Muharram: 'Muharram',
    Safar: 'Safar',
    RabiAlAwwal: "Rabi' al-Awwal",
    RabiAlThani: "Rabi' al-Thani",
    JumadaAlUla: 'Jumada al-Ula',
    JumadaAlThaniyah: 'Jumada al-Thaniyah',
    Rajab: 'Rajab',
    Shaaban: "Sha'ban",
    Ramadan: 'Ramadan',
    Shawwal: 'Shawwal',
    DhuAlQidah: "Dhu al-Qi'dah",
    DhuAlHijjah: 'Dhu al-Hijjah',
  },
  blocks: {
    common: 'Common',
    ContainerElement: 'Container',
    TextElement: 'Text',
    LinkElement: 'Link',
    ButtonElement: 'Button',
    ImageElement: 'Image',
    VideoElement: 'Video',
    WebViewElement: 'Web View',
    RichTextElement: 'Rich Text',
    YoutubePlayerElement: 'Youtube',
    AccordionElement: 'Accordion',
    ColumnElement: 'Column',
    RowElement: 'Row',
    PageElement: 'Page',
  },
  NODE: 'Basic',
  LAYOUT: 'Layout',
  GENERAL: 'General',
  showAllColumns: 'Show All Columns',
  columns: 'Columns',
  showOnly: 'Show only',
  showDetails: 'Show details',
  testConnection: 'Test Connection',
  connectionFailed: 'Connection Failed',
  connectionSuccess: 'Connection Succeeded',
  languageChangeMsg: 'Some changes will only take effect after app restart',
  noData: 'No Data Found',
  fileUploader: {
    dragAndDropOrClickHere: 'Drag and drop files or click here',
    dropItHere: 'Drop it here',
    uploading: 'Uploading',
    fileUploadedSuccessfully: 'File uploaded successfully',
    MB: 'MB',
  },
};
