import React from 'react';

import {YStack as BaseYStack, YStackProps} from 'tamagui';

import {useDirectionAwareProps} from '../../utils/useDirectionAwareProps';

export const YStack = (props: YStackProps) => {
  const _props = useDirectionAwareProps(props);

  return <BaseYStack {..._props} />;
};
