import {useEffect, useState} from 'react';

import {Option} from '../../../types';
import {MultiSelectProps} from '../types';

export const useSelectValues = (values: MultiSelectProps['values'], onChange: MultiSelectProps['onChange']) => {
  const [selectedValues, setSelectedValues] = useState<Option[]>(values ? [...values] : []);

  const isSelected = (item: Option) => selectedValues?.some((i) => i.value === item.value);

  useEffect(() => {
    !values?.length && setSelectedValues([]);
  }, [values]);

  const onChangeSelection = (value: Option) => {
    const checked = !isSelected(value);
    const newSelectedValues = checked
      ? [...selectedValues, value]
      : selectedValues.filter((item) => item.value !== value.value);
    setSelectedValues(newSelectedValues);
    onChange?.(newSelectedValues, {value, checked});
  };

  const onReset = () => {
    setSelectedValues([]);
    onChange?.([]);
  };

  return {selectedValues, isSelected, onChangeSelection, onReset};
};
