export const FONT_TOKENS = {
  2: 2,
  3: 3,
  4: 4,
  6: 6,
  8: 8,
  10: 10,
  12: 12,
  14: 14,
  16: 16,
  18: 18,
  20: 20,
  24: 24,
  30: 30,
  36: 36,
  40: 40,
  44: 44,
  48: 48,
  56: 56,
  60: 60,
  72: 72,
  80: 80,
  96: 96,
  132: 132,
  true: 16,
};
