import React, {ReactNode} from 'react';

import {ImageSourcePropType} from 'react-native';
import {Avatar as BaseAvatar, SquareProps} from 'tamagui';

import {IconView} from '../IconView';

export type AvatarProps = Omit<SquareProps, 'size' | 'circular'> & {
  size?: AvatarSizes;
  source?: ImageSourcePropType;
  fallback?: ReactNode;
};

const FALLBACK_SCALE = 0.57;

/**
 * Renders an avatar component.
 *
 * @component
 * @example
 * ```tsx
 * <Avatar
 *   size="sm"
 *   source={avatarImage}
 * />
 * ```

 */
export const Avatar: React.FC<AvatarProps> = ({size = 'sm', source, fallback, ...props}) => {
  const selectedSize = avatarSizes[size];
  const fallbackSize = selectedSize.size * FALLBACK_SCALE;

  return (
    <BaseAvatar
      circular={true}
      borderWidth={selectedSize.borderWidth}
      shadowColor="$neutral-500"
      shadowOffset={{width: 0, height: 0}}
      shadowOpacity={1}
      shadowRadius={2}
      borderColor="$neutral-50"
      size={selectedSize.size}
      {...props}>
      {source ? <BaseAvatar.Image source={source} /> : null}
      <BaseAvatar.Fallback backgroundColor="$neutral-200" alignItems="center" justifyContent="center">
        {fallback || <IconView icon="User" strokeWidth={2} color="$neutral-600" size={fallbackSize} />}
      </BaseAvatar.Fallback>
    </BaseAvatar>
  );
};

export const avatarSizes: Record<
  AvatarSizes,
  {
    size: number;
    borderWidth: number;
  }
> = {
  xs: {
    size: 22,
    borderWidth: 1,
  },
  sm: {
    size: 32,
    borderWidth: 1,
  },
  md: {
    size: 40,
    borderWidth: 2,
  },
  lg: {
    size: 48,
    borderWidth: 3,
  },

  xl: {
    size: 56,
    borderWidth: 3,
  },

  '2xl': {
    size: 64,
    borderWidth: 4,
  },

  '3xl': {
    size: 96,
    borderWidth: 4,
  },
  '4xl': {
    size: 140,
    borderWidth: 4,
  },
  '5xl': {
    size: 160,
    borderWidth: 4,
  },
} as const;

export type AvatarSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
