/**
 * Simulates the progress of a file upload.
 *
 * @param fileSize - The size of the file in bytes.
 * @param callback - A callback function that will be called with the progress value.
 * @param delay - The delay in milliseconds between each progress update. Default is 450ms.
 * @returns A function that can be called to stop the progress simulation.
 */
export function fakeUploadProgress(fileSize: number, callback: (progress: number) => void, delay = 450) {
  let progress = 0;
  const increment = 10000000 / fileSize;

  const intervalId = setInterval(() => {
    if (progress + increment <= 98) {
      progress += increment;
      callback(progress);
    } else {
      clearInterval(intervalId);
    }
  }, delay);

  return () => clearInterval(intervalId);
}
