import React, {useMemo} from 'react';

import {Calendar, CalendarEvent, CalendarProps} from '../../components/Calendar';
import {useIsAuthenticated} from '../../context/AuthContext';
import {useUserSettings} from '../../data';
import {Color} from '../../utils/color';
import {dayjs} from '../../utils/extendedDayjs';
import {isDefined} from '../../utils/isDefined';
import {stringToColor} from '../../utils/stringToColor';

import {calendarService} from './services/CalendarService';

export {calendarService, ScopedCalendarService} from './services/CalendarService';

export const AppCalendar = () => {
  const isAuth = useIsAuthenticated();

  const {userSettings} = useUserSettings();

  const calenderEvents = calendarService.getEvents();

  const events: CalendarEvent[] = useMemo(() => {
    if (userSettings) {
      return calenderEvents.filter((e) => {
        const showInCalendarSettings = userSettings.calendar?.showInCalendar;
        let show = true;

        if (isDefined(showInCalendarSettings) && e.appletId) {
          show = showInCalendarSettings?.includes(e.appletId) === true;
        }

        if (show) {
          if (!e.colors) {
            const main = e.color || stringToColor(e.type, 80, 40);
            e.colors = {main, light: Color.lighten(main, 50), dark: Color.darken(main, 0.8)};
          }

          if (e.startTime) {
            e.startTime = dayjs(e.startTime).format('HH:mm');
          }

          if (e.endTime) {
            e.endTime = dayjs(e.endTime).format('HH:mm');
          }
        }

        return show;
      });
    } else {
      return [];
    }
  }, [calenderEvents, userSettings, isAuth]);

  const calendarContext: CalendarProps = useMemo(() => {
    return {
      events,
      weekStartDay: 0,
    };
  }, [events]);

  return <Calendar {...calendarContext} />;
};
