import React from 'react';

import {StackProps} from 'tamagui';

import {View} from '../../View';

export const TabsRovingIndicator = (props: StackProps) => {
  return (
    <View
      borderRadius={15}
      height="100%"
      width="100%"
      position="absolute"
      zIndex={-1}
      backgroundColor="$accent"
      opacity={1}
      scale={1}
      animation="bouncy"
      enterStyle={{
        opacity: 0,
        scale: 0.8,
      }}
      exitStyle={{
        opacity: 0,
        scale: 0.8,
      }}
      {...props}
    />
  );
};
