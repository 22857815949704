import React from 'react';

import {TouchableOpacity, ViewStyle} from 'react-native';
import DraggableFlatList from 'react-native-draggable-flatlist';
import {View, XStack} from 'tamagui';

import {Option} from '../../types';
import {IconView} from '../IconView';
import {LocalizedText} from '../LocalizedText';
import {Typography} from '../Typography';
import {ViewProps} from '../View';

type Props = {
  list?: Option[];
  onChange?: (values: any) => any;
  style?: ViewStyle;
  labelProps?: ViewProps;
};

export function OrderList({list = [], onChange, labelProps, style}: Props) {
  return (
    <DraggableFlatList
      containerStyle={style as any}
      data={list}
      onDragEnd={({data}) => onChange?.(data.map(({value}) => value))}
      keyExtractor={(item) => item?.value}
      renderItem={({item, drag, isActive}) => {
        return (
          <XStack alignItems="center" backgroundColor={isActive ? '#88888822' : undefined}>
            <TouchableOpacity onPressIn={drag} disabled={isActive}>
              <IconView icon="GripHorizontal" size={30} accent />
            </TouchableOpacity>
            <View {...labelProps}>
              <Typography>
                <LocalizedText value={item.label} />
              </Typography>
            </View>
          </XStack>
        );
      }}
    />
  );
}
