import React, {useState} from 'react';

import {ViewProps} from '../View';
import {XStack} from '../XStack';

import {CarouselButton} from './components/CarouselButton';

export type CarouselProps = {
  size: number;
  onPageChange?: (index: number) => void;
  currentPage?: number;
  children: React.ReactNode;
} & ViewProps;

export const Carousel = ({currentPage, size, onPageChange, children, ...props}: CarouselProps) => {
  const [selectedPage, setSelectedPage] = useState(currentPage || 1);

  const onPageChanged = (page: number) => {
    onPageChange?.(page);
    setSelectedPage(page);
  };

  return (
    <XStack justifyContent="space-between" alignItems="center" marginTop="$4" width="100%" {...props}>
      <CarouselButton
        icon="ChevronLeft"
        onPress={() => onPageChanged(selectedPage - 1)}
        isHidden={selectedPage === 0}
      />
      {typeof children === 'function' ? children(selectedPage) : children}
      <CarouselButton
        icon="ChevronRight"
        onPress={() => onPageChanged(selectedPage + 1)}
        isHidden={selectedPage >= size - 1}
      />
    </XStack>
  );
};
