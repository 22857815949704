import {addDays, eachDay, endOfMonth, endOfWeek, format, getDay, startOfMonth, startOfWeek} from '../utils/date';

type FirstDayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export interface GetWeekdayLabelsProps {
  firstDayOfWeek?: FirstDayOfWeek;
  weekdayLabelFormat?(date: Date): string;
}

export function getWeekdayLabels({
  firstDayOfWeek = 1,
  weekdayLabelFormat = (date: Date) => format(date, 'iiiiii'),
}: GetWeekdayLabelsProps = {}) {
  const now = new Date();
  const arr = eachDay({
    start: addDays(startOfWeek(now), firstDayOfWeek),
    end: addDays(endOfWeek(now), firstDayOfWeek),
  });
  return arr.reduce((array, date) => {
    array.push(weekdayLabelFormat(date) as never);
    return array;
  }, []);
}

export interface GetDaysProps {
  year: number;
  month: number;
  firstDayOfWeek?: FirstDayOfWeek;
  dayLabelFormat?(date: Date): string;
}

export type CalendarDay = number | {dayLabel: string; date: Date};
export function getDays({
  year,
  month,
  firstDayOfWeek = 1,
  dayLabelFormat = (date: Date) => format(date, 'dd'),
}: GetDaysProps): CalendarDay[] {
  const date = new Date(year, month);

  const monthStart = startOfMonth(date);
  const monthStartDay = getDay(monthStart);
  const monthEnd = endOfMonth(date);

  const prevMonthDays = Array.from(
    Array(
      monthStartDay >= firstDayOfWeek ? monthStartDay - firstDayOfWeek : 6 - firstDayOfWeek + monthStartDay + 1,
    ).keys(),
  ).fill(0);
  const days = eachDay({start: monthStart, end: monthEnd}).map((date) => ({
    date,
    dayLabel: dayLabelFormat(date),
  }));

  return [...prevMonthDays, ...days];
}
