import React, {useMemo} from 'react';

import {ScrollView, View, StyleSheet, StyleProp, ViewStyle} from 'react-native';
import {useTheme} from 'tamagui';

import {useResponsiveValue} from '../../utils';

interface PageContainerProps {
  children?: any;
  noPadding?: boolean;
  scrollable?: boolean;
  styles?: StyleProp<ViewStyle>;
}

export function PageContainer({children, noPadding, scrollable = true, styles: extraStyles}: PageContainerProps): any {
  const theme = useTheme();

  const screenPadding = useResponsiveValue({$sm: 16, $md: 32, $lg: 32});

  const containerStyles = useMemo(() => styles({theme, noPadding, screenPadding}), [theme, noPadding, screenPadding]);

  if (scrollable) {
    return <ScrollView contentContainerStyle={[containerStyles.container, extraStyles]}>{children}</ScrollView>;
  }

  return <View style={[containerStyles.container, extraStyles]}>{children}</View>;
}

const styles = (props) =>
  StyleSheet.create({
    container: {
      flexGrow: 1,
      backgroundColor: props.theme.background.val,
      padding: props.noPadding ? 0 : props.screenPadding,
      paddingHorizontal: props.noPadding ? 0 : props.screenPadding,
      paddingBottom: props.noPadding ? 0 : 15,
    },
  });

export function AppScreen({children, scrollable, styles}: any): any {
  return (
    <PageContainer noPadding scrollable={scrollable} styles={styles}>
      {children}
    </PageContainer>
  );
}
