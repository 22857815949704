// todo: add docs
import React, {useState, createContext, useContext} from 'react';

import {NavigationContainer} from '@react-navigation/native';
import {createNavigationContainerRef} from '@react-navigation/native';
import {NavigationState} from '@react-navigation/routers';
import {useTranslation} from 'react-i18next';
import {Platform} from 'react-native';

import {getLocalizedText} from '../components/LocalizedText';

interface NavigationContextValue {
  routesStack: {title: string; routeId: string}[];
  onNavigationChange?: (state: NavigationState | undefined) => void;
}

export const NavigationContext = createContext<NavigationContextValue>({
  routesStack: [],
});

export const NavigationProvider = ({children, onNavigationChange, ref, ...props}) => {
  const [routesStack, setRouteStack] = useState<NavigationContextValue['routesStack']>([]);
  const {t, i18n} = useTranslation();

  const Navigationref = createNavigationContainerRef();

  return (
    <NavigationContext.Provider value={{routesStack}}>
      <NavigationContainer
        ref={ref || Navigationref}
        onStateChange={(state) => {
          const route = Navigationref.getCurrentRoute()?.params as any;
          const routeName = Navigationref.getCurrentRoute()?.name || '';
          const routeTitle = getLocalizedText({value: route?.title, t, i18n});
          const mainScreens = Navigationref?.getRootState()?.routeNames;
          const isTabScreen = route?.isTabScreen;

          const newStack = handleScreensStack(routeTitle, routesStack, mainScreens, isTabScreen, routeName);

          setRouteStack(newStack);
          onNavigationChange(state);
        }}
        {...props}>
        {children}
      </NavigationContainer>
    </NavigationContext.Provider>
  );
};

const handleScreensStack = (
  title: string,
  stack: NavigationContextValue['routesStack'] = [],
  mainScreens: string[],
  isTabScreen: boolean,
  name?: string,
) => {
  if (typeof name !== 'string') return stack;
  const isMainScreen =
    Platform.OS === 'web' ? mainScreens.includes(name) : !name.includes('@applet') && mainScreens.includes(name);
  const isHomeScreen = name === mainScreens[0];

  const homeScreen = {title: mainScreens[0], routeId: mainScreens[0]};
  const screen = {title, routeId: name};

  if (isHomeScreen) return [homeScreen];
  if (isMainScreen || isTabScreen) return [homeScreen, screen];

  return [...stack, screen];
};

export const useRoutesStack = () => {
  const context = useContext(NavigationContext);
  return context.routesStack;
};
