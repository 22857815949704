import React, {forwardRef} from 'react';

import {LocalizedTextType, BlockDefinition, BlockCategory} from '../../../../types';
import {coreScopedKey} from '../../../../utils';
import {LocalizedText} from '../../../LocalizedText';
import {Typography, TypographyProps} from '../../../Typography';
import {LayoutPropsBuilder} from '../../components';

import {TextPropsBuilder} from './PropsBuilder';

export type TextElementProps = TypographyProps & {
  value?: LocalizedTextType;
};

const defaultProps: TextElementProps = {
  lineHeight: 20,
  fontSize: 20,
  fontWeight: '500',
  value: 'Your content goes here ...',
};

export const Component = ({value, ...props}: TextElementProps, ref) => {
  return (
    <Typography {...props} ref={ref}>
      <LocalizedText value={value} />
    </Typography>
  );
};
export const TextComponent = forwardRef(Component);

export const TextElement: BlockDefinition<TextElementProps> = {
  version: 2,
  id: 'TextElement',
  name: coreScopedKey('blocks.TextElement'),
  props: defaultProps,
  category: BlockCategory.NODE,
  Component: TextComponent,
  Icon: 'Type',
  propsBuilder: [TextPropsBuilder, LayoutPropsBuilder],
  migrate: (block) => block,
};
