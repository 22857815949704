import React from 'react';

import {StyleSheet, Platform, SafeAreaView as BaseSafeAreaView, StatusBar as RNStatusBar} from 'react-native';

export function SafeAreaView({children}) {
  return <BaseSafeAreaView style={safeAreaStyles.container}>{children}</BaseSafeAreaView>;
}

export const safeAreaStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  shared: {
    paddingTop: Platform.OS === 'android' ? RNStatusBar.currentHeight : 0,
  },
});
