import createLucideIcon from '../createLucideIcon';

const GitPullRequestCreateArrow = createLucideIcon(
  'GitPullRequestCreateArrow',
  [
    ['circle', { cx: '5', cy: '6', r: '3', key: '1qnov2' }],
    ['path', { d: 'M5 9v12', key: 'ih889a' }],
    ['path', { d: 'm15 9-3-3 3-3', key: '1lwv8l' }],
    ['path', { d: 'M12 6h5a2 2 0 0 1 2 2v3', key: '1rbwk6' }],
    ['path', { d: 'M19 15v6', key: '10aioa' }],
    ['path', { d: 'M22 18h-6', key: '1d5gi5' }],
  ],
);

export default GitPullRequestCreateArrow;
