import { themes } from "@tamagui/themes";
import { animations } from "./animations.mjs";
import { configWithoutAnimations } from "./config.mjs";
import { configWithoutAnimations as configWithoutAnimations2 } from "./config.mjs";
export * from "./media.mjs";
export * from "./createGenericFont.mjs";
export * from "./animations.mjs";
const config = {
  ...configWithoutAnimations,
  // fixes typescript exporting this using internal /types/ path
  themes,
  animations
};
export { config, configWithoutAnimations2 as configWithoutAnimations };