// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P8gTCEfOy5aNVYsvljWt{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.NaFww1W7q0anty563Eli{
  width: calc(100% - 40px);
  flex-shrink: 0;
}

.AOVfiViQ_pgrNYDpGk1x{
  margin-inline-start: 2px;
  flex-shrink: 0;
  width: 38px;
}
`, "",{"version":3,"sources":["webpack://./node_modules/@medad-sep/core/components/MediaQueryInput/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;EACxB,cAAc;AAChB;;AAEA;EACE,wBAAwB;EACxB,cAAc;EACd,WAAW;AACb","sourcesContent":[".inputContainer{\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n\n.input{\n  width: calc(100% - 40px);\n  flex-shrink: 0;\n}\n\n.select{\n  margin-inline-start: 2px;\n  flex-shrink: 0;\n  width: 38px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `P8gTCEfOy5aNVYsvljWt`,
	"input": `NaFww1W7q0anty563Eli`,
	"select": `AOVfiViQ_pgrNYDpGk1x`
};
export default ___CSS_LOADER_EXPORT___;
