import React, {useCallback, useState} from 'react';

import {message} from 'antd';

import {useAPI} from '../../../services';
import {CoreTrans, TestConnectionFunction} from '../../index';

export function useTestConnection(test: TestConnectionFunction) {
  const API = useAPI();
  const [isTestingConnection, setIsTestingConnection] = useState(false);

  const testConnection = useCallback(
    async (values) => {
      setIsTestingConnection(true);

      try {
        if (!(await test?.(values, API))) {
          throw 'request failed';
        }

        message.success(<CoreTrans i18nKey="connectionSuccess" />);
      } catch (e) {
        message.error(<CoreTrans i18nKey="connectionFailed" />);
      } finally {
        setIsTestingConnection(false);
      }
    },
    [test],
  );

  return {testConnection, isTestingConnection};
}
