import React from 'react';

import {Collapse} from 'antd';
const {Panel} = Collapse;

export const ToolbarSection = ({title, children}: any) => {
  return (
    <Collapse bordered={false}>
      <Panel header={title} key="0">
        {children}
      </Panel>
    </Collapse>
  );
};
