import React, {ReactNode, useState} from 'react';

import {ColorTokens} from 'tamagui';

import {IconListType} from '../../lib';
import {Button} from '../Button';
import {IconView} from '../IconView';
import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {View, ViewProps} from '../View';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

type MessageType = {
  color: ColorTokens;
  icon: IconListType;
  backgroundColor: ColorTokens;
};

export const MessageBarType: Record<'info' | 'success' | 'danger' | 'warning', MessageType> = {
  info: {
    color: '$blue-600',
    icon: 'BadgeInfo',
    backgroundColor: '$blue-50',
  },
  success: {
    color: '$green-600',
    icon: 'BadgeCheck',
    backgroundColor: '$green-50',
  },
  danger: {
    color: '$red-600',
    icon: 'AlertOctagon',
    backgroundColor: '$red-50',
  },
  warning: {
    color: '$amber-600',
    icon: 'AlertTriangle',
    backgroundColor: '$amber-50',
  },
} as const;

export type MessageBarProps = ViewProps & {
  title?: string;
  description?: string;
  type?: keyof typeof MessageBarType;
  onAction?: () => void;
  onDismiss?: () => void;
  callToActionLabel?: ReactNode;
  dissmissLabel?: ReactNode;
};

export const MessageBar = ({
  title,
  description,
  type = 'info',
  onAction,
  onDismiss,
  callToActionLabel,
  dissmissLabel,
  ...props
}: MessageBarProps) => {
  const [isDismissed, setIsDismissed] = useState(false);

  const color = MessageBarType[type].color;
  const backgroundColor = MessageBarType[type].backgroundColor;
  const icon = MessageBarType[type].icon;

  const onActionAction = () => {
    onAction?.();
  };

  const onDismissClick = () => {
    setIsDismissed(true);
    onDismiss?.();
  };
  return (
    <View
      display={isDismissed ? 'none' : 'flex'}
      padding="$12"
      borderRadius={5}
      gap="$12"
      backgroundColor={backgroundColor}
      {...props}>
      <XStack alignItems="center" gap="$12">
        <View padding={6} alignItems="center" justifyContent="center" borderRadius={1000} backgroundColor={color}>
          <IconView strokeWidth={1.5} size={16} icon={icon} color="$background" />
        </View>
        <Typography marginHorizontal="$3" color="$neutral-800" fontWeight="600">
          {title}
        </Typography>
      </XStack>
      <YStack gap="$8">
        <Typography color="$neutral-700" variant="body2" fontWeight="400">
          {description}
        </Typography>
        {(onAction || onDismiss) && (
          <XStack gap="$24" padding="$0" alignItems="center">
            {onAction && (
              <Button onPress={onActionAction} type={type} fontWeight="700" variant="empty">
                {callToActionLabel ? callToActionLabel : <CoreTrans i18nKey="action.ok" />}
              </Button>
            )}
            {onDismiss && (
              <Button onPress={onDismissClick} padding={0} type={type} fontWeight="700" variant="empty">
                {dissmissLabel ? dissmissLabel : <CoreTrans i18nKey="action.cancel" />}
              </Button>
            )}
          </XStack>
        )}
      </YStack>
    </View>
  );
};
