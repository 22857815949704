import {ReactNode} from 'react';

export function isClassComponent(Component): Component is ReactNode {
  return !!(typeof Component === 'function' && Component.prototype && Component.prototype.isReactComponent);
}

export function isFunctionComponent(Component): Component is ReactNode {
  return (
    typeof Component === 'function' ||
    (typeof Component === 'object' && Component.$$typeof === Symbol.for('react.element'))
  );
}

export function isReactComponent(component): component is ReactNode {
  return isClassComponent(component) || isFunctionComponent(component);
}
