import {ApiConnector, BaseWidget} from '../../types';
import {Applet, AppletDefinition} from '../../types/Applet';
import {deepClone} from '../deepClone';
import {loadAppletLocalizations} from '../loadAppletLocalizations';

export async function loadApplets(
  applets: AppletDefinition[] = [],
  connectors: ApiConnector[] = [],
  filterDisabled = true,
  applyCustomizations = true,
): Promise<Applet[]> {
  const loadedList: Applet[] = [];

  for (let i = 0; i < applets.length; i++) {
    const load = applets[i].load;
    let applet: Applet = (await load()).default;

    const connector = connectors.find(({id}) => id === applet.id);

    if (applyCustomizations) {
      applet = applyAppletCustomizations(applet, connector);
    }

    loadAppletLocalizations(applet);

    if (!filterDisabled || (connector && connector.enabled)) {
      loadedList.push(applet);
    }
  }

  return loadedList;
}

const applyAppletCustomizations = (applet: Applet, connector: ApiConnector | undefined) => {
  const customizations = connector?.settings.customizations;
  const customizedApplet = deepClone(applet);

  ['services', 'widgets'].forEach((collection) => {
    customizedApplet[collection] = customizedApplet[collection].filter((it: BaseWidget) => {
      const customization = customizations?.[it.id];

      // clear empty customized name
      if (typeof customization?.name === 'object') {
        Object.entries(customization.name).forEach(
          ([key, value]) => !value.trim() && delete customization?.name?.[key],
        );
        !Object.keys(customization.name).length && (customization.name = undefined);
      }

      it.name = customization?.name || it.name;
      it.Icon = customization?.icon || it.Icon;
      return customization?.enabled !== false;
    });
  });

  return customizedApplet;
};
