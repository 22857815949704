import React, {useMemo} from 'react';

import {designSpec} from '../../';
import {useContentElementsResolver} from '../../../context';
import {ViewportSizes, AppContentPage, Block} from '../../../types';
import {usePropsByViewPortSize} from '../../../utils';
import {ErrorBoundary} from '../../ErrorBoundary';
import {PageContainer} from '../../ScreenPage';
import {View, ViewProps} from '../../View';

import {PageElement} from './components/PageElement';

type Props = ViewProps & {
  page: AppContentPage | null;
  elements?: Record<string, any>;
  viewportSize?: ViewportSizes;
};

export const PagePreview = ({
  page,
  viewportSize = ViewportSizes.MOBILE,
  elements,
  ...props
}: Props): JSX.Element | null => {
  const migratedPage = useMemo(() => designSpec.migrate(page), [page]);
  const pageProps = usePropsByViewPortSize(migratedPage?.props);
  const builderContentElements = elements || useContentElementsResolver();

  if (!migratedPage?.children || !Object.keys(builderContentElements).length) return null;

  const renderPageContent = (children: Block[]): JSX.Element[] => {
    return children.map((child) => (
      <PageElement
        key={child.id}
        child={child}
        renderPageContent={renderPageContent}
        builderContentElements={builderContentElements}
      />
    ));
  };

  const content = useMemo(
    () => (
      <ErrorBoundary>
        <View {...props} {...pageProps}>
          {renderPageContent(migratedPage.children)}
        </View>
      </ErrorBoundary>
    ),
    [page, viewportSize],
  );

  if (viewportSize === ViewportSizes.MOBILE) {
    return (
      <PageContainer {...props} {...pageProps}>
        {content}
      </PageContainer>
    );
  }

  return content;
};
