import createLucideIcon from '../createLucideIcon';

const PowerSquare = createLucideIcon('PowerSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M12 7v5', key: 'ma6bk' }],
  [
    'path',
    { d: 'M8 9a5.14 5.14 0 0 0 4 8 4.95 4.95 0 0 0 4-8', key: '15eubv' },
  ],
]);

export default PowerSquare;
