import React, {forwardRef, ReactNode, ComponentProps} from 'react';

import {Group as GroupContainer} from 'tamagui';

type GroupProps = ComponentProps<typeof GroupContainer> & {
  orientation?: 'horizontal' | 'vertical';
  children: ReactNode;
};

const _Group = ({orientation = 'horizontal', children, ...rest}: GroupProps, ref) => {
  return (
    <GroupContainer ref={ref} orientation={orientation} {...rest}>
      {children}
    </GroupContainer>
  );
};

export const Group = forwardRef(_Group);
