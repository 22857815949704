import {useMemo} from 'react';

import {useTranslation} from 'react-i18next';

export function useAppDirection() {
  const {i18n} = useTranslation();

  return useMemo(() => {
    const direction = i18n.dir(i18n.resolvedLanguage);
    const isLTR = direction === 'ltr';
    const isRTL = !isLTR;

    return {direction, isLTR, isRTL};
  }, [i18n.resolvedLanguage]);
}
