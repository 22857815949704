import {useCallback, useEffect, useState} from 'react';

import {authCacheKey} from '../../../config';
import {AuthResponse} from '../../../context/AuthContext';
import {cacheService} from '../../../services/cache';

export function useCachedAuth() {
  const [isLoadedCache, setIsLoadedCache] = useState(false);
  const [authResponse, setAuthResponse] = useState<AuthResponse | undefined>();

  const onAuthChange = useCallback((res?: AuthResponse) => {
    setAuthResponse(res);

    if (res) {
      cacheService.set(authCacheKey, res);
    } else {
      cacheService.remove(authCacheKey);
    }
  }, []);

  useEffect(() => {
    cacheService.get(authCacheKey).then((value) => {
      value && setAuthResponse(value as any);
      setIsLoadedCache(true);
    });
  }, []);

  return {isLoadedCache, authResponse, onAuthChange};
}
