import createLucideIcon from '../createLucideIcon';

const BookImage = createLucideIcon('BookImage', [
  [
    'path',
    {
      d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20',
      key: 't4utmx',
    },
  ],
  ['circle', { cx: '10', cy: '8', r: '2', key: '2qkj4p' }],
  ['path', { d: 'm20 13.7-2.1-2.1c-.8-.8-2-.8-2.8 0L9.7 17', key: '160say' }],
]);

export default BookImage;
