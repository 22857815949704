import React from 'react';

import {IconType} from '../../types';
import {Button, ButtonProps} from '../Button';
import {IconView} from '../IconView';
import {Typography} from '../Typography';
import {YStack} from '../YStack';

export type ResultProps = {
  icon?: IconType;
  title: string | React.ReactNode;
  description?: string;
  onAction?: () => void;
  actionLabel?: string;
  isActionLoading?: boolean;
  actionButtonProps?: ButtonProps;
};

/**
 * Displays the result status with an icon , title, description, and action button.
 */

export const Result: React.FC<ResultProps> = ({
  icon = 'AlertTriangle',
  title,
  description,
  onAction,
  actionLabel,
  isActionLoading = false,
  actionButtonProps,
}) => {
  return (
    <YStack alignItems="center" justifyContent="center" gap="$24" maxWidth="600px">
      <IconView icon={icon} size={100} fontWeight={600} />
      {!!title && (
        <Typography variant="body1" textAlign="center">
          {title}
        </Typography>
      )}
      {!!description && (
        <Typography variant="caption" textAlign="center" color="$neutral-600">
          {description}
        </Typography>
      )}
      {!!actionLabel && (
        <Button variant="outlined" type="danger" loading={isActionLoading} onPress={onAction} {...actionButtonProps}>
          {actionLabel}
        </Button>
      )}
    </YStack>
  );
};
