import {FormPageData} from '../types';

import {formIoStyle} from './formIoStyle';

export const formIoPage = ({
  appConfig,
  data,
  direction,
  style = '',
  authToken = '',
  refresher = Date.now(),
}: FormPageData) => {
  const bootstrapStyleUrl =
    direction === 'rtl'
      ? 'https://cdn.rtlcss.com/bootstrap/v4.5.3/css/bootstrap.min.css'
      : 'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css';

  return `
    <head>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
        <link rel="stylesheet" href="${bootstrapStyleUrl}">
        
        <script src="https://cdn.jsdelivr.net/npm/formiojs@4.16.0/dist/formio.full.min.js"></script>
        <style>${formIoStyle(appConfig)} ${style}</style>
    </head>
    
    <body>
    
    <div id="formio" dir="${direction}" data-updated="${refresher}"></div>
    
    <script type="text/javascript">
        window.authToken = \`${authToken}\`;
        window.onload = async () => {
            const parent=(window.top || window.ReactNativeWebView);
            
            try {
                Formio.registerPlugin({
                    priority: 0,
                    preRequest: function(requestArgs) {
                    requestArgs.opts.header.map['Authorization'] = "Bearer " + window.authToken;
                    return Promise.resolve(requestArgs);
                    }
                })
                
            const form = await Formio.createForm(document.getElementById("formio"),
                    ${JSON.stringify(data.form)},
                    {
                        readOnly: ${data.isReadOnly} ? "true" : null,
                        noAlerts: "true",
                        submitMessage: "",
                        disableAlerts: "true",
                    },
                )
                
            form.nosubmit = true;
            form.submission = ${JSON.stringify(data.formSubmission)};
    
                form.on('change', function(event) {
                    delete event.flags;
                    delete event.instance;
                    
                    if (event.changed) {
                        delete event.changed.instance;
                        delete event.changed.flags;
                    }

                parent.postMessage(JSON.stringify({eventName: 'change', payload: event}));
            });
    
                form.on("submit", (submission) => {
                    parent.postMessage(JSON.stringify({eventName: "submit", payload: submission}));
                });
            }catch (e) {}
        };
    </script>
    </body>
`;
};
