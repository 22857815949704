import {useMemo} from 'react';

import {useAppConnectors, useAppletContext} from '../../context';

export const useAppletConnector = () => {
  const appletId = useAppletContext();
  const connectors = useAppConnectors();

  return useMemo(() => {
    return connectors?.find((c) => c.id === appletId);
  }, [connectors, appletId]);
};
