import {useCallback, useEffect, useState} from 'react';

import AsyncStorage from '@react-native-async-storage/async-storage';
import locale_ar from 'dayjs/locale/ar';
import locale_en from 'dayjs/locale/en';
import {useTranslation} from 'react-i18next';
import {I18nManager, Platform} from 'react-native';

import {useIsDemoMode} from '../context/AppContext';
import {dayjs} from '../utils/extendedDayjs';

export const USER_LANG_KEY = '@USER_LANG';

const applyLanguage = (i18n, lang) => {
  i18n.changeLanguage(lang);

  const dir = i18n.dir(lang);

  I18nManager.forceRTL(dir === 'rtl');

  lang === 'ar' ? dayjs?.locale(locale_ar) : dayjs?.locale(locale_en);

  if (Platform.OS === 'web') document.dir = dir;
};

export const useCachedUserLang = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const {i18n} = useTranslation();
  const isDemoMode = useIsDemoMode();

  useEffect(() => {
    if (!isDemoMode && !isLoaded) {
      AsyncStorage.getItem(USER_LANG_KEY).then((lang) => {
        if (lang) applyLanguage(i18n, lang);
        setIsLoaded(true);
      });
    } else {
      setIsLoaded(true);
    }
  }, []);

  return isLoaded;
};

export const useUserLangSelector = () => {
  const {i18n} = useTranslation();

  const onLangChange = useCallback(async (lang) => {
    applyLanguage(i18n, lang);

    await saveUserLang(lang);
  }, []);

  return {
    onLangChange,
    languagesList: Object.keys(i18n.options.resources as any),
  };
};

export const saveUserLang = (lang: string) => {
  return AsyncStorage.setItem(USER_LANG_KEY, lang);
};
