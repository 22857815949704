import React from 'react';

import {Checkbox} from '../../../Checkbox';
import {Cell} from '../Cell';

interface RowSelectorProps {
  onSelectToggle?: () => void;
  selected?: boolean;
}

export const RowSelector = ({onSelectToggle, selected}: RowSelectorProps) => {
  return (
    <Cell width="$4" flex={0} alignItems="center" justifyContent="center">
      <Checkbox checked={selected} onCheckedChange={onSelectToggle} />
    </Cell>
  );
};
