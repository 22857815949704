import {ScopedCalendarService} from '../../lib/AppCalendar';
import {CacheService, cacheService, ScopedProfileRegistry} from '../../services';
import {useApplet} from '../../utils/useApplet';

const defaultUtils = {
  cache: cacheService,
  calendar: new ScopedCalendarService(''),
  profile: new ScopedProfileRegistry(''),
};

export const useScopedUtils = () => {
  const applet = useApplet();

  return applet?._context?.utils || defaultUtils;
};

export type ScopedUtils = typeof defaultUtils;

export const getScopedUtils = (scopeId: string): ScopedUtils => ({
  cache: new CacheService(scopeId),
  calendar: new ScopedCalendarService(scopeId),
  profile: new ScopedProfileRegistry(scopeId),
});
