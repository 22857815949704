import React from 'react';

import {IconView} from '../../IconView';
import {Pressable} from '../../Pressable';
import {ViewProps} from '../../View';
import {SortDirection} from '../model';

import {Cell} from './Cell';
import {HeaderText} from './HeaderText';

type Props = {
  sortBy?: SortDirection;
  sortable?: boolean;
  onSort?: (direction?: SortDirection) => void;
} & ViewProps;

export const ColumnHeader = ({children, sortBy, sortable, width, onSort, ...props}: Props) => {
  if (!sortable) {
    return (
      <Cell width={width} {...props}>
        <HeaderText>{children} </HeaderText>
      </Cell>
    );
  }

  const toggleSortDirection = () => {
    switch (sortBy) {
      case SortDirection.ASC:
        onSort?.(SortDirection.DESC);
        break;
      case SortDirection.DESC:
        onSort?.();
        break;
      default:
        onSort?.(SortDirection.ASC);
        break;
    }
  };

  return (
    <Cell width={width} {...props}>
      <Pressable onPress={toggleSortDirection} flex={1}>
        <HeaderText>{children}</HeaderText>
        <IconView
          opacity={sortBy ? 1 : 0.5}
          size={12}
          fontWeight="$10"
          icon={sortBy === SortDirection.ASC ? 'ArrowDown' : sortBy === SortDirection.DESC ? 'ArrowUp' : 'ArrowUpDown'}
          style={{marginHorizontal: 4}}
          accent={!!sortBy}
        />
      </Pressable>
    </Cell>
  );
};
