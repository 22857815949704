import { ResetPresence, usePresence } from "@tamagui/use-presence";
import { isWeb, useIsomorphicLayoutEffect } from "@tamagui/constants";
import { useEvent } from "@tamagui/web";
import { useEffect, useMemo, useRef } from "react";
import { Animated } from "react-native-web";
const animatedStyleKey = {
    transform: !0,
    opacity: !0
  },
  colorStyleKey = {
    backgroundColor: !0,
    color: !0,
    borderColor: !0,
    borderLeftColor: !0,
    borderRightColor: !0,
    borderTopColor: !0,
    borderBottomColor: !0
  },
  costlyToAnimateStyleKey = {
    borderRadius: !0,
    borderTopLeftRadius: !0,
    borderTopRightRadius: !0,
    borderBottomLeftRadius: !0,
    borderBottomRightRadius: !0,
    borderWidth: !0,
    borderLeftWidth: !0,
    borderRightWidth: !0,
    borderTopWidth: !0,
    borderBottomWidth: !0,
    ...colorStyleKey
    // TODO for other keys like height or width, it's better to not add them here till layout animations are ready
  },
  AnimatedView = Animated.View,
  AnimatedText = Animated.Text;
function useAnimatedNumber(initial) {
  const state = useRef(null);
  return state.current || (state.current = {
    composite: null,
    val: new Animated.Value(initial),
    strategy: {
      type: "spring"
    }
  }), {
    getInstance() {
      return state.current.val;
    },
    getValue() {
      return state.current.val._value;
    },
    stop() {
      state.current.composite?.stop(), state.current.composite = null;
    },
    setValue(next, {
      type,
      ...config
    } = {
      type: "spring"
    }, onFinish) {
      const val = state.current.val,
        handleFinish = onFinish ? ({
          finished
        }) => finished ? onFinish() : null : void 0;
      if (type === "direct") val.setValue(next);else if (type === "spring") {
        state.current.composite?.stop();
        const composite = Animated.spring(val, {
          ...config,
          toValue: next,
          useNativeDriver: !isWeb
        });
        composite.start(handleFinish), state.current.composite = composite;
      } else {
        state.current.composite?.stop();
        const composite = Animated.timing(val, {
          ...config,
          toValue: next,
          useNativeDriver: !isWeb
        });
        composite.start(handleFinish), state.current.composite = composite;
      }
    }
  };
}
function useAnimatedNumberReaction({
  value
}, onValue) {
  const onChange = useEvent(current => {
    onValue(current.value);
  });
  useEffect(() => {
    const id = value.getInstance().addListener(onChange);
    return () => {
      value.getInstance().removeListener(id);
    };
  }, [value, onChange]);
}
function useAnimatedNumberStyle(value, getStyle) {
  return getStyle(value.getInstance());
}
function createAnimations(animations) {
  return {
    isReactNative: !0,
    animations,
    View: AnimatedView,
    Text: AnimatedText,
    useAnimatedNumber,
    useAnimatedNumberReaction,
    useAnimatedNumberStyle,
    usePresence,
    ResetPresence,
    useAnimations: ({
      props,
      onDidAnimate,
      style,
      componentState,
      presence
    }) => {
      const isExiting = presence?.[0] === !1,
        sendExitComplete = presence?.[1],
        animateStyles = useRef({}),
        animatedTranforms = useRef([]),
        animationsState = useRef( /* @__PURE__ */new WeakMap()),
        animateOnly = props.animateOnly || [],
        hasAnimateOnly = !!props.animateOnly,
        args = [JSON.stringify(style), componentState, isExiting, !!onDidAnimate],
        isThereNoNativeStyleKeys = useMemo(() => isWeb ? !0 : Object.keys(style).some(key => animateOnly.length ? !animatedStyleKey[key] && animateOnly.indexOf(key) === -1 : !animatedStyleKey[key]), args),
        res = useMemo(() => {
          const runners = [],
            completions = [],
            nonAnimatedStyle = {};
          for (const key in style) {
            const val = style[key];
            if (animatedStyleKey[key] == null && !costlyToAnimateStyleKey[key]) {
              nonAnimatedStyle[key] = val;
              continue;
            }
            if (hasAnimateOnly && !animateOnly.includes(key)) {
              nonAnimatedStyle[key] = val;
              continue;
            }
            if (key !== "transform") {
              animateStyles.current[key] = update(key, animateStyles.current[key], val);
              continue;
            }
            if (val) {
              if (typeof val == "string") {
                console.warn("Warning: Tamagui can't animate string transforms yet!");
                continue;
              }
              for (const [index, transform] of val.entries()) {
                if (!transform) continue;
                const tkey = Object.keys(transform)[0],
                  currentTransform = animatedTranforms.current[index]?.[tkey];
                animatedTranforms.current[index] = {
                  [tkey]: update(tkey, currentTransform, transform[tkey])
                }, animatedTranforms.current = [...animatedTranforms.current];
              }
            }
          }
          const animatedStyle = {
            ...Object.fromEntries(Object.entries(animateStyles.current).map(([k, v]) => [k, animationsState.current.get(v)?.interpolation || v])),
            transform: animatedTranforms.current.map(r => {
              const key = Object.keys(r)[0],
                val = animationsState.current.get(r[key])?.interpolation || r[key];
              return {
                [key]: val
              };
            })
          };
          return {
            runners,
            completions,
            style: [nonAnimatedStyle, animatedStyle]
          };
          function update(key, animated, valIn) {
            const isColorStyleKey = colorStyleKey[key],
              [val, type] = isColorStyleKey ? [0, void 0] : getValue(valIn);
            let animateToValue = val;
            const value = animated || new Animated.Value(val),
              curInterpolation = animationsState.current.get(value);
            let interpolateArgs;
            if (type && (interpolateArgs = getInterpolated(curInterpolation?.current ?? value._value, val, type), animationsState.current.set(value, {
              interpolation: value.interpolate(interpolateArgs),
              current: val
            })), isColorStyleKey && (animateToValue = curInterpolation?.animateToValue ? 0 : 1, interpolateArgs = getColorInterpolated(curInterpolation?.current,
            // valIn is the next color
            valIn, animateToValue), animationsState.current.set(value, {
              current: valIn,
              interpolation: value.interpolate(interpolateArgs),
              animateToValue: curInterpolation?.animateToValue ? 0 : 1
            })), value) {
              const animationConfig = getAnimationConfig(key, animations, props.animation);
              let resolve;
              const promise = new Promise(res2 => {
                resolve = res2;
              });
              completions.push(promise), runners.push(() => {
                value.stopAnimation();
                function getAnimation() {
                  return Animated[animationConfig.type || "spring"](value, {
                    toValue: animateToValue,
                    useNativeDriver: !isWeb && !isThereNoNativeStyleKeys,
                    ...animationConfig
                  });
                }
                (animationConfig.delay ? Animated.sequence([Animated.delay(animationConfig.delay), getAnimation()]) : getAnimation()).start(({
                  finished
                }) => {
                  finished && resolve();
                });
              });
            }
            return process.env.NODE_ENV === "development" && props.debug === "verbose" && console.info(" \u{1F4A0} animate", key, `from (${value._value}) to`, valIn, `(${val})`, "type", type, "interpolate", interpolateArgs), value;
          }
        }, args);
      return useIsomorphicLayoutEffect(() => {
        res.runners.forEach(r => r());
        let cancel = !1;
        return Promise.all(res.completions).then(() => {
          cancel || (onDidAnimate?.(), isExiting && sendExitComplete?.());
        }), () => {
          cancel = !0;
        };
      }, args), process.env.NODE_ENV === "development" && props.debug === "verbose" && console.info("Animated", {
        response: res,
        inputStyle: style,
        isExiting
      }), res;
    }
  };
}
function getColorInterpolated(currentColor, nextColor, animateToValue) {
  const inputRange = [0, 1],
    outputRange = [currentColor || nextColor, nextColor];
  return animateToValue === 0 && outputRange.reverse(), {
    inputRange,
    outputRange
  };
}
function getInterpolated(current, next, postfix = "deg") {
  next === current && (current = next - 1e-9);
  const inputRange = [current, next],
    outputRange = [`${current}${postfix}`, `${next}${postfix}`];
  return next < current && (inputRange.reverse(), outputRange.reverse()), {
    inputRange,
    outputRange
  };
}
function getAnimationConfig(key, animations, animation) {
  if (typeof animation == "string") return animations[animation];
  let type = "",
    extraConf;
  const shortKey = transformShorthands[key];
  if (Array.isArray(animation)) {
    type = animation[0];
    const conf = animation[1]?.[key] ?? animation[1]?.[shortKey];
    conf && (typeof conf == "string" ? type = conf : (type = conf.type || type, extraConf = conf));
  } else {
    const val = animation?.[key] ?? animation?.[shortKey];
    type = val?.type, extraConf = val;
  }
  return {
    ...animations[type],
    ...extraConf
  };
}
const transformShorthands = {
  x: "translateX",
  y: "translateY",
  translateX: "x",
  translateY: "y"
};
function getValue(input, isColor = !1) {
  if (typeof input != "string") return [input];
  const [_, number, after] = input.match(/([-0-9]+)(deg|%|px)/) ?? [];
  return [+number, after];
}
export { AnimatedText, AnimatedView, createAnimations, useAnimatedNumber, useAnimatedNumberReaction, useAnimatedNumberStyle };