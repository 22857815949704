import createLucideIcon from '../createLucideIcon';

const BookType = createLucideIcon('BookType', [
  [
    'path',
    {
      d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20',
      key: 't4utmx',
    },
  ],
  ['path', { d: 'M16 8V6H8v2', key: 'x8j6u4' }],
  ['path', { d: 'M12 6v7', key: '1f6ttz' }],
  ['path', { d: 'M10 13h4', key: 'ytezjc' }],
]);

export default BookType;
