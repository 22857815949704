import React from 'react';

import {View} from 'react-native';

import {FullScreenModal} from '../FullScreenModal';
import {Spinner} from '../Spinner';

export function FullScreenLoadingModal() {
  return (
    <FullScreenModal animationType="none" hideNavigationHeader>
      <View style={{alignContent: 'center', justifyContent: 'center', flex: 1}}>
        <Spinner />
      </View>
    </FullScreenModal>
  );
}
