import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import hijri from 'dayjs-calendar-hijri';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(hijri);
dayjs.extend(customParseFormat);

export {dayjs};
