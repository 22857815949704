import React, {ReactNode} from 'react';

import {Alert, Form, message, Space, FormInstance} from 'antd';
import mergeWith from 'lodash/mergeWith';
import {Trans} from 'react-i18next';

import {useAppletContext} from '../../../context';
import {editConnector, useConnector} from '../../../data';
import {Button} from '../../Button';
import {IconView} from '../../IconView';
import {PageLoader} from '../../PageLoader';
import {CoreTrans} from '../../ScopedTrans';
import {Typography} from '../../Typography';
import {XStack} from '../../XStack';

interface Props {
  requireConnectorDefinition?: boolean;
  children?: ReactNode;
  title?: ReactNode;
  hideAppletDisabledHint?: boolean;
  form?: FormInstance<any>;
}

export const AppletConfigSection: React.FC<Props> = ({
  requireConnectorDefinition,
  hideAppletDisabledHint,
  children,
  title,
  form,
}) => {
  const appletId = useAppletContext();
  const {data: connector, isLoading: isLoadingConnector} = useConnector(appletId);
  const {isLoading: isUpdatingConnector, mutateAsync: updateConnector} = editConnector(appletId);

  const onSubmit = async (values: any) => {
    try {
      function mergeCustomizer(objValue: unknown, srcValue: unknown) {
        if (Array.isArray(srcValue) || Array.isArray(objValue)) {
          return srcValue;
        }
      }
      const body = mergeWith(connector || {}, values, mergeCustomizer);
      delete body.metadata;

      await updateConnector(body);

      message.success(<Trans i18nKey="successMsg" />);
    } catch (e) {
      message.error(<Trans i18nKey="generalErrorMsg" />);
    }
  };

  if (isLoadingConnector) {
    return <PageLoader />;
  }

  if (!connector && requireConnectorDefinition) {
    return <Alert message={<CoreTrans i18nKey="connectorMustBeDefined" />} type="error" />;
  }

  return (
    <Form
      layout="vertical"
      name={`${appletId}-configs`}
      initialValues={connector}
      onFinish={onSubmit}
      scrollToFirstError
      form={form}>
      <Space direction="vertical" className="width-fill">
        {!connector?.enabled && !hideAppletDisabledHint && (
          <Alert className="margin-bottom" message={<CoreTrans i18nKey="connectorIsDisabled" />} type="warning" />
        )}
        <XStack justifyContent="space-between" alignItems="center">
          <Typography variant="h4" marginBottom={4}>
            {title}
          </Typography>

          <Button
            height="$3"
            icon={<IconView icon="Save" />}
            disabled={isUpdatingConnector}
            loading={isUpdatingConnector}>
            <Trans i18nKey="action.save" />
          </Button>
        </XStack>
        {children}
      </Space>
    </Form>
  );
};
