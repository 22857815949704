import createLucideIcon from '../createLucideIcon';

const GitCompareArrows = createLucideIcon('GitCompareArrows', [
  ['circle', { cx: '5', cy: '6', r: '3', key: '1qnov2' }],
  ['path', { d: 'M12 6h5a2 2 0 0 1 2 2v7', key: '1yj91y' }],
  ['path', { d: 'm15 9-3-3 3-3', key: '1lwv8l' }],
  ['circle', { cx: '19', cy: '18', r: '3', key: '1qljk2' }],
  ['path', { d: 'M12 18H7a2 2 0 0 1-2-2V9', key: '16sdep' }],
  ['path', { d: 'm9 15 3 3-3 3', key: '1m3kbl' }],
]);

export default GitCompareArrows;
