import React from 'react';

import {Modal, Pressable, StyleSheet, View} from 'react-native';

import {useResponsiveValue} from '../../utils/withResponsiveProps';

interface Props {
  isOpened: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const OverlayModal = ({isOpened = false, onClose, children}: Props) => {
  const isDesktopView = useResponsiveValue({$sm: false, $md: true});

  const styles = StyleSheet.create({
    modal: {
      alignSelf: 'center',
      backgroundColor: 'white',
      width: isDesktopView ? '50%' : '90%',
      maxHeight: isDesktopView ? '70%' : '90%',
      minHeight: isDesktopView ? '40%' : '40%',
      borderRadius: 10,
      marginVertical: isDesktopView ? '10%' : 20,
      overflow: 'scroll',
    },
  });
  return (
    <Modal animationType="fade" transparent={true} onRequestClose={onClose} visible={isOpened}>
      <Pressable onPress={onClose} style={Styles.backdrop} />
      <View style={styles.modal}>{children}</View>
    </Modal>
  );
};

const Styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#232f34',
    opacity: 0.2,
  },
});
