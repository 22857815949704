export enum ViewportSizes {
  MOBILE = '$sm',
  TABLET = '$md',
  DESKTOP = '$lg',
}

export const pageContainerWidthByViewportSize = {
  [ViewportSizes.DESKTOP]: '100%',
  [ViewportSizes.TABLET]: '820px',
  [ViewportSizes.MOBILE]: '340px',
} as const;

export const defaultViewportSize = ViewportSizes.DESKTOP;

// Type Guards
export function isMobileViewPort(viewportSize: ViewportSizes): viewportSize is ViewportSizes.MOBILE {
  return viewportSize === ViewportSizes.MOBILE;
}

export function isTabletViewPort(viewportSize: ViewportSizes): viewportSize is ViewportSizes.TABLET {
  return viewportSize === ViewportSizes.TABLET;
}

export function isDesktopViewPort(viewportSize: ViewportSizes): viewportSize is ViewportSizes.DESKTOP {
  return viewportSize === ViewportSizes.DESKTOP;
}
