import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../types';
import {coreScopedKey} from '../../../../utils';
import {View, ViewProps} from '../../../View';
import {YoutubePlayer, YoutubePlayerProps} from '../../../YoutubePlayer';
import {LayoutPropsBuilder} from '../../components';

import {YoutubePlayerpropsBuilder} from './PropsBuilder';

export type YoutubePlayerElementProps = ViewProps & YoutubePlayerProps;

const defaultProps: YoutubePlayerElementProps = {
  style: {maxWidth: '100%', height: 200},
  url: 'https://www.youtube.com/watch?v=Zi1TEZ9PDHI',
};

export const Component = (props: YoutubePlayerElementProps, ref) => {
  return (
    <View {...props} ref={ref}>
      <YoutubePlayer key={props.url} url={props.url} />
    </View>
  );
};

export const YoutubePlayerComponent = forwardRef(Component);

export const YoutubePlayerElement: BlockDefinition<YoutubePlayerElementProps> = {
  name: coreScopedKey('blocks.YoutubePlayerElement'),
  version: 1,
  id: 'YoutubePlayerElement',
  props: defaultProps,
  category: BlockCategory.NODE,
  Component: YoutubePlayerComponent,
  Icon: 'Youtube',
  propsBuilder: [YoutubePlayerpropsBuilder, LayoutPropsBuilder],
};
