import React, {useState} from 'react';

import {uploadFile} from '../../utils/uploadFile';
import {Button} from '../Button';

import {FilePickerProps} from './types';

export function FilePicker({pickerOptions, label, onChange, ...props}: FilePickerProps) {
  const [fileName, setFileName] = useState<string>('');

  const pickFile = async () => {
    const res = await uploadFile(pickerOptions);

    if (!res.canceled) {
      setFileName(res.assets.map((it) => it.name).join(', '));
      onChange(res);
    }
  };

  return (
    <Button {...props} onPress={pickFile}>
      {label} {fileName && `(${fileName})`}
    </Button>
  );
}
