export const DARK_COLORS = {
  background: '#18191A',
  accent: 'gray',
  accentLight: 'lightgray',
  highlightColor: 'gray',
  disabled: '#DDE4EE',
  border: '#383A3C',
  placeholder: 'white',
  subText: '#4B5563',
  textColor: 'white',
  transparent: '#ffffff00',
  borderHover: '#64748B',
  neutral: '#292929',
  grey: '#73738C',
  lightWhite: '#ffffff30',
  black: '#000000',
  white: '#FFFFFF',
};

export const DARK_BLUE = {
  'blue-900': '#194185',
  'blue-700': '#B2DDFF',
  'blue-600': '#84CAFF',
  'blue-400': '#53B1FD',
  'blue-300': '#84B4FF',
  'blue-200': '#B2DDFF',
  'blue-50': '#194185',
};

export const DARK_AMBER = {
  'amber-900': '#9C5604',
  'amber-700': '#FEF0C7',
  'amber-600': '#FEC84B',
  'amber-400': '#FF9500',
  'amber-300': '#FEC84B',
  'amber-200': '#FEDF89',
  'amber-100': '#FEF0C7',
  'amber-50': '#492B15',
};

export const DARK_GREEN = {
  'green-900': '#05603A',
  'green-700': '#C7FED6',
  'green-600': '#6CE9A6',
  'green-400': '#20BF74',
  'green-300': '#6CE9A6',
  'green-200': '#A6F4C5',
  'green-100': '#C7FED6',
  'green-50': '#21382D',
};

export const DARK_GREY = {
  'grey-50': '#F7F7F8',
  'grey-100': '#EBEBEF',
  'grey-200': '#D8D8DF',
  'grey-300': '#B9B9C6',
  'grey-400': '#8F8FA3',
  grey: '#73738C',
  'grey-600': '#5A5A72',
  'grey-700': '#434356',
  'grey-800': '#25252D',
  'grey-900': '#131318',
};

export const DARK_NEUTRAL = {
  'neutral-1150': '#242526',
  'neutral-1100': '#3A3B3C',
  'neutral-1050': '#242526',
  'neutral-1000': '#18191A',
  'neutral-900': '#E4E6EA',
  'neutral-800': '#E4E6EA',
  'neutral-700': '#F1F5F9',
  'neutral-600': '#E4E6EB',
  'neutral-500': '#B1B3B8',
  'neutral-400': '#B1B3B8',
  'neutral-300': '#B1B3B8',
  'neutral-200': '#343536',
  'neutral-100': '#242526',
  'neutral-50': '#3A3B3C',
};

export const DARK_RED = {
  'red-900': '#87112B',
  'red-700': '#FFDFDD',
  'red-600': '#FDA19B',
  'red-400': '#ED456A',
  'red-300': '#FDA19B',
  'red-200': '#FECDC9',
  'red-100': '#FFDFDD',
  'red-50': '#491515',
};
