import React from 'react';

import {NoDataMessage} from '../NoDataMessage';
import {View} from '../View';

export function EmptyIllustration() {
  return (
    <View alignItems="center" justifyContent="center" marginTop={40} paddingBottom={5} paddingHorizontal={5}>
      <NoDataMessage size={128} hasNoData hideLabel />
    </View>
  );
}
