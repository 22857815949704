import React from 'react';

import {Trans as TransBase} from 'react-i18next';
import {useTranslation} from 'react-i18next';

import {useAppletContext} from '../../context/AppletContext';
import {appletScopedKey, coreScopedKey, shellScopedKey} from '../../utils/scopedKeys';

export * from './utils';

type TransProps = React.ComponentProps<typeof TransBase>;

export const Trans = (props: TransProps) => {
  const {i18n} = useTranslation();

  return <TransBase key={i18n.resolvedLanguage} {...props} />;
};

export const AppletTrans = ({i18nKey, ...props}: TransProps) => {
  const appletId = useAppletContext();

  return <Trans i18nKey={appletScopedKey(appletId, i18nKey)} {...props} />;
};

export const CoreTrans = ({i18nKey, ...props}: TransProps) => {
  return <Trans i18nKey={coreScopedKey(i18nKey)} {...props} />;
};

export const ShellTrans = ({i18nKey, ...props}: TransProps) => {
  return <Trans i18nKey={shellScopedKey(i18nKey)} {...props} />;
};
