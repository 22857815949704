import createLucideIcon from '../createLucideIcon';

const GitPullRequestArrow = createLucideIcon('GitPullRequestArrow', [
  ['circle', { cx: '5', cy: '6', r: '3', key: '1qnov2' }],
  ['path', { d: 'M5 9v12', key: 'ih889a' }],
  ['circle', { cx: '19', cy: '18', r: '3', key: '1qljk2' }],
  ['path', { d: 'm15 9-3-3 3-3', key: '1lwv8l' }],
  ['path', { d: 'M12 6h5a2 2 0 0 1 2 2v7', key: '1yj91y' }],
]);

export default GitPullRequestArrow;
