import {useQuery} from 'react-query';

import {useAppletContext} from '../../context';

export const getScopedQueryKey = (scope, queryKey) => {
  if (typeof queryKey === 'string') {
    queryKey = `${scope}.${queryKey}`;
  } else if (Array.isArray(queryKey)) {
    queryKey = [scope, ...queryKey];
  }

  return queryKey;
};

export const useAppletScopedQuery: typeof useQuery = (queryKey, ...props) => {
  const appletId = useAppletContext();

  return useQuery(getScopedQueryKey(appletId, queryKey), ...props);
};
