import {useEffect, useState} from 'react';

import {EnvironmentProfile} from '../types/EnvironmentProfile';
import {blobToBase64} from '../utils/blobToBase64';
import {isUrl} from '../utils/validators/url';

import {useAppFile} from './useAppFile';

export function useAppImage(
  file: string | Blob | undefined,
  profile: EnvironmentProfile,
  serviceId?: string,
  useServiceImage?: any,
) {
  const usingFileFrom = isUrl(file);
  let image: any;
  let restConfig;
  if (!serviceId) {
    image = useAppFile(!usingFileFrom && file, profile).data;
    restConfig = useAppFile(!usingFileFrom && file, profile);
  } else {
    image = useServiceImage(!usingFileFrom && serviceId).data;
    restConfig = useServiceImage(!usingFileFrom && serviceId);
  }
  const [url, setUrl] = useState<string | undefined>(usingFileFrom ? (file as string) : undefined);

  useEffect(() => {
    if (isUrl(file)) {
      setUrl(file as string);
      return;
    }

    const isBlob = !!file && typeof file !== 'string';
    if (isBlob) {
      blobToBase64(file).then(setUrl);
      return;
    }

    if (!image) {
      setUrl(undefined);
    } else {
      blobToBase64(image).then(setUrl);
    }
  }, [file, image]);

  return {url, ...restConfig};
}
