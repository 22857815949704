import {AppContentPage, Block, DesignSpec} from '../../../types';
import {CommonElements} from '../elements';

export const designSpec: DesignSpec = {
  version: 1,
  blockDefinition: CommonElements,
  migrate: migratePage,
};

function migrateBlock(block: Block): Block {
  const blockDef = designSpec.blockDefinition[block.definitionId];

  if (blockDef && block.definitionVersion < blockDef.version) {
    return blockDef.migrate ? blockDef.migrate(block) || block : block;
  }

  if (block.children && block.children.length > 0) {
    const updatedBlock = {
      ...block,
      children: block.children.map(migrateBlock),
    };

    return updatedBlock;
  }

  return block;
}

export function migratePage(page: AppContentPage | null): AppContentPage | null {
  if (!page) return null;
  if (page.specVersion < designSpec.version) {
    return designSpec.migrate ? designSpec.migrate(page) || page : page;
  }

  const updatedPage = {
    ...page,
    children: page.children.map(migrateBlock),
  };

  return updatedPage;
}
