import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Calendar from 'expo-calendar';
import {Platform} from 'react-native';

export type NativeCalendarApi = {
  addEventsToCalendar: (event: Partial<Calendar.Event>, storeName: string) => Promise<string | undefined>;
  createCalendar: (title: string, storeName: string, color?: string, sourceName?: string) => Promise<void>;
  deleteCalendar: (storeName: string) => Promise<void>;
  getCalendarId: (storeName: string) => Promise<string | undefined>;
  getEvents: (storeName: string, startDate: Date, endDate: Date) => Promise<false | Calendar.Event[]>;
  getPermission: () => Promise<boolean>;
  getCalendars: () => Promise<Calendar.Calendar[]>;
  deleteCalendarEvent: (storeName: string, eventId: string) => Promise<void>;
};

export const nativeCalendarApi = (): NativeCalendarApi => {
  const getDefaultCalendarSource = async () => {
    const defaultCalendar = await Calendar.getDefaultCalendarAsync();
    return defaultCalendar.source;
  };

  const storeCalendar = async (storeName: string, calendarId: string) => {
    try {
      await AsyncStorage.setItem(storeName, calendarId);
    } catch (e) {}
  };

  const getCalendarStored = async (storeName: string) => {
    try {
      const calendarStored = await AsyncStorage.getItem(storeName);
      if (calendarStored !== null) return calendarStored;
    } catch (e) {}
  };

  const getPermission = async () => {
    const {status} = await Calendar.requestCalendarPermissionsAsync();
    return status === 'granted';
  };

  const createCalendar = async (
    title: string,
    storeName: string,
    color = '#5351e0',
    sourceName = 'Medad SEP Calendar',
  ) => {
    const defaultCalendarSource =
      Platform.OS === 'ios' ? await getDefaultCalendarSource() : {isLocalAccount: true, name: title, id: undefined};

    const thereIsACalendar = await getCalendarStored(storeName);

    if (!thereIsACalendar) {
      const newCalendarID = await Calendar.createCalendarAsync({
        title,
        color,
        entityType: Calendar.EntityTypes.EVENT,
        sourceId: defaultCalendarSource.id,
        source: {isLocalAccount: true, name: sourceName, type: 'LOCAL'},
        name: storeName,
        ownerAccount: 'personal',
        accessLevel: Calendar.CalendarAccessLevel.OWNER,
      });

      storeCalendar(storeName, newCalendarID);
    }
  };

  const deleteCalendar = async (storeName: string) => {
    try {
      const calendarId = await getCalendarStored(storeName);
      if (calendarId) {
        await AsyncStorage.removeItem(storeName);
        await Calendar.deleteCalendarAsync(calendarId);
      }
    } catch (e) {}
  };

  const deleteCalendarEvent = async (storeName: string, eventId: string) => {
    try {
      const calendarId = await getCalendarStored(storeName);
      if (calendarId) {
        await Calendar.deleteEventAsync(eventId);
      }
    } catch (e) {}
  };

  const addEventsToCalendar = async (event: Partial<Calendar.Event>, storeName: string) => {
    const calendarId = await getCalendarStored(storeName);

    if (calendarId) {
      const startDate = new Date(event.startDate as any);
      const endDate = new Date(event.endDate as any);

      const existingEvents = await Calendar.getEventsAsync([calendarId], startDate, endDate);

      const isDuplicate = existingEvents.some(
        (existingEvent) =>
          existingEvent.title === event.title &&
          existingEvent.startDate === event.startDate &&
          existingEvent.endDate === event.endDate,
      );

      if (!isDuplicate) {
        const _event = {
          timeZone: 'local',
          alarms: [
            {
              relativeOffset: 0,
              method: Calendar.AlarmMethod.ALERT,
            },
          ],
          ...event,
        };

        const eventId = await Calendar.createEventAsync(calendarId, _event);
        return eventId;
      }
    }
  };

  const getEvents = async (storeName: string, startDate: Date, endDate: Date) => {
    const thereIsACalendar = await getCalendarStored(storeName);
    let thereIs: Calendar.Event[] = [];
    if (thereIsACalendar) {
      thereIs = await Calendar.getEventsAsync([thereIsACalendar], startDate, endDate);
    }
    return thereIsACalendar ? thereIs : false;
  };

  const getCalendars = async () => {
    return await Calendar.getCalendarsAsync();
  };

  const getCalendarId = async (storeName: string) => {
    return await getCalendarStored(storeName);
  };

  return {
    addEventsToCalendar,
    createCalendar,
    deleteCalendar,
    getCalendarId,
    getEvents,
    getPermission,
    getCalendars,
    deleteCalendarEvent,
  };
};
