import createLucideIcon from '../createLucideIcon';

const FolderInput = createLucideIcon('FolderInput', [
  [
    'path',
    {
      d: 'M2 9V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H20a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-1',
      key: 'fm4g5t',
    },
  ],
  ['path', { d: 'M2 13h10', key: 'pgb2dq' }],
  ['path', { d: 'm9 16 3-3-3-3', key: '6m91ic' }],
]);

export default FolderInput;
