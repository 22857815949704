import {isDefined} from '../isDefined';

export const appletScopedKey = (id, key?, namespace?) => '@applet/' + [id, namespace, key].filter(isDefined).join('.');
export const parseAppletScopedKey = (scopedKey) => {
  const match = /@applet\/(\w*).?([\w.]*)/g.exec(scopedKey);

  return {
    appletId: match?.[1],
    key: match?.[2],
  };
};

export const coreScopedKey = (key?) => [`@core`, key].filter(isDefined).join('.');

export const shellScopedKey = (key?) => [`@shell`, key].filter(isDefined).join('.');
