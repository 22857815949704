import React from 'react';

import {
  TwitterShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share';
import {XStack} from 'tamagui';

import {Popover} from '../Popover';

interface Props {
  trigger: any;
  link: string;
  label: string;
}

export const SocialShareModal: React.FC<Props> = ({trigger, label, link}) => {
  return (
    <Popover trigger={trigger}>
      <XStack marginTop={2} space={2} alignItems="flex-start" flexWrap="wrap">
        <TwitterShareButton url={link} title={label}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <FacebookShareButton url={link} quote={label}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <WhatsappShareButton url={link} title={label}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>

        <EmailShareButton url={link} subject={label}>
          <EmailIcon size={32} round />
        </EmailShareButton>
      </XStack>
    </Popover>
  );
};
