import dayjs from 'dayjs';

export function formatParams(params): any {
  const _params = {...params};
  for (const key of Object.keys(_params)) {
    const value = _params[key];
    if (value instanceof dayjs) {
      _params[key] = _params[key].format('YYYY-MM-DD');
    }
  }

  return _params;
}
