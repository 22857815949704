import React, {ReactNode} from 'react';

import {ColorTokens, View, YStack, YStackProps} from 'tamagui';

import {IconType} from '../../types';
import {IconView} from '../IconView';
import {Typography} from '../Typography';

export type TimelineProps<T = {title: string; description: string; icon?: IconType; iconColor?: ColorTokens}> = {
  lineColor?: YStackProps['borderColor'];
  gap?: YStackProps['gap'];
  stepIndicatorSize?: number;
  lineWidth?: number;
  steps: T[];
  renderItem?: (item: T) => ReactNode;
};

const defaultRenderItems = (item) => (
  <YStack gap={2}>
    <Typography variant="body1">{item.title}</Typography>
    <Typography variant="caption" fontWeight="400">
      {item.description}
    </Typography>
  </YStack>
);

export const Timeline = <T extends {title: string; description: string; icon?: IconType; iconColor?: ColorTokens}>({
  steps,
  lineColor = '$neutral-400',
  gap = 12,
  stepIndicatorSize = 8,
  lineWidth = 2,
  renderItem = defaultRenderItems,
}: TimelineProps<T>) => {
  return (
    <View
      flexDirection="column"
      borderStartWidth={1}
      alignItems="stretch"
      borderColor={lineColor}
      position="relative"
      gap={gap}>
      <View
        width={0}
        height="100%"
        borderWidth={lineWidth / 2}
        borderColor={lineColor}
        position="absolute"
        top="0"
        left="0"
        right="0"
      />
      {steps.map((item, index) => (
        <View key={index} position="relative">
          <View
            width={stepIndicatorSize}
            height={stepIndicatorSize}
            backgroundColor="$background"
            borderRadius={1000}
            borderWidth={lineWidth}
            borderColor={item.iconColor}
            position="absolute"
            top="0"
            alignItems="center"
            justifyContent="center"
            left={-(stepIndicatorSize - lineWidth) / 2}
            right={-(stepIndicatorSize - lineWidth) / 2}>
            <IconView icon={item.icon} color={item.iconColor} size={stepIndicatorSize / 1.75} />
          </View>
          <YStack marginHorizontal={stepIndicatorSize + 4}>{renderItem(item)}</YStack>
        </View>
      ))}
    </View>
  );
};
