import React from 'react';

import {ToastViewport, ToastProvider} from '@tamagui/toast';
import {Modal, SafeAreaView, StyleSheet} from 'react-native';
import {useTheme} from 'tamagui';

import {LocalizedTextType} from '../../types';
import {NavigationHeader} from '../NavigationHeader';

interface Props {
  onClose?: () => void;
  isVisible?: boolean;
  animationType?: 'none' | 'slide' | 'fade' | undefined;
  title?: LocalizedTextType;
  children?: JSX.Element | JSX.Element[];
  hideNavigationHeader?: boolean;
}

export const FullScreenModal: React.FC<Props> = ({
  onClose,
  isVisible,
  children,
  title = '',
  animationType = 'slide',
  hideNavigationHeader,
}) => {
  const {background} = useTheme();

  return (
    <Modal
      animationType={animationType}
      visible={isVisible}
      onRequestClose={onClose}
      presentationStyle="fullScreen"
      style={[styles.modal, {backgroundColor: background?.val}]}>
      <SafeAreaView style={[styles.container, {backgroundColor: background?.val}]}>
        {!hideNavigationHeader && <NavigationHeader title={title} onBack={onClose} />}

        {children}
        <ToastProvider>
          <ToastViewport width="100%" />
        </ToastProvider>
      </SafeAreaView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    height: '100%',
  },
  container: {
    flex: 1,
  },
});
