import React from 'react';

import {Trans} from 'react-i18next';

import {coreScopedKey} from '../../utils/scopedKeys';
import {Typography} from '../Typography';

interface Props {
  error?: any;
}

export const FieldError: React.FC<Props> = ({error}) => {
  const fieldError: {type: string; message: string} = Array.isArray(error) ? error[0] : error;

  if (!fieldError) {
    return null;
  }

  return (
    <Typography color="red">
      <Trans i18nKey={fieldError.message || coreScopedKey(`validation.${fieldError.type}`)} />
    </Typography>
  );
};
