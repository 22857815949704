import {useQuery} from 'react-query';

import {useAPI} from '../services';
import {ApiConnector} from '../types';

export function useConnectors(baseApiUrl?: string, tenant?: string) {
  const API = useAPI();
  const {data, ...rest} = useQuery(['connector-manager/connectors'], () =>
    API.get(`connector-manager/connectors`, {
      prefixUrl: baseApiUrl,
      headers: {'x-tenant': tenant},
    }).json<{connectors: ApiConnector[]}>(),
  );
  return {data: data?.connectors, ...rest};
}
