import React, {createContext, PropsWithChildren, ReactNode, useContext} from 'react';

export interface DatepickerContext {
  rtl?: boolean;
  focusedDate: Date | null;
  goToPreviousMonths: () => void;
  goToPreviousMonthsByOneMonth: () => void;
  goToNextMonths: () => void;
  goToNextMonthsByOneMonth: () => void;
  goToPreviousYear: (numYears?: number) => void;
  goToNextYear: (numYears?: number) => void;

  onDateSelect(date: Date): void;
  onDateHover(date: Date): void;

  isDateFocused(date: Date): boolean;
  isDateSelected(date: Date): boolean;
  isDateHovered(date: Date): boolean;
  isDateBlocked(date: Date): boolean;
  isFirstOrLastSelectedDate(date: Date): boolean;

  onDayRender?(date: Date): ReactNode;
}

export const datepickerContextDefaultValue: DatepickerContext = {
  rtl: false,
  focusedDate: null,
  isDateFocused: () => false,
  isDateSelected: () => false,
  isDateHovered: () => false,
  isDateBlocked: () => false,
  isFirstOrLastSelectedDate: () => false,
  onDateHover: () => {},
  onDateSelect: () => {},
  onDayRender: undefined,
  goToPreviousMonthsByOneMonth: () => {},
  goToPreviousMonths: () => {},
  goToNextMonthsByOneMonth: () => {},
  goToNextMonths: () => {},
  goToPreviousYear: () => {},
  goToNextYear: () => {},
};

const DatepickerContext = createContext<DatepickerContext>(datepickerContextDefaultValue);

export type DatepickerProviderProps = PropsWithChildren<Partial<DatepickerContext>>;

export const DatepickerProvider = ({children, ...value}: DatepickerProviderProps) => {
  const contextValue: DatepickerContext = {
    ...datepickerContextDefaultValue,
    ...value,
  };

  return <DatepickerContext.Provider value={contextValue}>{children}</DatepickerContext.Provider>;
};

export const useDatepickerContext = () => useContext(DatepickerContext);
