import React from 'react';

import {TamaguiProvider} from 'tamagui';

import {useAppTheme} from '../../context/AppThemeContext';
import {createCustomTamaguiConfig, CustomTamaguiConfig} from '../../lib/tamagui';
import {ToastProvider} from '../Toast';

type Props = CustomTamaguiConfig & {
  children: any;
};

export function ShellContainerProvider({children, ...rest}: Props) {
  const {theme} = useAppTheme();

  return (
    <TamaguiProvider defaultTheme={theme || ''} config={createCustomTamaguiConfig(rest)}>
      <ToastProvider>{children}</ToastProvider>
    </TamaguiProvider>
  );
}
