import React from 'react';

import {BidirectionalIcon} from '../BidirectionalIcon';
import {Button} from '../Button';

interface Props {
  onBack?: () => void;
}

export const NavigateBackButton: React.FC<Props> = ({onBack}) => {
  return (
    <Button
      onPress={onBack}
      icon={<BidirectionalIcon color="$neutral-600" icon="ArrowLeft" size={24} />}
      borderColor="$border"
      variant="outlined"
      paddingHorizontal="$8"
    />
  );
};
