import tinyColor from 'tinycolor2';

interface ColorHelpers {
  isLight: (color: string) => boolean;
  isDark: (color: string) => boolean;
  setAlpha: (color: string, alpha: number) => string;

  lighten: (color: string, amount: number) => string;
  darken: (color: string, amount: number) => string;
  brighten: (color: string, amount: number) => string;
}

export const Color: typeof tinyColor & ColorHelpers = tinyColor as any;

Color.isLight = (color: string) => Color(color).isLight();
Color.isDark = (color: string) => Color(color).isDark();
Color.setAlpha = (color: string, alpha: number) => `${Color(color).setAlpha(alpha)}`;
Color.lighten = (color: string, amount: number) => `${Color(color).lighten(amount)}`;
Color.brighten = (color: string, amount: number) => `${Color(color).brighten(amount)}`;
Color.darken = (color: string, amount: number) => `${Color(color).darken(amount)}`;
