import React, {ComponentProps} from 'react';

import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {NativeStackNavigatorProps} from '@react-navigation/native-stack/lib/typescript/src/types';

import {NavigationHeader, NavigationHeaderProps} from '../../components/NavigationHeader';
import {navigateBack} from '../navigateToRoot';

export function createStackNavigator() {
  const Stack = createNativeStackNavigator();

  const Navigator = ({children, initialRouteName = undefined}: NativeStackNavigatorProps) => (
    <Stack.Navigator
      initialRouteName={initialRouteName}
      screenOptions={{
        header: ({options, navigation}) => {
          const goBack = () => navigateBack(navigation as any);
          return <NavigationHeader onBack={goBack} {...((options as any).navigationHeader || {})} />;
        },
      }}>
      {children}
    </Stack.Navigator>
  );

  type OriginalScreenProps = ComponentProps<typeof Stack.Screen>;

  type ScreenProps = OriginalScreenProps & {
    options?: OriginalScreenProps['options'] & {navigationHeader?: NavigationHeaderProps};
  };

  const Screen: React.FC<ScreenProps> = Stack.Screen;

  return {Stack, Screen, Navigator};
}
