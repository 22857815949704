import React, {forwardRef} from 'react';

import {LocalizedTextType, BlockDefinition, BlockCategory} from '../../../../types';
import {coreScopedKey} from '../../../../utils';
import {Button} from '../../../Button';
import {ButtonProps} from '../../../Button/types';
import {Link} from '../../../Link';
import {LocalizedText} from '../../../LocalizedText';
import {Typography} from '../../../Typography';
import {LayoutPropsBuilder} from '../../components';

import {ButtonPropsBuilder} from './PropsBuilder';

export type ButtonElementProps = ButtonProps & {
  value?: LocalizedTextType;
  fontSize?: number;
};

const defaultProps: ButtonElementProps = {
  href: 'https://septenant.medadstg.com/',
  value: 'Click me',
  fontSize: 20,
  fontWeight: '400',
  textAlign: 'center',
  margin: 0,
  padding: 0,
};

export const Component = ({value, ...props}: ButtonElementProps, ref) => {
  return (
    <Button $sm={{width: '100%'}} {...props} ref={ref}>
      <Link href={props.href} textDecorationLine="none">
        <Typography>
          <LocalizedText value={value} />
        </Typography>
      </Link>
    </Button>
  );
};

export const ButtonComponent = forwardRef(Component);

export const ButtonElement: BlockDefinition<ButtonElementProps> = {
  name: coreScopedKey('blocks.ButtonElement'),
  version: 1,
  id: 'ButtonElement',
  props: defaultProps,
  Component: ButtonComponent,
  category: BlockCategory.NODE,
  Icon: 'SquareGantt',
  propsBuilder: [ButtonPropsBuilder, LayoutPropsBuilder],
};
