import React from 'react';

import {View} from 'react-native';
import {ColorTokens, getToken, useTheme} from 'tamagui';

import {useAppConfig} from '../../context';
import {Icon, IconListType} from '../../lib/lucide';
import {IconProps, IconType} from '../../types';
import {isReactComponent} from '../../utils/isReactComponent';

type Props = IconProps & {
  icon?: IconType;
  defaultIcon?: IconListType;
  fill?: boolean;
  fillColor?: ColorTokens;
  accent?: boolean;
  color?: ColorTokens;
};

export const IconView = ({icon, defaultIcon, color, accent, fill, fillColor = '$transparent', ...props}: Props) => {
  const appConfig = useAppConfig();
  const theme = useTheme();

  const filledColor = fillColor == '$accent' ? appConfig.accentColor : theme[fillColor]?.val ?? 'transparent';

  let iconColor = getToken(color as any) || (color && theme[color]?.val) || color;

  if (accent || iconColor == '$accent') {
    iconColor = appConfig?.accentColor as any;
  }

  if (!iconColor) {
    iconColor = theme['$neutral-600']?.val;
  }

  let content: any = null;

  if (!icon || typeof icon === 'string') {
    content = (
      <View {...props}>
        <Icon name={(icon || defaultIcon || 'Hexagon') as any} size={props.size} color={iconColor} fill={filledColor} />
      </View>
    );
  }

  if (isReactComponent(icon)) {
    const IconComponent = icon as any;
    content = <IconComponent {...props} color={iconColor} />;
  }

  if (content && fill) {
    content = (
      <View style={{height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center'}}>{content}</View>
    );
  }

  return content;
};
