import tinycolor from 'tinycolor2';

type ColorVariations = Record<string, string>;

export const generateColorVariations = (baseColor: string, colorName: string): ColorVariations => {
  const variations: ColorVariations = {};

  const pattern = [900, 800, 700, 600, 500, 400, 300, 200, 100, 50];

  pattern.forEach((value) => {
    const variationName = `${colorName}-${value}`;
    const modifiedColor = modifyColor(baseColor, value);
    variations[variationName] = modifiedColor.toHexString();
  });

  return variations;
};

const modifyColor = (baseColor: string, value: number): tinycolor.Instance => {
  const color = tinycolor(baseColor);

  if (value === 600) return color;

  if (value < 600) {
    const lightnessFactor = Math.round((value * 40) / 600);
    return color.lighten(lightnessFactor);
  } else {
    const darkenessFactor = Math.round((value * 40) / 900);
    return color.darken(darkenessFactor);
  }
};
