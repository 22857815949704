const normalizeString = (value: string) => value.replace(/\s/g, '').toLowerCase();

export const filterByQuery = <T>(query: string, filterBy?: (keyof T)[], data?: T[]): T[] | undefined => {
  query = query.trim();

  if (!query || !filterBy) {
    return data;
  }

  return data?.filter((row) => {
    const filterField = (fieldValue: unknown) => {
      if (typeof fieldValue === 'string' && normalizeString(fieldValue).includes(normalizeString(query))) {
        return true;
      }

      if (Array.isArray(fieldValue)) {
        for (const item of fieldValue) {
          if (filterField(item)) return true;
        }
      }
    };

    for (const field of filterBy) {
      if (filterField(row[field])) {
        return true;
      }
    }

    return false;
  });
};
