import {Color} from '../color';

export function getColorShades(color?: string) {
  if (!color) return undefined;

  return {
    50: Color.setAlpha(color, 0.1),
    100: Color.setAlpha(color, 0.2),
    200: Color.setAlpha(color, 0.3),
    300: Color.setAlpha(color, 0.4),
    400: Color.setAlpha(color, 0.6),
    500: color,
    600: Color.darken(color, 10),
    700: Color.darken(color, 15),
    800: Color.darken(color, 20),
    900: Color.darken(color, 25),
  };
}
