import React, {FC, forwardRef} from 'react';

import {ResponsiveProps} from './models';
import {useResponsiveProps} from './utils';

export const withResponsiveProps = <T,>(Component: FC<T>): FC<ResponsiveProps<T>> => {
  return forwardRef((responsiveProps, ref) => {
    const props = useResponsiveProps(responsiveProps);
    return <Component {...(props as any)} ref={ref} />;
  }) as any;
};
