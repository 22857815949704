import createLucideIcon from '../createLucideIcon';

const GitGraph = createLucideIcon('GitGraph', [
  ['circle', { cx: '5', cy: '6', r: '3', key: '1qnov2' }],
  ['path', { d: 'M5 9v6', key: '158jrl' }],
  ['circle', { cx: '5', cy: '18', r: '3', key: '104gr9' }],
  ['path', { d: 'M12 3v18', key: '108xh3' }],
  ['circle', { cx: '19', cy: '6', r: '3', key: '108a5v' }],
  ['path', { d: 'M16 15.7A9 9 0 0 0 19 9', key: '1e3vqb' }],
]);

export default GitGraph;
