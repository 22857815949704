import createLucideIcon from '../createLucideIcon';

const LibrarySquare = createLucideIcon('LibrarySquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M7 7v10', key: 'd5nglc' }],
  ['path', { d: 'M11 7v10', key: 'pptsnr' }],
  ['path', { d: 'm15 7 2 10', key: '1m7qm5' }],
]);

export default LibrarySquare;
