import * as Calendar from 'expo-calendar';

import {CalendarEvent} from '../../components';
import {syncCalendarServicesKey} from '../../config';
import {cacheService} from '../../services';
import {appletNameFromId, nativeCalendarApi} from '../../utils';

export const syncCalendarEvents = async (calendarEvents: CalendarEvent[]) => {
  const {addEventsToCalendar, getPermission, createCalendar, getEvents, getCalendarId, deleteCalendarEvent} =
    nativeCalendarApi();

  const syncedCalendarServices: string[] | undefined = await cacheService.get(syncCalendarServicesKey);
  if (!syncedCalendarServices || syncedCalendarServices.length === 0) return;

  const granted = await getPermission();
  if (!granted) return;

  const calendarIdCache: Record<string, string | undefined> = {};

  for (const appletId of syncedCalendarServices) {
    const startDate = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
    const endDate = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);
    const nativeCalendarEvents = await getEvents(appletId, startDate, endDate);

    if (!nativeCalendarEvents) {
      const widgetSource = appletNameFromId(appletId);
      await createCalendar(widgetSource, appletId);
    } else {
      for (const nativeEvent of nativeCalendarEvents) {
        const eventExists = calendarEvents.find(
          (existingEvent) =>
            existingEvent.title === nativeEvent.title &&
            existingEvent.start === nativeEvent.startDate &&
            existingEvent.end === nativeEvent.endDate,
        );
        if (!eventExists) {
          await deleteCalendarEvent(appletId, nativeEvent.id);
        }
      }
    }

    if (!calendarIdCache[appletId]) {
      calendarIdCache[appletId] = await getCalendarId(appletId);
    }
    const calendarId = calendarIdCache[appletId];

    if (calendarId) {
      for (const e of calendarEvents) {
        const widgetSource = appletNameFromId(appletId);

        if (e.appletId === appletId) {
          const event: Partial<Calendar.Event> = {
            title: e.title,
            creationDate: e.date,
            startDate: e.start,
            endDate: e.end,
            timeZone: 'Local',
            notes: e.description,
            calendarId: calendarId,
            instanceId: e.id,
            organizer: widgetSource,
            id: e.id,
            originalId: e.id,
          };
          await addEventsToCalendar(event, appletId);
        }
      }
    }
  }
};
