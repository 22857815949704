import React from 'react';

import {useResponsiveValue} from '../../utils/withResponsiveProps';

import {DesktopCalendar} from './components/DesktopCalendar';
import {MobileCalendar} from './components/MobileCalendar';
import {CalendarProvider} from './context/CalendarContext';
import {CalendarProps} from './types';

export * from './types';

export const Calendar = ({
  events,
  calendarHeader,
  calendarRef,
  eventContent,
  weekStartDay = 0,
  noTitle = false,
  withPrint = false,
}: CalendarProps) => {
  const Calendar = useResponsiveValue({$sm: MobileCalendar, $md: DesktopCalendar});

  return (
    <CalendarProvider value={{events, weekStartDay}}>
      <Calendar
        eventContent={eventContent}
        calendarHeader={calendarHeader}
        calendarRef={calendarRef}
        noTitle={noTitle}
        withPrint={withPrint}
      />
    </CalendarProvider>
  );
};
