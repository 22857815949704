import React, {useRef, useEffect} from 'react';

import SvgChart, {SVGRenderer} from '@wuba/react-native-echarts/svgChart';
import * as echarts from 'echarts';
import * as echartsCharts from 'echarts/charts';
import * as echartsComponents from 'echarts/components';
import * as echartsCore from 'echarts/core';

import {useAppTheme} from '../../context';

export const echart = {echartsCore, echarts, echartsComponents, echartsCharts, SVGRenderer, SvgChart};

echartsCore.use([
  echartsComponents.TitleComponent,
  echartsComponents.TooltipComponent,
  echartsComponents.GridComponent,
  SVGRenderer,
  echartsCharts.LineChart,
  echartsCharts.BarChart,
  echartsCharts.PieChart,
  echartsCharts.ScatterChart,
]);

export type ChartProps = {
  option: any;
  width?: number;
  height?: number;
  onInit?: (chartRef: React.MutableRefObject<any>) => void;
};

export const Chart = ({option, width = 300, height = 300, onInit}: ChartProps) => {
  const chartRef = useRef<any>(null);
  const {theme} = useAppTheme();

  useEffect(() => {
    let chart: any;
    if (chartRef.current) {
      chart = echartsCore.init(chartRef.current, theme === 'dark' ? 'dark' : 'light', {
        renderer: 'svg',
        width,
        height,
      });
      chart.setOption(option);
      onInit?.(chartRef);
    }
    return () => chart?.dispose();
  }, [option]);

  return <SvgChart ref={chartRef} />;
};
