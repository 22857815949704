import {isDefined} from '../isDefined';

export function filterUndefined(obj: Record<string, any>) {
  const res = {...obj};

  Object.keys(res).forEach((key) => {
    if (!isDefined(res[key])) {
      delete res[key];
    }
  });

  return res;
}
