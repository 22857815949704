import {AlertStatusConfig} from '../types';

export const AlertTypeConfig: AlertStatusConfig = {
  info: {
    color: '$blue-400',
    icon: 'AlertCircle',
    backgroundColor: '$blue-200',
    lightColor: '$blue-50',
  },
  success: {
    color: '$green-400',
    icon: 'Check',
    backgroundColor: '$green-100',
    lightColor: '$green-50',
  },
  danger: {
    color: '$red-400',
    icon: 'AlertOctagon',
    backgroundColor: '$red-100',
    lightColor: '$red-50',
  },
  warning: {
    color: '$amber-400',
    icon: 'AlertTriangle',
    backgroundColor: '$amber-100',
    lightColor: '$amber-50',
  },
};
