import {useMemo, useState} from 'react';

import {Column} from '../model';

export interface ColumnsSelector<T> {
  showAllColumns: () => void;
  isColumnVisible: (column: Column<T>) => boolean;
  onFilterColumns: (column: Column<T>, checked: boolean) => void;
  showOneColumn: (column: Column<T>) => void;
  displayedColumns: Column<T>[];
}

export function useColumnsSelector<T>(columns: Column<T>[]): ColumnsSelector<T> {
  const [hiddenColumns, setHiddenColumns] = useState(new Set<keyof T>());

  const showAllColumns = () => setHiddenColumns(new Set());

  const showOneColumn = (column: Column<T>) =>
    setHiddenColumns(() => new Set(columns.filter((col) => col.field !== column.field).map((col) => col.field)));

  const isColumnVisible = (column: Column<T>) => !hiddenColumns.has(column.field);

  const onFilterColumns = (column: Column<T>, checked: boolean) => {
    setHiddenColumns((prev) => {
      checked ? prev.delete(column.field) : prev.add(column.field);
      return new Set(prev);
    });
  };

  const displayedColumns = useMemo(() => columns.filter(isColumnVisible), [columns, hiddenColumns]);

  return {displayedColumns, onFilterColumns, showAllColumns, isColumnVisible, showOneColumn};
}
