type Callback = (args: any) => void;

type Listener = {
  eventName: string;
  cb: Callback;
};

export class EventHub {
  listeners: Listener[] = [];

  addListener = (eventName: string, cb: Callback) => {
    this.listeners.push({eventName, cb});
    const index = this.listeners.length - 1;
    return () => this.listeners.splice(index, 1);
  };

  emit = (eventName: string, data: any) => {
    this.listeners.forEach((listener) => {
      if (listener.eventName === eventName) {
        listener.cb(data);
      }
    });
  };
}
