export const parseToCamelCase = (str: string) => {
  return str
    .split(/[ _-]/g)
    .map((word, index) => {
      word = word.toLowerCase();

      if (index) {
        word = word[0].toUpperCase() + word.slice(1);
      }

      return word;
    })
    .join('');
};
