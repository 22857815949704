import createLucideIcon from '../createLucideIcon';

const BookA = createLucideIcon('BookA', [
  [
    'path',
    {
      d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20',
      key: 't4utmx',
    },
  ],
  ['path', { d: 'm8 13 4-7 4 7', key: '4rari8' }],
  ['path', { d: 'M9.1 11h5.7', key: '1gkovt' }],
]);

export default BookA;
