import React from 'react';

import {Trans} from 'react-i18next';

import {BuilderPropsGroup, LocalizedTextType, PropsBuilder} from '../../../../types';
import {coreScopedKey, usePropsByViewPortSize} from '../../../../utils';
import {Input} from '../../../Input';
import {XStack} from '../../../XStack';
import {EditSectionElement} from '../../components';
import {PropsBuilderContainer} from '../../components/PropsBuilderContainer';

import {WebViewElementType} from './index';

export const _WebViewPropsBuilder: PropsBuilder<WebViewElementType> = ({value: propsValue, onChange}) => {
  const {uri: url} = usePropsByViewPortSize(propsValue);

  const onUpdateProps = (key: string, value: number | string | LocalizedTextType) => {
    onChange(key, value);
  };

  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<Trans i18nKey="settings.basic.url" />}>
        <XStack alignItems="center" justifyContent="space-between">
          <Input value={url} onChangeText={(value) => onUpdateProps('uri', value)} width="80%" />
        </XStack>
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};

export const WebViewPropsBuilder: BuilderPropsGroup<WebViewElementType> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.WebViewElement'),
      Content: _WebViewPropsBuilder,
    },
  ],
};
