import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../types';
import {coreScopedKey} from '../../../../utils';
import {View, ViewProps} from '../../../View';
import {LayoutPropsBuilder} from '../../components';

export type ColumnElementProps = ViewProps;

const defaultProps: ColumnElementProps = {
  position: 'relative',
  flexDirection: 'column',
  borderRadius: 4,
  paddingHorizontal: 3,
  maxWidth: '100%',
  paddingBottom: 3,
  padding: 30,
  flexWrap: 'wrap',
  gap: 6,
  $md: {
    flexWrap: 'wrap',
  },
  $sm: {
    flexWrap: 'wrap',
  },
};

export const Component = ({children, ...props}: ColumnElementProps, ref) => {
  return (
    <View {...props} ref={ref}>
      {children}
    </View>
  );
};

export const ColumnComponent = forwardRef(Component);

export const ColumnElement: BlockDefinition<ColumnElementProps> = {
  name: coreScopedKey('blocks.ColumnElement'),
  version: 1,
  id: 'ColumnElement',
  children: [],
  props: defaultProps,
  Component: ColumnComponent,
  category: BlockCategory.LAYOUT,
  propsBuilder: [LayoutPropsBuilder],
  Icon: 'Rows',
};
