import {useQuery} from 'react-query';

import {useAPI} from '../services/api';

export type UserSettings = {
  calendar: {
    showInCalendar?: string[];
    syncedCalendarServices?: string[];
  };
  selectedRoles?: Record<string, string | null>;
};

export function useUserSettings() {
  const API = useAPI();

  const {data, ...rest} = useQuery(['user-settings/mySettings'], () =>
    API.get('user-settings/mySettings').json<{settings: UserSettings}>(),
  );

  return {userSettings: data?.settings, ...rest};
}
