import React from 'react';

import {IconType} from '../../../types';
import {BidirectionalIcon} from '../../BidirectionalIcon';
import {Pressable} from '../../Pressable';
import {ViewProps} from '../../View';

type Props = {
  icon: IconType;
  onPress: () => void;
  isHidden?: boolean;
};

export const CarouselButton = ({icon, onPress, isHidden}: Props & ViewProps) => {
  const onPressfunction = isHidden ? () => {} : onPress;
  return (
    <Pressable
      borderColor="$border"
      borderWidth={1}
      borderRadius={10}
      padding="$2"
      opacity={isHidden ? 0.4 : 1}
      onPress={onPressfunction}
      cursor="pointer">
      <BidirectionalIcon icon={icon} size={30} color="$neutral-600" />
    </Pressable>
  );
};
