import {
  AppContentPage,
  Applet,
  AppletWidget,
  AppPage,
  AppStructure,
  Block,
  isAppContentPage,
  isAppServicePage,
  isBuilderWidgetElement,
  isLeafElement,
  BlockDefinition,
} from '../../types';
import {createAppServicePage} from '../appStructureFactory';

export function processAppStructure(
  structure: AppStructure,
  enabledApplets: Applet[],
  commonElements: {[key: string]: any},
  showAllWidgets = false,
  isAuthenticated = false,
) {
  const enabledAppletsMap = new Map<string, Applet>();
  enabledApplets.forEach((applet) => enabledAppletsMap.set(applet.id, applet));

  addMissingServices(structure.pages, enabledApplets);

  structure.pages = escapePages(structure.pages, enabledAppletsMap, commonElements, showAllWidgets, isAuthenticated);

  Object.values(structure.auxiliaryPages).forEach((group) => {
    group.pages = group.pages.filter((page) =>
      escapeContentPageDisabledWidgets(page, enabledAppletsMap, commonElements, showAllWidgets, isAuthenticated),
    );
  });

  Object.values(structure.launcher).forEach((launcherPage) => {
    launcherPage.children &&
      escapeContentPageDisabledWidgets(
        launcherPage,
        enabledAppletsMap,
        commonElements,
        showAllWidgets,
        isAuthenticated,
      );
  });

  return structure;
}

function addMissingServices(pages: AppPage[], enabledApplets: Applet[]) {
  const alreadyAdded = new Set();
  pages.forEach((page) => isAppServicePage(page) && alreadyAdded.add(page.serviceId));

  enabledApplets.forEach((applet) => {
    applet.services.forEach((service) => {
      if (!alreadyAdded.has(service.id)) {
        pages.push(createAppServicePage(applet.id, service.id));
        alreadyAdded.add(service.id);
      }
    });
  });
}

function escapePages(
  pages: AppPage[],
  enabledAppletsMap: Map<string, Applet>,
  commonElements: {[key: string]: any},
  showAllWidgets = false,
  isAuthenticated = false,
): AppPage[] {
  return pages.filter((page) => {
    if (isAppServicePage(page)) {
      const {appletId, serviceId} = page;
      const applet = enabledAppletsMap.get(appletId);

      const service = applet?.services.find(({id}) => id === serviceId);
      if (!applet || !service) return false;

      if (showAllWidgets) return true;

      return isAuthenticated && applet.canView ? applet.canView(service, applet._context as any) : service.public;
    }
    if (isAppContentPage(page)) {
      if (!page.children) return false;
      if (!page.public && !showAllWidgets && !isAuthenticated) return false;
      return escapeContentPageDisabledWidgets(page, enabledAppletsMap, commonElements, showAllWidgets, isAuthenticated);
    }
  });
}

function escapeContentPageDisabledWidgets(
  page: AppContentPage,
  enabledAppletsMap: Map<string, Applet>,
  commonElements: {[key: string]: BlockDefinition},
  showAllWidgets = false,
  isAuthenticated = false,
): boolean {
  const isWidgetEnabled = (widgetElement: AppletWidget): boolean => {
    const applet = enabledAppletsMap.get(widgetElement.appletId);
    const appletIsDisabled = !enabledAppletsMap.has(widgetElement.appletId);
    const widget = applet?.widgets.find(({id}) => id === widgetElement.id);

    if (appletIsDisabled) return false;
    if (showAllWidgets) return true;

    if (applet && widget) {
      return !!(isAuthenticated ? applet?.canView?.(widget, applet._context as any) : !!widget.public);
    }
    return false;
  };

  const traverseNode = (node: Block): boolean | undefined => {
    const element = commonElements[node.definitionId];

    if (isLeafElement(node)) {
      if (isBuilderWidgetElement(element)) return isWidgetEnabled(element);
      return true;
    }

    if (node.children && node.children.length) {
      node.children = node.children.filter(traverseNode);
    }

    return node.children && node.children.length > 0;
  };

  if (page && page?.children?.length) {
    page.children.filter((child) => traverseNode(child));
  }

  return page?.children?.length > 0;
}
