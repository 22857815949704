// todo: add docs
import React, {useContext} from 'react';

import {
  ApiConfig,
  ApiConnector,
  AppConfig,
  Applet,
  AppStructure,
  ContentElementsResolver,
  EnvironmentProfile,
} from '../types';
import {EventHub} from '../utils';

export interface AppContextValue {
  applets: Applet[];
  contentElementsResolver: ContentElementsResolver;
  appConfig: AppConfig;
  appStructure: AppStructure;
  apiConfig: ApiConfig;
  isDemoMode?: boolean;
  appEventHub?: EventHub;
  connectors?: ApiConnector[];
}

export const appContextDefaultValue = {
  applets: [],
  connectors: [],
  contentElementsResolver: {},
  appConfig: {
    primaryColor: 'black',
    accentColor: 'black',
  },
  appStructure: {
    launcher: {},
    pages: [],
    auxiliaryPages: [],
  },
  apiConfig: {
    baseApiUrl: '',
    tenant: '',
    environmentProfile: EnvironmentProfile.DRAFT,
  },
  isDemoMode: false,
};

const AppContext = React.createContext<AppContextValue>(appContextDefaultValue);

export const AppContextProvider = AppContext.Provider;

export const useAppletsList = () => {
  return useContext(AppContext).applets;
};

export const useContentElementsResolver = () => {
  return useContext(AppContext).contentElementsResolver;
};

export const useAppStructure = () => {
  return useContext(AppContext).appStructure;
};

export const useAppConfig = () => {
  return useContext(AppContext)?.appConfig;
};

export const useAppConnectors = () => {
  return useContext(AppContext)?.connectors;
};

export const useApiConfig = () => {
  return useContext(AppContext).apiConfig;
};

export const useIsDemoMode = (): boolean => {
  return !!useContext(AppContext).isDemoMode;
};

export const useAppEventHub = () => {
  return useContext(AppContext).appEventHub;
};
