export const ButtonTypeColors = {
  neutral: {
    default: '$neutral-600',
    hover: '$neutral-700',
    loading: '$neutral-400',
    outlineLoading: '$neutral-50',
  },
  // todo : change accent variantions after adding the generate colors variations utility to the core .
  primary: {
    default: '$accent',
    hover: '$accent',
    loading: '$accent',
    outlineLoading: '$accent',
  },
  info: {
    default: '$blue-600',
    hover: '$blue-700',
    loading: '$blue-400',
    outlineLoading: '$blue-50',
  },
  danger: {
    default: '$red-600',
    hover: '$red-700',
    loading: '$red-400',
    outlineLoading: '$red-50',
  },
  warning: {
    default: '$amber-600',
    hover: '$amber-700',
    loading: '$amber-400',
    outlineLoading: '$amber-50',
  },
  success: {
    default: '$green-600',
    hover: '$green-700',
    loading: '$green-400',
    outlineLoading: '$green-50',
  },
};
