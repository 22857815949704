import React, {forwardRef} from 'react';

import {ImageProps} from 'tamagui';

import {BlockDefinition, BlockCategory} from '../../../../types';
import {coreScopedKey} from '../../../../utils';
import {Image} from '../../../Image';
import {View} from '../../../View';
import {LayoutPropsBuilder} from '../../components';

import {ImagePropsBuilder} from './PropsBuilder';

export type ImageElementProps = ImageProps & {
  uri: string;
};

const defaultProps: ImageElementProps = {
  maxHeight: 300,
  minHeight: 300,
  height: 300,
  minWidth: 250,
  uri: 'https://bgr.com/wp-content/uploads/2022/03/AdobeStock_194080021.jpeg?quality=82&strip=all',
  borderRadius: 0,
  resizeMode: 'cover',
  flex: 1,
};

export const Component = (
  {uri, resizeMode, borderRadius, borderWidth, borderColor, ...props}: ImageElementProps,
  ref,
) => {
  return (
    <View {...props} ref={ref}>
      <Image
        source={{uri}}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        borderColor={borderColor}
        resizeMode={resizeMode}
        width="100%"
        height="100%"
      />
    </View>
  );
};
export const ImageComponent = forwardRef(Component);

export const ImageElement: BlockDefinition<ImageElementProps> = {
  name: coreScopedKey('blocks.ImageElement'),
  version: 1,
  id: 'ImageElement',
  props: defaultProps,
  category: BlockCategory.NODE,
  Component: ImageComponent,
  Icon: 'Image',
  propsBuilder: [ImagePropsBuilder, LayoutPropsBuilder],
};
