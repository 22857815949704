import createLucideIcon from '../createLucideIcon';

const FolderKey = createLucideIcon('FolderKey', [
  ['circle', { cx: '16', cy: '20', r: '2', key: '1vifvg' }],
  [
    'path',
    {
      d: 'M10 20H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H20a2 2 0 0 1 2 2v2',
      key: '3hgo9p',
    },
  ],
  ['path', { d: 'm22 14-4.5 4.5', key: '1ef6z8' }],
  ['path', { d: 'm21 15 1 1', key: '1ejcpy' }],
]);

export default FolderKey;
