import React, {ComponentProps, forwardRef} from 'react';

import {Text as BaseText} from 'tamagui';

export type TextProps = ComponentProps<typeof BaseText>;

export function _Text({fontFamily = '$body', ...props}: TextProps, ref) {
  return <BaseText ref={ref} fontFamily={fontFamily} {...props} />;
}

export const Text = forwardRef(_Text);
