import {useIsAuthenticated} from '../../context/AuthContext';

interface Props {
  children: any;
}

export const AuthenticationGuard = ({children}: Props) => {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? children : null;
};
