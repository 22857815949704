import React from 'react';

import {Separator as BaseSeparator} from 'tamagui';

import {ViewProps} from '../View';

export type SeparatorProps = ViewProps & {
  vertical?: boolean;
};

export function Separator({vertical = false, ...props}: SeparatorProps) {
  return <BaseSeparator vertical={vertical} {...props} backgroundColor="$border" />;
}
