import {useApiConfig, useIsDemoMode} from '../../context/AppContext';

import {createMockApi} from './createMockApi';
import {useAppBaseAPI} from './useAppBaseAPI';

export const useAPI = (mockedResponse?: any, mockedStatus = 200, waitTime = 500) => {
  const {baseApiUrl, tenant} = useApiConfig();
  const instance = useAppBaseAPI(baseApiUrl, tenant);
  const isDemoMode = useIsDemoMode();

  if (isDemoMode && mockedResponse) {
    return createMockApi(mockedResponse, mockedStatus, waitTime);
  }

  return instance;
};

export type ApiInstance = ReturnType<typeof useAPI>;
