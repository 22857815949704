import {CalendarEvent} from '../../../components/Calendar';

class CalendarService {
  private events: CalendarEvent[] = [];

  registerEvents(events: CalendarEvent[]) {
    this.events = [...this.events, ...events];
  }

  getEvents(): CalendarEvent[] {
    return this.events;
  }

  clearEvents() {
    this.events = [];
  }
}

export class ScopedCalendarService {
  scopedId: string;

  constructor(scopedId: string) {
    this.scopedId = scopedId;
  }
  registerEvents(events: CalendarEvent[]) {
    events.forEach((e) => {
      e.appletId = this.scopedId;
    });

    calendarService.registerEvents(events);
  }
}

// todo: dont expose outside of core
export const calendarService = new CalendarService();
