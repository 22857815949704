import createLucideIcon from '../createLucideIcon';

const Armchair = createLucideIcon('Armchair', [
  ['path', { d: 'M19 9V6a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v3', key: 'irtipd' }],
  [
    'path',
    {
      d: 'M3 16a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5a2 2 0 0 0-4 0v2H7v-2a2 2 0 0 0-4 0Z',
      key: '1e01m0',
    },
  ],
  ['path', { d: 'M5 18v2', key: 'ppbyun' }],
  ['path', { d: 'M19 18v2', key: 'gy7782' }],
]);

export default Armchair;
