import React, {useEffect, useState} from 'react';

import {useIsFocused} from '@react-navigation/native';
import {BarCodeScanner} from 'expo-barcode-scanner';
import {Camera} from 'expo-camera';
import {CameraProps} from 'expo-camera/build/Camera.types';
import {StyleSheet} from 'react-native';

import {Spinner} from '../Spinner';
import {View} from '../View';

export const BarcodeScanner = ({barCodeScannerSettings, ...props}: CameraProps) => {
  const isFocused = useIsFocused();

  const [hasPermission, setHasPermission] = useState(false);

  const requestPermissions = async () => {
    const {status} = await BarCodeScanner.requestPermissionsAsync();
    if (status === 'granted') {
      setHasPermission(true);
    } else {
      requestAnimationFrame(() => requestPermissions());
    }
  };

  useEffect(() => {
    requestPermissions();
  }, []);

  if (!hasPermission || !isFocused) {
    return (
      <View alignItems="center" justifyContent="center" padding={5}>
        <Spinner />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Camera
        style={styles.camera}
        ratio="16:9"
        barCodeScannerSettings={{
          barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
          ...(barCodeScannerSettings || {}),
        }}
        {...props}
      />

      <View style={styles.focusContainer}>
        <View style={styles.focus} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  camera: {
    flex: 1,
  },
  focusContainer: {
    flex: 1,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  focus: {
    height: 300,
    width: 300,
    borderColor: 'green',
    borderWidth: 2,
  },
});
