import React from 'react';

import Animated, {useAnimatedProps, useSharedValue, withTiming} from 'react-native-reanimated';
import {Circle, Svg} from 'react-native-svg';
import {ColorTokens} from 'tamagui';

import {useTokenColor} from '../../utils/useTokenColor';
import {Typography} from '../Typography';
import {XStack} from '../XStack';

export type CircularProgressProps = {
  value: number;
  size?: number;
  strokeWidth?: number;
  backgroundColor?: ColorTokens;
  color?: ColorTokens;
  hidePercentage?: boolean;
};

/**
 * A circular progress component that displays a percentage value.
 *
* Params:
* @param value - The progress value, ranging from 0 to 100.
* @param size - The size of the circular progress component.
* @param strokeWidth - The width of the progress stroke.
* @param hidePercentage - Whether to hide the percentage label.
* @param backgroundColor - The background color of the circular progress.
* @param color - The color of the progress stroke.

 * @component
 * @example
 * ```tsx
 * <CircularProgress value={50}  />
 * ```
 */
export const CircularProgress: React.FC<CircularProgressProps> = ({
  value = 0,
  size = 18,
  strokeWidth = 2,
  hidePercentage = false,
  backgroundColor = '$neutral-200',
  color = '$green-600',
}) => {
  const radius = (size - strokeWidth) / 2;
  const circum = radius * 2 * Math.PI;
  const progress = Math.min(Math.abs(value), 100);
  const svgProgress = 100 - progress;

  const progressShared = useSharedValue(svgProgress);
  progressShared.value = withTiming(svgProgress);

  const AnimatedCircle = Animated.createAnimatedComponent(Circle);

  const animatedProps = useAnimatedProps(() => {
    return {
      strokeDashoffset: radius * Math.PI * 2 * (progressShared.value / 100),
    };
  });

  const ColorValue = useTokenColor(color);
  const BackgroundColorValue = useTokenColor(backgroundColor);

  return (
    <XStack gap="$6" alignItems="center">
      <Svg width={size} height={size}>
        <Circle
          stroke={BackgroundColorValue}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
        />

        <AnimatedCircle
          animatedProps={animatedProps}
          stroke={ColorValue}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDasharray={`${circum} ${circum}`}
          strokeLinecap="round"
          transform={`rotate(-90, ${size / 2}, ${size / 2})`}
          strokeWidth={strokeWidth}
        />
      </Svg>
      {!hidePercentage && (
        <Typography variant="body2" color={ColorValue}>
          {progress} %
        </Typography>
      )}
    </XStack>
  );
};
