import { shorthands } from "@tamagui/shorthands";
import { themes, tokens } from "@tamagui/themes";
import { fonts } from "./fonts.mjs";
import { media, mediaQueryDefaultActive } from "./media.mjs";
const configWithoutAnimations = {
  defaultFont: "body",
  shouldAddPrefersColorThemes: !0,
  themeClassNameOnRoot: !0,
  themes,
  media,
  shorthands,
  tokens,
  fonts,
  mediaQueryDefaultActive,
  selectionStyles: theme => theme.color5 ? {
    backgroundColor: theme.color5,
    color: theme.color11
  } : null
};
export { configWithoutAnimations };