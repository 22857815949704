import {size, zIndex, space as spaceValues, color} from '@tamagui/themes';
import {createTokens} from 'tamagui';

import {RADIUS_TOKENS, SPACE_TOKENS} from './constant';

export const space = {...spaceValues, ...SPACE_TOKENS};

export const tokens = createTokens({
  color,
  space,
  size,
  radius: RADIUS_TOKENS,
  zIndex,
});
