import React from 'react';

import * as icons from 'lucide-react-native';

import {IconProps} from '../../types';

import {IconsList} from './IconsList';
export * from './IconsList';

export type IconListType = typeof IconsList[number];

type Props = IconProps & {
  name: IconListType;
  fill?: string;
};

export const Icon = ({name, size = 16, ...props}: Props) => {
  const SelectedIcon = icons[name] as any;
  if (!SelectedIcon) return null;

  return <SelectedIcon strokeWidth={1.8} size={size} {...props} />;
};
