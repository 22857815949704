// todo: add docs
import React, {useState, useContext, ReactNode, useEffect} from 'react';

import {useColorScheme} from 'react-native';

import {cacheService} from '../services';
import {Theme} from '../types';

const THEME_KEY = '@APP_THEME';

export interface AppThemeContextValue {
  theme: Theme | null;
  setTheme: (theme: Theme) => void;
}

export const appThemeContextValue = {
  theme: Theme.light,
  setTheme: () => {},
};

const AppTheme = React.createContext<AppThemeContextValue>(appThemeContextValue);

type AppContextProviderType = {
  children: ReactNode;
};

export const AppThemeContext = ({children}: AppContextProviderType) => {
  const colorScheme = useColorScheme();
  const [theme, setTheme] = useState<Theme | null>(null);

  const onThemeChange = (theme: Theme) => {
    setTheme(theme);
    cacheService.set(THEME_KEY, theme);
  };

  useEffect(() => {
    const loadThemePrefrence = async () => {
      const currentTheme = await cacheService.get<Theme>(THEME_KEY);
      const osThemePreference = colorScheme === 'dark' ? Theme.dark : Theme.light;

      if (currentTheme) {
        setTheme(currentTheme);
      } else {
        cacheService.set(THEME_KEY, osThemePreference);
        setTheme(osThemePreference);
      }
    };

    loadThemePrefrence();
  }, []);

  return (
    <AppTheme.Provider
      value={{
        theme,
        setTheme: onThemeChange,
      }}>
      {!theme || children}
    </AppTheme.Provider>
  );
};

export const useAppTheme = () => {
  return useContext(AppTheme);
};
