import {useTranslation} from 'react-i18next';

import {useAppletContext} from '../../context';
import {appletScopedKey} from '../scopedKeys';

export const useAppletScopedTranslation = () => {
  const appletId = useAppletContext();
  const {t, ...rest} = useTranslation();

  return {
    t: ((key, options?) => t(appletScopedKey(appletId, key), options as any)) as typeof t,
    ...rest,
  };
};
