import React from 'react';

import {AuthContextProvider} from '../../context/AuthContext';

import {SSOConfig} from './models';
import {useCachedAuth} from './utils/useCachedAuth';
import {useSSOAuth} from './utils/useSSOAuth';

export * from './AuthenticationGuard';

interface Props {
  children: any;
  config: SSOConfig;
}

export const AuthProvider = ({children, config}: Props) => {
  const {isLoadedCache, authResponse, onAuthChange} = useCachedAuth();
  const {ready, login, logout, refreshToken, discovery} = useSSOAuth(config, onAuthChange, authResponse);

  if (!ready || !isLoadedCache) return null;

  return (
    <AuthContextProvider value={{config, authResponse, login, logout, refreshToken, discovery}}>
      {children}
    </AuthContextProvider>
  );
};
