import {useCallback} from 'react';

interface UseDayProps {
  date: Date;
  focusedDate: Date | null;
  isDateFocused(date: Date): boolean;
  isDateSelected(date: Date): boolean;
  isDateHovered(date: Date): boolean;
  isDateBlocked(date: Date): boolean;
  isFirstOrLastSelectedDate(date: Date): boolean;
  onDateSelect(date: Date): void;
  onDateHover(date: Date): void;
}

export function useDay({
  date,
  focusedDate,
  isDateSelected,
  isDateFocused,
  isFirstOrLastSelectedDate,
  isDateHovered,
  isDateBlocked,
  onDateSelect,
  onDateHover,
}: UseDayProps) {
  const onClick = useCallback(() => onDateSelect(date), [date, onDateSelect]);
  const onMouseEnter = useCallback(() => onDateHover(date), [date, onDateHover]);
  const disabled = isDateBlocked(date) && !isDateHovered(date);

  return {
    tabIndex: focusedDate === null || isDateFocused(date) ? 0 : -1,
    isSelected: isDateSelected(date),
    isSelectedStartOrEnd: isFirstOrLastSelectedDate(date),
    isWithinHoverRange: isDateHovered(date),
    disabledDate: disabled,
    onClick: disabled ? () => {} : onClick,
    onMouseEnter,
  };
}
