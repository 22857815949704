import React from 'react';

import {Typography} from '../Typography';
import {XStack} from '../XStack';

import {BadgeTypeConfig} from './config';
import {BadgeTypesConfig} from './types';

export * from './types';

export const Badge = ({label, prefix, suffix, type, color, ...props}: BadgeTypesConfig) => {
  const badgeColor = color || BadgeTypeConfig[type || '']?.color;

  return (
    <XStack
      minHeight={26}
      flex={0}
      borderColor={badgeColor || '$border'}
      borderWidth="$0.5"
      borderRadius="$rounded-full"
      alignItems="center"
      paddingHorizontal="$12"
      paddingVertical="$4"
      gap="$6"
      {...props}>
      <XStack marginHorizontal="$2">{prefix}</XStack>
      <Typography variant="body2" fontWeight="600" color={badgeColor || '$neutral-600'}>
        {label}
      </Typography>
      <XStack marginHorizontal="$2">{suffix}</XStack>
    </XStack>
  );
};
