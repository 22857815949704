import {createVariable} from 'tamagui';
import tinyColor from 'tinycolor2';

import {Theme} from '../../types';

import {generateColorVariations} from './utils/generateColorVariations';

import {TamaConfigType} from '.';

export const tintTamaguiTheme = (baseConfig: TamaConfigType, accent = 'gray') => {
  const accentLight = tinyColor(accent).setAlpha(0.2);

  /*tokens*/
  baseConfig.tokens.color['accent'] = accent as any;
  baseConfig.tokens.color['accentLight'] = accentLight as any;

  const accentVariations = generateColorVariations(accent, 'accent');

  for (const key in accentVariations) {
    baseConfig.tokens.color[key] = accentVariations[key] as any;
    baseConfig.themes[Theme.light][key] = createVariable({key, name: key, val: accentVariations[key]});
  }

  /* variables */

  /* light */
  baseConfig.themes[Theme.light].accent = createVariable({key: 'accent', name: 'accent', val: accent});
  baseConfig.themes[Theme.light].accentLight = createVariable({
    key: 'accentLight',
    name: 'accentLight',
    val: accentLight,
  });
  baseConfig.themes[Theme.light].backgroundHover = baseConfig.themes.light.accentLight;

  /* dark */
  baseConfig.themes[Theme.dark].accent = createVariable({key: 'accent', name: 'accent', val: accent});
  baseConfig.themes[Theme.dark].accentLight = createVariable({
    key: 'accentLight',
    name: 'accentLight',
    val: accentLight,
  });
  baseConfig.themes[Theme.dark].backgroundHover = baseConfig.themes.light.accentLight;
  baseConfig.themes[Theme.dark].highlightColor = createVariable({
    key: 'highlightColor',
    name: 'highlightColor',
    val: accent,
  });

  return baseConfig;
};
