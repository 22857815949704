import React from 'react';

import {Checkbox} from '../../../Checkbox';
import {IconView} from '../../../IconView';
import {Popover} from '../../../Popover';
import {Pressable} from '../../../Pressable';
import {CoreTrans} from '../../../ScopedTrans';
import {Typography} from '../../../Typography';
import {View} from '../../../View';
import {XStack} from '../../../XStack';
import {ColumnsSelector} from '../../hooks/useColumnsSelector';
import {TableProps} from '../../model';

interface Props<T> {
  columns: TableProps<T>['columns'];
  columnsSelector: ColumnsSelector<T>;
}
export function VisibleColumnsSelector<T>({columns, columnsSelector}: Props<T>) {
  return (
    <Popover
      contentProps={{padding: 0, borderRadius: 30, overflow: 'hidden'}}
      hideArrow
      closable
      trigger={
        <View
          borderWidth={1}
          borderColor="$border"
          borderRadius={30}
          paddingHorizontal="$10"
          paddingVertical="$10"
          flex={0}
          hoverStyle={{cursor: 'pointer', borderWidth: 1, backgroundColor: '$accentLight'}}>
          <IconView icon="Columns" size={22} color="$neutral-500" />
        </View>
      }>
      <XStack
        paddingHorizontal="$14"
        borderRadius="$2"
        minHeight="$4"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="$neutral-200">
        <Typography marginHorizontal="$2">
          <CoreTrans i18nKey="columns" />
        </Typography>
        <Pressable onPress={() => columnsSelector.showAllColumns()}>
          <Typography color="$accent" marginHorizontal="$2">
            <CoreTrans i18nKey="showAllColumns" />
          </Typography>
        </Pressable>
      </XStack>

      {columns?.map((column, index) => {
        return (
          <XStack
            key={index}
            minWidth="$18"
            width="100%"
            alignItems="center"
            gap={10}
            paddingVertical={12}
            paddingHorizontal="$14">
            <XStack alignItems="center" gap="$8" flex={1}>
              <Checkbox
                checked={columnsSelector.isColumnVisible(column)}
                onCheckedChange={(checked) => columnsSelector.onFilterColumns(column, checked)}
              />
              <Typography>{column.label}</Typography>
            </XStack>

            <Pressable onPress={() => columnsSelector.showOneColumn(column)}>
              <Typography color="$accent">
                <CoreTrans i18nKey="showOnly" />
              </Typography>
            </Pressable>
          </XStack>
        );
      })}
    </Popover>
  );
}
