import {useMemo} from 'react';

import {useAppDirection} from '../useAppDirection';

// todo: fix real issue in tamagui when aliased
export const useDirectionAwareProps = (props: object): any => {
  const {isRTL} = useAppDirection();

  return useMemo(() => {
    const res = {};

    Object.entries(props).forEach(([key, value]) => {
      if (key.endsWith('Start')) {
        key = key.replace('Start', isRTL ? 'Right' : 'Left');
      } else if (key.endsWith('End')) {
        key = key.replace('End', isRTL ? 'Left' : 'Right');
      }

      res[key] = value;
    });

    return res;
  }, [props, isRTL]);
};
