import React, {useContext, useMemo} from 'react';

import {getConfig} from 'tamagui';

import {ResponsiveValue} from './models';

export const VirtualViewPort = React.createContext<keyof ResponsiveValue<any> | undefined>(undefined);

export const useVirtualViewPort = () => {
  return useContext(VirtualViewPort);
};

export const VirtualViewPortWidthMapper = ({width, children}) => {
  const {media} = getConfig();

  const matchedMedia = useMemo(() => {
    const {sm, md} = media;

    if (width < sm.maxWidth) return '$sm';
    if (width < md.maxWidth) return '$md';
    if (width > md.maxWidth) return '$lg';
  }, [width]);

  return <VirtualViewPort.Provider value={matchedMedia}>{children}</VirtualViewPort.Provider>;
};
