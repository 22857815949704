import React from 'react';

import {RadioGroup as BaseRadioGroup} from 'tamagui';

import {RadioGroupProps, RadioProps} from './types';

export const RadioGroup = ({defaultValue, children, ...props}: RadioGroupProps) => {
  return (
    <BaseRadioGroup defaultValue={defaultValue} name="form" {...props}>
      {children}
    </BaseRadioGroup>
  );
};

export const Radio = ({size, value}: RadioProps) => {
  const id = `radiogroup-${value}`;

  return (
    <BaseRadioGroup.Item value={value} id={id} size={size} borderColor="$border" backgroundColor="$transparent">
      <BaseRadioGroup.Indicator borderColor="$accent" borderWidth="$6" padding="$6" backgroundColor="$transparent" />
    </BaseRadioGroup.Item>
  );
};
