import {fromByteArray} from 'base64-js';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import {Platform} from 'react-native';

import {getFileExtensionFromBuffer} from '../getFileExtensionFromBuffer';

export const downloadFile = async (fileBuffer: ArrayBuffer, fileExtension?: string, fileName?: string) => {
  const uint8Array = new Uint8Array(fileBuffer);
  const usedFileName = fileName || generateRandomFileName();
  let extension = fileExtension;
  if (!extension) {
    extension = getFileExtensionFromBuffer(fileBuffer);
  }

  if (Platform.OS === 'web') {
    const blob = new Blob([uint8Array], {type: `application/${extension}`});
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${usedFileName}.${extension}`;
    downloadLink.click();

    URL.revokeObjectURL(downloadLink.href);
  } else {
    const base64Data = arrayBufferToBase64(fileBuffer);
    if (Platform.OS === 'android') {
      const permissions = await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync();

      if (permissions.granted) {
        const uri = await FileSystem.StorageAccessFramework.createFileAsync(
          permissions.directoryUri,
          usedFileName,
          `application/${extension}`,
        );
        await FileSystem.writeAsStringAsync(uri, base64Data, {
          encoding: FileSystem.EncodingType.Base64,
        });
      }
    } else {
      Sharing.shareAsync(base64Data);
    }
  }
};

function arrayBufferToBase64(arrayBuffer) {
  const byteArray = new Uint8Array(arrayBuffer);
  const base64 = fromByteArray(byteArray);
  return base64;
}

function generateRandomFileName() {
  const timestamp = new Date().getTime();
  const randomString = Math.random().toString(36).substring(2, 8);
  return `${timestamp}_${randomString}`;
}
