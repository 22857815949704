import React from 'react';

import {Video as ExpoVideo, VideoProps as ExpoVideoProps, ResizeMode} from 'expo-av';

export type VideoProps = ExpoVideoProps & {
  url?: string;
  resizeMode?: ResizeMode;
};

export const Video: React.FC<VideoProps> = ({url, ...props}) => {
  return (
    <ExpoVideo useNativeControls style={{width: '100%', height: '100%'}} {...(props as any)} source={{uri: url}} />
  );
};
