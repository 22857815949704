import createLucideIcon from '../createLucideIcon';

const FolderSearch = createLucideIcon('FolderSearch', [
  ['circle', { cx: '17', cy: '17', r: '3', key: '18b49y' }],
  [
    'path',
    {
      d: 'M10.7 20H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H20a2 2 0 0 1 2 2v4.1',
      key: '1bw5m7',
    },
  ],
  ['path', { d: 'm21 21-1.5-1.5', key: '3sg1j' }],
]);

export default FolderSearch;
