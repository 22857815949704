import createLucideIcon from '../createLucideIcon';

const GitFork = createLucideIcon('GitFork', [
  ['circle', { cx: '12', cy: '18', r: '3', key: '1mpf1b' }],
  ['circle', { cx: '6', cy: '6', r: '3', key: '1lh9wr' }],
  ['circle', { cx: '18', cy: '6', r: '3', key: '1h7g24' }],
  ['path', { d: 'M18 9v2c0 .6-.4 1-1 1H7c-.6 0-1-.4-1-1V9', key: '1uq4wg' }],
  ['path', { d: 'M12 12v3', key: '158kv8' }],
]);

export default GitFork;
