import {useMutation} from 'react-query';

import {useAPI} from '../services';
import {ApiConnector} from '../types';

export function editConnector(id?: string) {
  const API = useAPI();
  return useMutation<any, any, Partial<ApiConnector>>((value) =>
    API.patch(`connector-manager/connectors/${id}`, {json: value}),
  );
}
