import {useEffect, useState, RefObject} from 'react';

import FullCalendar from '@fullcalendar/react';

import {dayjs} from '../../../utils/extendedDayjs';
import {CalendarState, CalendarViewsType} from '../types';

export const initialState: CalendarState = {
  date: dayjs().toDate(),
  view: 'dayGridMonth',
};

export const useCalendarController = (calendarRef?: RefObject<FullCalendar>) => {
  const [{date, view}, setCalendarState] = useState(initialState);
  const calendarApi = calendarRef && calendarRef.current?.getApi();

  useEffect(() => {
    if (calendarApi) {
      setCalendarState((prev) => {
        return {...prev, date: calendarApi.getDate()};
      });
    }
  }, [calendarRef]);

  const changeView = (view: CalendarViewsType) => {
    calendarApi?.changeView(view);
    setCalendarState((prev) => ({...prev, date: dayjs().toDate(), view: view}));
  };

  const changeDate = (date: Date = dayjs().toDate()) => {
    calendarApi?.gotoDate(date);
    setCalendarState((prev) => ({...prev, date: dayjs(date).toDate()}));
  };

  return {
    changeView,
    changeDate,
    date,
    view,
  };
};
