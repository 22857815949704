import {useMemo} from 'react';

import {useMedia} from 'tamagui';

import {ViewportSizes} from '../../types';

import {useVirtualViewPort} from './context';
import {ResponsiveProps, ResponsiveValue} from './models';

export function getResponsiveValue<T>(
  value: ResponsiveValue<T>,
  media: ReturnType<typeof useMedia>,
  virtualViewPort?: keyof ResponsiveValue<any>,
) {
  const {$md, $sm, $lg} = value;

  const {md, sm} = virtualViewPort ? {md: virtualViewPort === '$md', sm: virtualViewPort === '$sm'} : media;

  if ($sm !== undefined && sm) return $sm;
  if ($md !== undefined && md) return $md;

  return $lg ?? $md ?? $sm ?? value;
}

export function useResponsiveValue<T = Record<string, any>>(value?: ResponsiveValue<T> | T): T {
  const virtualViewPort = useVirtualViewPort();
  const media = useMedia();

  if (!value) return value as any;

  return getResponsiveValue(value, media, virtualViewPort) as T;
}

// [enhance] debounce
export function useResponsiveProps<T>(values?: ResponsiveProps<T>): T {
  const virtualViewPort = useVirtualViewPort();
  const media = useMedia();

  return useMemo(() => {
    if (!values) return values;

    const props = {};

    Object.entries(values).forEach(([key, value]) => {
      props[key] = getResponsiveValue(value as any, media, virtualViewPort);
    });

    return props as any;
  }, [virtualViewPort, media, values]);
}

export const usePropsByViewPortSize = <T = Record<string, any>>(props: T = {} as T): T => {
  const virtualViewPort = useVirtualViewPort();

  return useMemo(() => {
    let result = {...props};
    if (virtualViewPort && result[virtualViewPort]) {
      result = {...result, ...result[virtualViewPort]};

      delete result[ViewportSizes.MOBILE];
      delete result[ViewportSizes.TABLET];
    }

    return result;
  }, [virtualViewPort, props]);
};
