import React, {ComponentProps, forwardRef} from 'react';

import {Text as BaseText} from 'tamagui';

type TextProps = ComponentProps<typeof BaseText>;

export type TypographyVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2' | 'caption';

export type TypographyFontWeight = '400' | '500' | '600' | '700';

export type TypographyProps = TextProps & {
  variant?: TypographyVariants;
  fontWeight?: TypographyFontWeight;
};

const variantMap: Record<TypographyVariants, TextProps['fontSize']> = {
  h1: '$72',
  h2: '$60',
  h3: '$48',
  h4: '$36',
  h5: '$24',
  h6: '$20',
  body1: '$16',
  body2: '$14',
  caption: '$12',
};

export function _Typography({variant = 'body1', fontWeight = '500', ...props}: TypographyProps, ref) {
  return <BaseText ref={ref} fontFamily="$body" fontSize={variantMap[variant]} fontWeight={fontWeight} {...props} />;
}

/**
 * The `Typography` component used to display text UI across the SEP core projects , it uses `Text` component from [tamagui](https://tamagui.dev/) .
 */
export const Typography = forwardRef(_Typography);
