import {Applet, AppletWidget} from '../../types/Applet';

export function buildAppletsEditorResolver(applets: Applet[]): Record<string, AppletWidget> {
  const resolver: any = {};

  for (let i = 0; i < applets.length; i++) {
    const applet = applets[i];
    applet.widgets.forEach((widget) => {
      resolver[widget.id] = widget;
    });
  }

  return resolver;
}
