import createLucideIcon from '../createLucideIcon';

const BookOpenText = createLucideIcon('BookOpenText', [
  ['path', { d: 'M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z', key: 'vv98re' }],
  ['path', { d: 'M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z', key: '1cyq3y' }],
  ['path', { d: 'M6 8h2', key: '30oboj' }],
  ['path', { d: 'M6 12h2', key: '32wvfc' }],
  ['path', { d: 'M16 8h2', key: 'msurwy' }],
  ['path', { d: 'M16 12h2', key: '7q9ll5' }],
]);

export default BookOpenText;
