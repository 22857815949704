import React from 'react';

import {useTranslation} from 'react-i18next';
import {Stack, XStack} from 'tamagui';

import {dayjs} from '../../../utils/extendedDayjs';
import {coreScopedKey} from '../../../utils/scopedKeys';
import {BidirectionalIcon} from '../../BidirectionalIcon';
import {Button} from '../../Button';
import {getLocalizedText} from '../../LocalizedText';
import {Typography} from '../../Typography';
import {View} from '../../View';
import {hijriMonths} from '../config';
import {useDatepickerContext} from '../context/DatepickerProvider';
import {useMonth, UseMonthProps} from '../hooks';
import {CalendarType} from '../models';

import {Day} from './Day';

type Props = UseMonthProps & {
  monthsCount: number;
  isFirstMonth: boolean;
  isLastMonth: boolean;
  type: CalendarType;
  date: Date;
};

export function DatepickerContent({type, monthsCount, isFirstMonth, isLastMonth, date, ...props}: Props) {
  const datepickerContext = useDatepickerContext();
  const {t, i18n} = useTranslation();

  const {
    goToPreviousYear,
    goToPreviousMonths,
    goToPreviousMonthsByOneMonth,
    goToNextMonthsByOneMonth,
    goToNextMonths,
    goToNextYear,
  } = datepickerContext;

  const {days, weekdayLabels, monthLabel} = useMonth({
    ...props,
    monthLabelFormat(date: Date) {
      if (type === 'gregory') {
        return dayjs(date).format('MMMM');
      }
      const hijriMonth = hijriMonths[+dayjs(date).calendar('hijri').format('MM') - 1];
      return getLocalizedText({value: coreScopedKey(`hidjriMonths.${hijriMonth}`), t, i18n});
    },
    dayLabelFormat(date) {
      return dayjs(date).calendar(type).format('DD');
    },
    weekdayLabelFormat(date) {
      return dayjs(date).format('ddd');
    },
  });

  return (
    <View width={340} $xs={{height: 340}}>
      <XStack space="$2" justifyContent="center" alignItems="center">
        <Button
          borderWidth={0}
          variant="outlined"
          size="$2"
          focusable={false}
          circular
          onPress={() => goToPreviousYear(10)}
          icon={<BidirectionalIcon icon="ChevronsLeft" size={16} color="$accent" />}
        />
        <Button
          borderWidth={0}
          variant="outlined"
          size="$2"
          circular
          focusable={false}
          onPress={() => goToPreviousYear(1)}
          icon={<BidirectionalIcon icon="ChevronLeft" size={16} color="$accent" />}
        />
        <Typography>{dayjs(date).calendar(type).format('YYYY')}</Typography>
        <Button
          borderWidth={0}
          variant="outlined"
          size="$2"
          focusable={false}
          onPress={() => goToNextYear(1)}
          icon={<BidirectionalIcon icon="ChevronRight" size={16} color="$accent" />}
          circular
        />
        <Button
          borderWidth={0}
          variant="outlined"
          size="$2"
          circular
          focusable={false}
          onPress={() => goToNextYear(10)}
          icon={<BidirectionalIcon icon="ChevronsRight" size={16} color="$accent" />}
        />
      </XStack>

      <XStack justifyContent="space-between" alignItems="center" height={40}>
        <Button
          borderWidth={0}
          variant="outlined"
          focusable={false}
          opacity={isFirstMonth ? undefined : 0}
          disabled={!isFirstMonth}
          onPress={monthsCount > 1 ? goToPreviousMonths : goToPreviousMonthsByOneMonth}
          icon={<BidirectionalIcon icon="ChevronLeft" size={18} color="$accent" />}
          circular
        />

        <Typography>{monthLabel}</Typography>

        <Button
          borderWidth={0}
          variant="outlined"
          focusable={false}
          opacity={isLastMonth ? undefined : 0}
          disabled={!isLastMonth}
          onPress={monthsCount > 1 ? goToNextMonths : goToNextMonthsByOneMonth}
          icon={<BidirectionalIcon icon="ChevronRight" size={18} color="$accent" />}
          circular
        />
      </XStack>
      <XStack flex={7} width={340} $xs={{width: '100%'}}>
        {weekdayLabels.map((dayLabel) => (
          <Typography width={`${100 / 7}%`} key={dayLabel} textAlign="center" paddingVertical="$2">
            {dayLabel}
          </Typography>
        ))}
      </XStack>
      <XStack display="flex" flexWrap="wrap" width={340} $xs={{width: '100%'}}>
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return (
              <Day
                datepickerContext={datepickerContext}
                dayLabel={day.dayLabel}
                date={day.date}
                key={day.date.toString()}
              />
            );
          }
          return <Stack key={index} width={`${100 / 7}%`} />;
        })}
      </XStack>
      <XStack display="flex"></XStack>
    </View>
  );
}
