import {useQuery} from 'react-query';

import {useAPI} from '../services/api';
import {EnvironmentProfile} from '../types/EnvironmentProfile';

export function useAppFile(name: unknown, profile: EnvironmentProfile) {
  const isValidFile = !!name && typeof name === 'string';

  const API = useAPI();
  const res = useQuery(
    [`portal-builder/myPortalApp/images/${name}`],
    () => API.get(`portal-builder/myPortalApp/images/${name}`, {searchParams: {profile}}).blob(),
    {enabled: isValidFile},
  );

  if (!isValidFile) return {data: undefined};

  return res;
}
