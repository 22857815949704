import {useQuery} from 'react-query';

import {useAPI} from '../services/api';
import {App, EnvironmentProfile} from '../types';

export function useApp(baseApiUrl?: string, tenant?: string, profile?: EnvironmentProfile) {
  const API = useAPI();
  return useQuery(['portal-builder/myPortalApp'], () =>
    API.get('portal-builder/myPortalApp', {
      prefixUrl: baseApiUrl,
      headers: {'x-tenant': tenant},
      ...(profile ? {searchParams: {profile}} : {}),
    })
      .json<App>()
      .then((app) => {
        // Add default structure if not present
        if (Object.keys(app.structure).length === 0) {
          app.structure = {launcher: {}, pages: [], auxiliaryPages: []};
        }

        return app;
      }),
  );
}
