import React, {Suspense} from 'react';

import type {AppContainerProps} from './AppContainer';
const AppContainer = React.lazy(() => import('./AppContainer'));

export const AppBaseContainer = (props: AppContainerProps) => (
  <Suspense>
    <AppContainer {...props} />
  </Suspense>
);
