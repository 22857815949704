import createLucideIcon from '../createLucideIcon';

const FolderEdit = createLucideIcon('FolderEdit', [
  [
    'path',
    { d: 'M8.4 10.6a2.1 2.1 0 1 1 2.99 2.98L6 19l-4 1 1-3.9Z', key: '10ocjb' },
  ],
  [
    'path',
    {
      d: 'M2 11.5V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H20a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-9.5',
      key: '1h3cz8',
    },
  ],
]);

export default FolderEdit;
