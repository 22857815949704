import React, {Fragment, useCallback, useRef, useState} from 'react';

import {ScrollView, Dimensions, Platform, NativeSyntheticEvent, NativeScrollEvent} from 'react-native';

import {isDefined} from '../../utils/isDefined';
import {NoDataMessage} from '../NoDataMessage';
import {SectionLoader} from '../PageLoader';
import {Paginator} from '../Paginator';
import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {Cell} from './components/Cell';
import {ColumnHeader} from './components/ColumnHeader';
import {Row} from './components/Row';
import {RowExtend} from './components/RowExtend';
import {RowHeader} from './components/RowHeader';
import {useRowSelector, RowSelector} from './components/RowSelector';
import {TableHeader} from './components/TableHeader';
import {useColumnsSelector} from './hooks/useColumnsSelector';
import {SortedBy, TableProps} from './model';
import {filterByQuery} from './utils/filterByQuery';
import {sortByMultipleKeys} from './utils/sortByMultipleKeys';

export * from './model';
export {Cell, Row};
const windowWidth = Dimensions.get('window').width;
const actionsColumnWidth = 100;

export function Table<T>({
  title,
  columns,
  data,
  formatters,
  filterBy,
  stickyHeader = true,
  sortBy,
  filterInputProps,
  totalItems = 0,
  pageSize = 5,
  pageSizeOptions = [],
  onFilter,
  onSort,
  actions,
  onSelect,
  onPagination,
  extendedView,
  isLoading,
  hasNoData,
  itemPrimaryKey,
  minContentHeight,
  showIndices,
  footer,
  hidePagination,
  subRow,
  rowProps,
  cellProps,
  onPaginationSizeChange,
  defaultValue = '-',
  paginatorProps,
  ...props
}: TableProps<T>) {
  const [filteredData, setFilteredData] = useState<T[]>();
  const [sortedData, setSortedData] = useState<T[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);

  const [extendedItem, setExtendedItem] = useState<T>();
  const sortedBy = useRef<SortedBy<T>>(new Map());

  const {selectAll, onToggleSelectAll, onSelectItem, isItemSelected} = useRowSelector<T>(onSelect);

  const isMobile = Platform.OS !== 'web';
  const [rowHeightsByIndex, setRowHeightsByIndex] = useState<{[key: string | number]: number} | null>(null);

  const columnWidthSm = actions && rowHeightsByIndex ? windowWidth - actionsColumnWidth : windowWidth;

  const onRowLayouts = useCallback((e, idx?: number) => {
    e.persist?.(); // Prevents React from nullifying the event
    const key = isMobile ? idx : e?.nativeEvent?.target?.id;
    setRowHeightsByIndex((prev) => {
      return {...prev, [key]: e?.nativeEvent?.layout?.height};
    });
  }, []);
  const columnsSelector = useColumnsSelector<T>(columns);

  const defaultFilter = (value: string) => {
    const filteredData = filterByQuery(value, filterBy, data);
    setFilteredData(filteredData === data ? undefined : filteredData);
  };

  const defaultSort = (sortBy: SortedBy<T>) => {
    const unsortedData = [...(filteredData || data || [])];
    const [sortedData, didChange] = sortByMultipleKeys(unsortedData, sortBy);
    setSortedData(didChange ? sortedData : undefined);
  };

  let displayedData = sortedData || filteredData || data;

  const localPagination = !onPagination && currentPageSize;
  if (localPagination) {
    const paginationStart = (currentPage - 1) * currentPageSize;
    displayedData = displayedData?.slice(paginationStart, paginationStart + currentPageSize);
  }

  const filterable = filterBy?.length || onFilter;
  const loading = isDefined(isLoading) ? isLoading : !isDefined(data);
  const empty = isDefined(hasNoData) ? hasNoData : data?.length === 0;

  const scrollViewRef = useRef<ScrollView | null>(null);

  const scrollToPosition = (position: number) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({x: position, animated: false});
    }
  };

  const handleMomentumScrollEnd = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const scrollEndPosition = event.nativeEvent.contentOffset.x;
    const columnsNum = columnsSelector.displayedColumns.length;

    const columnIndex = Math.floor(scrollEndPosition / columnWidthSm);
    const targetScrollPosition = columnIndex * columnWidthSm;
    const middleOfColumn = columnIndex * columnWidthSm + columnWidthSm / 2;

    if (scrollEndPosition > middleOfColumn) {
      const nextColumnIndex = Math.min(columnIndex + 1, columnsNum - 1);
      const nextColumnScrollPosition = nextColumnIndex * columnWidthSm;
      scrollToPosition(nextColumnScrollPosition);
    } else {
      scrollToPosition(targetScrollPosition);
    }
  };

  return (
    <YStack
      theme="translucent"
      borderRadius="$8"
      borderWidth={1}
      borderColor="$neutral-200"
      overflow="hidden"
      {...props}>
      {(title || filterable) && (
        <TableHeader
          columns={columns}
          columnsSelector={columnsSelector}
          title={title}
          filterable={filterable}
          onFilter={onFilter}
          defaultFilter={defaultFilter}
          filterInputProps={filterInputProps}
        />
      )}

      <XStack>
        <ScrollView
          ref={scrollViewRef}
          onMomentumScrollEnd={handleMomentumScrollEnd}
          pagingEnabled
          horizontal
          showsHorizontalScrollIndicator={Platform.OS === 'web'}
          contentContainerStyle={{flexGrow: 1, flexDirection: 'column'}}>
          {stickyHeader && (
            <RowHeader
              onSelect={onSelect}
              showIndices={showIndices}
              columns={columnsSelector.displayedColumns}
              sortBy={sortBy}
              selectAll={selectAll}
              sortedBy={sortedBy}
              defaultSort={defaultSort}
              onSort={onSort}
              extendedView={extendedView}
              onToggleSelectAll={onToggleSelectAll}
              columnHeaderProps={{$sm: {width: columnWidthSm}}}
            />
          )}

          <ScrollView>
            <YStack width="100%">
              {!stickyHeader && (
                <RowHeader
                  onSelect={onSelect}
                  showIndices={showIndices}
                  columns={columnsSelector.displayedColumns}
                  sortBy={sortBy}
                  selectAll={selectAll}
                  sortedBy={sortedBy}
                  defaultSort={defaultSort}
                  onSort={onSort}
                  extendedView={extendedView}
                  onToggleSelectAll={onToggleSelectAll}
                  columnHeaderProps={{$sm: {width: columnWidthSm}}}
                />
              )}
              <YStack
                justifyContent="flex-start"
                width="100%"
                minHeight={minContentHeight || 44}
                backgroundColor="$neutral-1050">
                {loading && <SectionLoader isLoading overlayMode />}
                {!loading && empty && <NoDataMessage hasNoData />}
                {displayedData?.map((item, index) => {
                  const rowExtended = item === extendedItem;

                  return (
                    <Fragment key={(itemPrimaryKey && (item[itemPrimaryKey] as any)) || index}>
                      <Row
                        subRow={subRow?.(item)}
                        {...(rowProps?.(item) || {})}
                        backgroundColor={rowExtended ? '$neutral-100' : '$background'}
                        onLayout={onRowLayouts}
                        rowExtended={rowExtended}
                        extendedView={extendedView}
                        item={item}
                        id={index + ''}>
                        {extendedView && (
                          <RowExtend
                            extended={rowExtended}
                            onToggle={() => setExtendedItem(rowExtended ? undefined : item)}
                          />
                        )}
                        {onSelect && (
                          <RowSelector selected={isItemSelected(item)} onSelectToggle={() => onSelectItem(item)} />
                        )}
                        {showIndices && (
                          <Cell flex={0} width="$3">
                            {currentPageSize * (currentPage - 1) + index + 1}
                          </Cell>
                        )}

                        {columnsSelector.displayedColumns.map((column, index) => {
                          const formatter = formatters?.[column.field];
                          const value = item[column.field];
                          return (
                            <Cell
                              key={index}
                              width={column.width}
                              $sm={{width: columnWidthSm}}
                              {...(cellProps?.(item, column.field) || {})}>
                              {(formatter ? formatter({value, item}) : value) ?? (
                                <Typography>{defaultValue}</Typography>
                              )}
                            </Cell>
                          );
                        })}
                      </Row>
                    </Fragment>
                  );
                })}
                {footer}
              </YStack>
            </YStack>
          </ScrollView>
        </ScrollView>

        {actions && rowHeightsByIndex && (
          <YStack width={actionsColumnWidth} backgroundColor="$background">
            <Row backgroundColor="$accentLight" borderWidth={0.5} borderColor="$border" alignItems="center">
              <ColumnHeader>
                <CoreTrans i18nKey="actions" />
              </ColumnHeader>
            </Row>
            {displayedData?.map((item, index) => {
              return (
                <XStack
                  alignItems="center"
                  justifyContent="center"
                  borderColor="$border"
                  borderWidth={0.5}
                  key={index}
                  height={rowHeightsByIndex?.[index]}>
                  {actions(item)}
                </XStack>
              );
            })}
          </YStack>
        )}
      </XStack>

      {!hidePagination && (
        <Paginator
          current={empty ? 0 : currentPage}
          total={empty ? 0 : onPagination ? totalItems || data?.length : data?.length}
          pageSize={currentPageSize}
          onChange={onPagination || setCurrentPage}
          onPageSizeChange={(pageSize) => {
            onPaginationSizeChange?.(pageSize);
            setCurrentPageSize(pageSize);
          }}
          pageSizeOptions={pageSizeOptions}
          {...paginatorProps}
        />
      )}
    </YStack>
  );
}
