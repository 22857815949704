import createLucideIcon from '../createLucideIcon';

const BookAudio = createLucideIcon('BookAudio', [
  [
    'path',
    {
      d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20',
      key: 't4utmx',
    },
  ],
  ['path', { d: 'M8 8v3', key: '1qzp49' }],
  ['path', { d: 'M12 6v7', key: '1f6ttz' }],
  ['path', { d: 'M16 8v3', key: 'gejaml' }],
]);

export default BookAudio;
