import React from 'react';

import {Trans} from 'react-i18next';

export const requiredRule: any = {
  required: true,
  message: <Trans i18nKey="validation.requiredField" />,
};

export const mustBePositiveRule: any = {
  min: 0,
  message: <Trans i18nKey="validation.mustBePositive" />,
};
