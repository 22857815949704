import {useQuery} from 'react-query';

import {useAPI} from '../services';
import {ApiConnector} from '../types';

export function useConnector<T = ApiConnector>(id?: string) {
  const API = useAPI();
  return useQuery(['connector-manager/connectors', id], () => API.get(`connector-manager/connectors/${id}`).json<T>(), {
    enabled: !!id,
  });
}
